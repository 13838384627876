// const baseURL = 'https://localhost:44389/api/';
 const baseURL = 'https://api.skgoas.in/api/';

export const LOGIN_URL = `${baseURL}Account/login`;
export const CHANGE_PASSWORD = `${baseURL}Account/changepassword`;
export const BANK_URL = `${baseURL}Masters/getbanks`;
export const GET_BANK_BY_ID = `${baseURL}Masters/getbankbyid`;
export const ADD_BANK = `${baseURL}Masters/addbank`;
export const UPDATE_BANK = `${baseURL}Masters/updatebank`;
export const BRANCH_URL = `${baseURL}Masters/getbranches`;
export const GET_BRANCH_BY_ID = `${baseURL}Masters/getbranchbyid`;
export const GET_BRANCH_BY_BANK_ID = `${baseURL}Masters/getbranchesbybankid`;
export const ADD_BRANCH = `${baseURL}Masters/addbranch`;
export const UPDATE_BRANCH = `${baseURL}Masters/updatebranch`;
export const STATE_URL = `${baseURL}Masters/getstates`;
export const GET_STATE_BY_ID = `${baseURL}Masters/getstatebyid`;
export const ADD_STATE = `${baseURL}Masters/addstate`;
export const UPDATE_STATE = `${baseURL}Masters/updatestate`;
export const DISTRICT_URL = `${baseURL}Masters/getdistricts`;
export const GET_DISTRICT_BY_STATE_ID = `${baseURL}Masters/getdistrictsbystateid`;
export const GET_DISTRICT_BY_ID = `${baseURL}Masters/getdistrictbyid`;
export const ADD_DISTRICT = `${baseURL}Masters/adddistrict`;
export const UPDATE_DISTRICT = `${baseURL}Masters/updatedistrict`;
export const DESIGNATION_URL = `${baseURL}Masters/getdesignations`;
export const GET_DESIGNATION_BY_ID = `${baseURL}Masters/getdesignationbyid`;
export const ADD_DESIGNATION = `${baseURL}Masters/adddesignation`;
export const UPDATE_DESIGNATION = `${baseURL}Masters/updatedesignation`;
export const TASK_CATEGORY_URL = `${baseURL}Masters/gettaskcategories`;
export const GET_TASK_CATEGORY_BY_ID = `${baseURL}Masters/gettaskcategorybyid`;
export const ADD_UPDATE_TASK_CATEGORY = `${baseURL}Masters/addupdatetaskcategory`;
export const GET_TASK_BY_CATEGORY_ID = `${baseURL}Masters/gettasksbycategoryid`;
export const GET_TASK_BY_ID = `${baseURL}Masters/gettaskbyid`;
export const ADD_UPDATE_TASK = `${baseURL}Masters/addupdatetask`;

export const PRODUCT_CATEGORY_URL = `${baseURL}Masters/getproductcategories`;
export const GET_PRODUCT_CATEGORY_BY_ID = `${baseURL}Masters/getproductcategorybyid`;
export const ADD_PRODUCT_CATEGORY = `${baseURL}Masters/addproductcategory`;
export const UPDATE_PRODUCT_CATEGORY = `${baseURL}Masters/updateproductcategory`;

export const PRODUCT_SUB_CATEGORY_URL = `${baseURL}Masters/getproductsubcategories`;
export const GET_PRODUCT_SUB_CATEGORY_BY_ID = `${baseURL}Masters/getproductsubcategorybyid`;
export const ADD_PRODUCT_SUB_CATEGORY = `${baseURL}Masters/addproductsubcategory`;
export const UPDATE_PRODUCT_SUB_CATEGORY = `${baseURL}Masters/updateproductsubcategory`;

export const PRODUCT_URL = `${baseURL}Masters/getproducts`;
export const GET_PRODUCT_BY_ID = `${baseURL}Masters/getproductbyid`;
export const ADD_PRODUCT = `${baseURL}Masters/addproduct`;
export const UPDATE_PRODUCT = `${baseURL}Masters/updateproduct`;

export const SUBASSEMBLY_URL = `${baseURL}Masters/getsubassemblies`;
export const GET_SUBASSEMBLY_BY_ID = `${baseURL}Masters/getsubassemblybyid`;
export const ADD_UPDATE_SUBASSEMBLY = `${baseURL}Masters/addupdatesubassembly`;

export const PART_URL = `${baseURL}Masters/getparts`;
export const GET_PART_BY_ID = `${baseURL}Masters/getpartbyid`;
export const ADD_UPDATE_PART = `${baseURL}Masters/addupdatepart`;

export const OPERATION_URL = `${baseURL}Masters/getoperations`;
export const GET_OPERATION_BY_ID = `${baseURL}Masters/getoperationbyid`;
export const ADD_UPDATE_OPERATION = `${baseURL}Masters/addupdateoperation`;

export const INSPECTION_CHECKLIST_URL = `${baseURL}Masters/getinspectionchecklist`;
export const GET_INSPECTION_CHECKLIST_BY_ID = `${baseURL}Masters/getinspectioncheckitbyid`;
export const ADD_UPDATE_INSPECTION_CHECKLIST = `${baseURL}Masters/addupdateinspectionchecklist`;

export const MACHINE_URL = `${baseURL}Masters/getmachines`;
export const GET_MACHINE_BY_ID = `${baseURL}Masters/getmachinebyid`;
export const ADD_UPDATE_MACHINE = `${baseURL}Masters/addupdatemachine`;

export const MATERIAL_CATEGORY_URL = `${baseURL}Masters/getmaterialcategories`;
export const GET_MATERIAL_CATEGORY_BY_ID = `${baseURL}Masters/getmaterialcategorybyid`;
export const ADD_MATERIAL_CATEGORY = `${baseURL}Masters/addmaterialcategory`;
export const UPDATE_MATERIAL_CATEGORY = `${baseURL}Masters/updatematerialcategory`;
export const MATERIAL_SUB_CATEGORY_URL = `${baseURL}Masters/getmaterialsubcategories`;
export const GET_MATERIAL_SUB_CATEGORY_BY_ID = `${baseURL}Masters/getmaterialsubcategorybyid`;
export const ADD_MATERIAL_SUB_CATEGORY = `${baseURL}Masters/addmaterialsubcategory`;
export const UPDATE_MATERIAL_SUB_CATEGORY = `${baseURL}Masters/updatematerialsubcategory`;
export const MATERIAL_URL = `${baseURL}Masters/getmaterials`;
export const GET_MATERIAL_BY_ID = `${baseURL}Masters/getmaterialbyid`;
export const ADD_MATERIAL = `${baseURL}Masters/addmaterial`;
export const UPDATE_MATERIAL = `${baseURL}Masters/updatematerial`;
export const GET_ALL_MATERIALS = `${baseURL}Masters/getallmaterials`;

export const GET_OPERATION_MACHINE_MAP = `${baseURL}Masters/getoperationmachinemappings`;
export const ADD_OPERATION_MACHINE_MAP = `${baseURL}Masters/addoperationmachinemapping`;
export const DELETE_OPERATION_MACHINE_MAP = `${baseURL}Masters/deleteoperationmachinemapping`;

export const HSNCODE_URL = `${baseURL}Masters/gethsncodes`;
export const GET_HSNCODE_BY_ID = `${baseURL}Masters/gethsncodebyid`;
export const ADD_UPDATE_HSNCODE = `${baseURL}Masters/addupdatehsncode`;

export const SUPPLIER_CATEGORY_URL = `${baseURL}Suppliers/getsuppliercategories`;
export const GET_SUPPLIER_CATEGORY_BY_ID = `${baseURL}Suppliers/getsuppliercategorybyid`;
export const ADD_UPDATE_SUPPLIER_CATEGORY = `${baseURL}Suppliers/addupdatesuppliercategory`;
export const SUPPLIER_URL = `${baseURL}Suppliers/getsuppliers`;
export const GET_SUPPLIER_BY_ID = `${baseURL}Suppliers/getsupplierbyid`;
export const ADD_UPDATE_SUPPLIER = `${baseURL}Suppliers/addupdatesupplier`;

export const EMPLOYEE_URL = `${baseURL}Employees/getemployees`;
export const GET_EMPLOYEE_BY_ID = `${baseURL}Employees/getemployeebyid`;
export const ADD_UPDATE_EMPLOYEE = `${baseURL}Employees/addupdateemployee`;

export const CUSTOMER_CATEGORY_URL = `${baseURL}Customers/getcustomercategories`;
export const CUSTOMER_URL = `${baseURL}Customers/getcustomers`;
export const GET_CUSTOMER_BY_ID = `${baseURL}Customers/getcustomerbyid`;
export const ADD_UPDATE_CUSTOMER = `${baseURL}Customers/addupdatecustomer`;

export const WORK_ORDER_URL = `${baseURL}Customers/getworkorders`;
export const GET_WORK_ORDER_BY_ID = `${baseURL}Customers/getworkorderbyid`;
export const ADD_UPDATE_WORK_ORDER = `${baseURL}Customers/addupdateworkorder`;
export const GET_PENDING_WORK_ORDER = `${baseURL}Customers/getpendingworkorders`;
export const GET_ALL_WORK_ORDER_NUMBERS = `${baseURL}Customers/getallworkordernumbers`;
export const UPDATE_WORK_ORDER_STATUS = `${baseURL}Customers/updateworkorderstatus`;
export const GET_NEXT_JOB_NO = `${baseURL}Customers/getnextjobno`;
export const GET_AVAILABLE_STOCK = `${baseURL}Customers/getavailablestock`;
export const ADD_REQUIRED_STOCK = `${baseURL}Customers/addrequiredstock`;
export const GET_REQUIRED_STOCK = `${baseURL}Customers/getrequiredstock`;
export const ADD_STOCK_WORK_ORDER_MAPPING = `${baseURL}Customers/addstockworkordermapping`;
export const GET_AVAILABLE_STOCK_FOR_MAPPING = `${baseURL}Customers/getavailablestockformapping`;
export const ADD_STOCK = `${baseURL}Customers/addstock`;
export const ADD_STOCK_MAPPING = `${baseURL}Customers/addstockmapping`;
export const ADD_PRODUCT_INTO_STOCK = `${baseURL}Customers/addproductintostock`;
export const ADD_WORK_ORDER_FOR_MATERIAL = `${baseURL}Customers/addworkorderformaterial`;

export const GET_SUBASSEMBLY_MATERIAL_MAP = `${baseURL}Masters/getsubassemblymaterialmappings`;
export const ADD_SUBASSEMBLY_MATERIAL_MAP = `${baseURL}Masters/addsubassemblymaterialmapping`;
export const DELETE_SUBASSEMBLY_MATERIAL_MAP = `${baseURL}Masters/deletesubassemblymaterialmapping`;
export const GET_SUBASSEMBLY_MATERIAL = `${baseURL}Masters/getsubassemblymaterial`;

export const GET_SUPPLIER_MATERIAL_MAP = `${baseURL}Suppliers/getsuppliermaterialmappings`;
export const ADD_SUPPLIER_MATERIAL_MAP = `${baseURL}Suppliers/addsuppliermaterialmapping`;
export const DELETE_SUPPLIER_MATERIAL_MAP = `${baseURL}Suppliers/deletesuppliermaterialmapping`;
export const GET_SUPPLIER_MATERIALS = `${baseURL}Suppliers/getsuppliermaterials`;

export const OPERATION_CRITICAL_PARAMETER_URL = `${baseURL}Masters/getoperationcriticalparams`;
export const GET_OPERATION_CRITICAL_PARAMETER_BY_ID = `${baseURL}Masters/getoperationcriticalparambyid`;
export const ADD_UPDATE_OPERATION_CRITICAL_PARAMETER = `${baseURL}Masters/addupdateoperationcriticalparam`;

export const OPERATION_INSPECTION_PARAMETER_URL = `${baseURL}Masters/getoperationinspectionparams`;
export const GET_OPERATION_INSPECTION_PARAMETER_BY_ID = `${baseURL}Masters/getoperationinspectionparambyid`;
export const ADD_UPDATE_OPERATION_INSPECTION_PARAMETER = `${baseURL}Masters/addupdateoperationinspectionparam`;

export const WORK_ALLOCATION_URL = `${baseURL}WorkAllocations/getworkallocations`;
export const GET_WORK_ALLOCATION_BY_ID = `${baseURL}WorkAllocations/getworkallocationbyid`;
export const GET_WORK_ALLOCATION_BY_PART_ID = `${baseURL}WorkAllocations/getworkallocationbypartid`;
export const ADD_UPDATE_WORK_ALLOCATION = `${baseURL}WorkAllocations/addupdateworkallocation`;
export const DELETE_WORK_ALLOCATION = `${baseURL}WorkAllocations/deleteworkallocation`;

export const GET_PENDING_WORK_ALLOCATION = `${baseURL}WorkAllocations/getpendingworkallocationsbyemployee`;
export const GET_DAILY_WORK_DETAILS = `${baseURL}WorkAllocations/getdailyworkdetails`;
export const DELETE_DAILY_WORK_DETAILS = `${baseURL}WorkAllocations/deletedailyworkdetails`;
export const ADD_DAILY_WORK_DETAILS = `${baseURL}WorkAllocations/adddailyworkdetails`;
export const UPDATE_DAILY_WORK_DETAILS_STATUS = `${baseURL}WorkAllocations/updatedailyworkdetailsstatus`;
export const GET_DAILY_WORK_DETAILS_FOR_APPROVAL = `${baseURL}WorkAllocations/getdailyworkdetailsforaprroval`;
export const ADD_DAILY_WORK_STATUS = `${baseURL}WorkAllocations/adddailyworkstatus`;
export const ADD_DAILY_WORK_INSPECTION_OBSERVATION = `${baseURL}WorkAllocations/adddailyworkinspectionobservation`;

export const GET_PURCHASE_ORDER_BY_SUPPLIER = `${baseURL}Suppliers/getpurchaseordersbysupplier`;
export const ADD_PURCHASE_ORDER_FOR_WORK = `${baseURL}Suppliers/addpurchaseorderforwork`;
export const DELETE_PURCHASE_ORDER_FOR_WORK = `${baseURL}Suppliers/deletepurchaseorderforwork`;
export const UPDATE_PURCHASE_ORDER_FOR_WORK_STATUS = `${baseURL}Suppliers/updatepurchaseorderforworkstatus`;

export const GET_PURCHASE_ORDER_FOR_WORK_BY_SUPPLIER = `${baseURL}Suppliers/getpurchaseordersforworkbysupplier`;
export const ADD_PURCHASE_ORDER = `${baseURL}Suppliers/addpurchaseorder`;
export const DELETE_PURCHASE_ORDER_DETAILS_BY_ID = `${baseURL}Suppliers/deletepurchaseorderdetailsbyid`;

export const GET_PURCHASE_ORDER_BY_ID = `${baseURL}Suppliers/getpurchaseorderbyid`;
export const GET_PURCHASE_ORDER_FOR_WORK_BY_ID = `${baseURL}Suppliers/getpurchaseorderforworkbyid`;

export const GET_GRN_BY_PO = `${baseURL}Suppliers/getgrnbypo`;
export const ADD_GRN = `${baseURL}Suppliers/addgoodsreceiptnote`;
export const DELETE_GRN_DETAILS_BY_ID = `${baseURL}Suppliers/deletegrndetailsbyid`;

export const ADD_MATERIAL_ISSUE = `${baseURL}Suppliers/addmaterialissue`;
export const GET_MATERIAL_AVAILABLE_QUANTITY = `${baseURL}Suppliers/getmaterialavailablequantity`;
export const GET_MATERIAL_FOR_REQUISITION = `${baseURL}Suppliers/getmaterialsforrequisition`;
export const ADD_MATERIAL_REQUISITION = `${baseURL}Suppliers/addmaterialrequisition`;
export const DELETE_MATERIAL_REQUISITION = `${baseURL}Suppliers/deletematerialrequisition`;
export const GET_MATERIAL_REQUISITION_BY_MATERIAL_ID = `${baseURL}Suppliers/getmaterialrequisitionsbymaterialid`;
export const GET_MATERIAL_REQUISITION_BY_STATUS = `${baseURL}Suppliers/getmaterialrequisitionsbystatus`;
export const UPDATE_MATERIAL_REQUISITION_STATUS = `${baseURL}Suppliers/updatematerialrequisitionstatus`;
export const GET_MATERIAL_REQUISITION_FOR_PO = `${baseURL}Suppliers/getmaterialrequisitionsforpo`;
export const GET_SUPPLIER_FOR_MATERIAL = `${baseURL}Suppliers/getsupplierformaterial`;

export const GET_PENDING_TASK_ALLOCATION = `${baseURL}Employees/getpendingtaskallocations`;
export const GET_TASK_ALLOCATION_BY_ID = `${baseURL}Employees/gettaskallocationbyid`;
export const ADD_UPDATE_TASK_ALLOCATION = `${baseURL}Employees/addupdatetaskallocation`;
export const DELETE_TASK_ALLOCATION = `${baseURL}Employees/deletetaskallocation`;
export const GET_PENDING_TASK_ALLOCATION_BY_EMPLOYEE = `${baseURL}Employees/getpendingtaskallocationsbyemployee`;
export const GET_TASK_DETAILS = `${baseURL}Employees/gettaskdetails`;
export const DELETE_TASK_DETAILS = `${baseURL}Employees/deletetaskdetails`;
export const ADD_TASK_DETAILS = `${baseURL}Employees/addtaskdetails`;

export const GET_SUPPLIER_OPERATION_MAP = `${baseURL}Suppliers/getsupplieroperationmappings`;
export const ADD_SUPPLIER_OPERATION_MAP = `${baseURL}Suppliers/addsupplieroperationmapping`;
export const DELETE_SUPPLIER_OPERATION_MAP = `${baseURL}Suppliers/deletesupplieroperationmapping`;
export const GET_SUPPLIER_FOR_OPERATION = `${baseURL}Suppliers/getsupplierforoperation`;

export const WORK_ALLOCATION_TO_SUPPLIER = `${baseURL}WorkAllocations/getworkallocationstosupplier`;
export const GET_WORK_ALLOCATION_TO_SUPPLIER_BY_ID = `${baseURL}WorkAllocations/getworkallocationtosupplierbyid`;
export const ADD_UPDATE_WORK_ALLOCATION_TO_SUPPLIER = `${baseURL}WorkAllocations/addupdateworkallocationtosupplier`;
export const DELETE_WORK_ALLOCATION_TO_SUPPLIER = `${baseURL}WorkAllocations/deleteworkallocationtosupplier`;

export const GET_PENDING_WORK_ALLOCATION_TO_SUPPLIER = `${baseURL}WorkAllocations/getpendingworkallocationstosupplier`;
export const APPROVE_WORK_ALLOCATION_TO_SUPPLIER_STATUS = `${baseURL}WorkAllocations/approveworkallocationtosupplierstatus`;
export const GET_APPROVED_WORK_ALLOCATION_TO_SUPPLIER = `${baseURL}WorkAllocations/getapprovedworkallocationstosupplier`;
export const GET_ORDERED_WORK_ALLOCATION_TO_SUPPLIER = `${baseURL}WorkAllocations/getorderedworkallocationstosupplier`;
export const ADD_WORK_INSPECTION_OBSERVATION_OF_SUPPLIER = `${baseURL}WorkAllocations/addworkinspectionobservationofsupplier`;
export const UPDATE_WORK_ALLOCATION_TO_SUPPLIER_STATUS = `${baseURL}WorkAllocations/updateworkallocationtosupplierstatus`;

export const PRE_DELIVERY_INSPECTION_CRITERIA_URL = `${baseURL}Masters/getpredeliveryinspectioncriteria`;
export const GET_PRE_DELIVERY_INSPECTION_CRITERIA_BY_ID = `${baseURL}Masters/getpredeliveryinspectioncriteriabyid`;
export const ADD_UPDATE_PRE_DELIVERY_INSPECTION_CRITERIA = `${baseURL}Masters/addupdatepredeliveryinspectioncriteria`;

export const GET_HSNCODE_BY_MATERIAL_ID = `${baseURL}Masters/gethsncodebymaterialid`;
export const GET_HSNCODE_BY_OPERATION_ID = `${baseURL}Masters/gethsncodebyoperationid`;

export const GET_PRODUCTS_FOR_PRE_DELIVERY_INSPECTION = `${baseURL}WorkAllocations/getproductsforpredeliveryinspection`;
export const ADD_PRE_DELIVERY_INSPECTION = `${baseURL}WorkAllocations/addpredeliveryinspection`;
export const ADD_PRE_DELIVERY_INSPECTION_RESULT = `${baseURL}WorkAllocations/addpredeliveryinspectionresult`;

export const GET_AVAILABLE_PRODUCTS_FOR_MAPPING = `${baseURL}Customers/getavailableproductformapping`;
export const GET_AVAILABLE_SUBASSEMBLY_FOR_MAPPING = `${baseURL}Customers/getavailablesubassemblyformapping`;
export const GET_AVAILABLE_PARTS_FOR_MAPPING = `${baseURL}Customers/getavailablepartsformapping`;

export const GET_COMPLETED_PRODUCTS_FOR_MAPPING = `${baseURL}Customers/getcompletedproductformapping`;
export const GET_COMPLETED_SUBASSEMBLY_FOR_MAPPING = `${baseURL}Customers/getcompletedsubassemblyformapping`;
export const GET_COMPLETED_STOCK_FOR_MAPPING = `${baseURL}Customers/getcompletedstockformapping`;

export const GET_PRODUCTS_FOR_DELIVERY_CHALLAN = `${baseURL}WorkAllocations/getproductsfordeliverychallan`;
export const GET_SUBASSEMBLY_FOR_DELIVERY_CHALLAN = `${baseURL}WorkAllocations/getsubassemblyfordeliverychallan`;
export const GET_PARTS_FOR_DELIVERY_CHALLAN = `${baseURL}WorkAllocations/getpartsfordeliverychallan`;
export const ADD_DELIVERY_CHALLAN = `${baseURL}WorkAllocations/adddeliverychallan`;
export const ADD_DELIVERY_CHALLAN_STOCK_DETAILS = `${baseURL}WorkAllocations/adddeliverychallanstockdetails`;
export const GET_NEXT_CHALLAN_NO = `${baseURL}WorkAllocations/getnextchallanno`;

export const GET_SUPPLIER_RATE_FOR_OPERATION = `${baseURL}Suppliers/getsupplierrateforoperation`;

export const GET_ITEMS_FOR_INVOICE = `${baseURL}WorkAllocations/getitemsforinvoice`;
export const ADD_INVOICE = `${baseURL}WorkAllocations/addinvoice`;
export const GET_NEXT_INVOICE_NO = `${baseURL}WorkAllocations/getnextinvoicenno`;

export const GET_DELIVERY_CHALLANS = `${baseURL}WorkAllocations/getdeliverychallans`;
export const GET_DELIVERY_CHALLAN_BY_ID = `${baseURL}WorkAllocations/getdeliverychallanbyid`;
export const GET_INVOICES = `${baseURL}WorkAllocations/getinvoices`;
export const GET_INVOICE_BY_ID = `${baseURL}WorkAllocations/getinvoicebyid`;
export const GET_INVOICES_BY_CUSTOMER_ID = `${baseURL}WorkAllocations/getinvoicesbycustomerid`;
export const GET_INVOICES_BY_WORK_ORDER_ID = `${baseURL}WorkAllocations/getinvoicesbyworkorderid`;

export const GET_INVOICES_FOR_LR = `${baseURL}WorkAllocations/getinvoicesforlr`;
export const ADD_LR = `${baseURL}WorkAllocations/addlr`;
export const GET_NEXT_LR_NO = `${baseURL}WorkAllocations/getnextlrno`;
export const GET_LRS = `${baseURL}WorkAllocations/getlrs`;
export const GET_LR_BY_ID = `${baseURL}WorkAllocations/getlrbyid`;

export const GET_INSPECTION_TASKS = `${baseURL}Employees/getinspectiontasks`;
export const GET_INSPECTION_TASK_BY_ID = `${baseURL}Employees/getinspectiontaskbyid`;

export const GET_CUSTOMER_RECEIPTS = `${baseURL}Customers/getcustomerreceipts`;
export const DELETE_CUSTOMER_RECEIPT_BY_ID = `${baseURL}Customers/deletecustomerreceiptbyid`;
export const ADD_CUSTOMER_RECEIPT = `${baseURL}Customers/addcustomerreceipt`; 
export const GET_PURCHASE_ORDER_BY_TYPE = `${baseURL}Suppliers/getpurchaseordersbytype`;
export const GET_SUPPLIER_PAYMENT = `${baseURL}Suppliers/getsupplierpayments`;
export const DELETE_SUPPLIER_PAYMENT_BY_ID = `${baseURL}Suppliers/deletesupplierpaymentbyid`;
export const ADD_SUPPLIER_PAYMENT = `${baseURL}Suppliers/addsupplierpayment`;
export const GET_BILL_NO = `${baseURL}Suppliers/getbillnos`;
export const GET_SUPPLIER_PAYMENT_BALANCE = `${baseURL}Suppliers/getsupplierpaymentbalance`;
export const GET_CUSTOMER_RECEIPT_BALANCE = `${baseURL}Customers/getcustomerreceiptbalance`;

export const GET_EMPLOYEE_REPORT = `${baseURL}Reports/getemployeesreport`;
export const GET_SUPPLIER_REPORT = `${baseURL}Reports/getsuppliersreport`;
export const GET_CUSTOMER_REPORT = `${baseURL}Reports/getcustomersreport`;
export const GET_WORK_ORDER_REPORT = `${baseURL}Reports/getworkorderreport`;
export const GET_TASK_STATUS_REPORT = `${baseURL}Reports/gettaskstatusreport`;
export const GET_PURCHASE_ORDER_REPORT = `${baseURL}Reports/getpurchaseorderreport`;
export const GET_PURCHASE_ORDER_FOR_WORK_REPORT = `${baseURL}Reports/getpurchaseorderforworkreport`;
export const GET_INVOICE_REPORT = `${baseURL}Reports/getinvoicereport`;
export const GET_WORK_ALLOCATION_REPORT = `${baseURL}Reports/getworkallocationreport`;
export const GET_WORK_ALLOCATION_STATUS_REPORT = `${baseURL}Reports/getworkallocationstatusreport`;
export const GET_WORK_ALLOCATION_TO_SUPPLIER_REPORT = `${baseURL}Reports/getworkallocationtosupplierreport`;
export const GET_MATERIAL_STOCK_REPORT = `${baseURL}Reports/getmaterialstockreport`;
export const GET_STOCK_BELOW_REORDER_QUANTITY = `${baseURL}Reports/getstockbelowreorderquantity`;
export const GET_MATERIAL_ISSUE_REPORT = `${baseURL}Reports/getmaterialissuereport`;
export const GET_REJECTED_OPERATION_REPORT = `${baseURL}Reports/getrejectedoperationreport`;
export const GET_REJECTED_OPERATION_OF_SUPPLIER_REPORT = `${baseURL}Reports/getrejectedoperationofsupplierreport`;
export const GET_EMPLOYEE_PERFORMANCE_REPORT = `${baseURL}Reports/getemployeeperformancereport`;
export const GET_CUSTOMER_RECEIPT_REPORT = `${baseURL}Reports/getcustomerreceiptreport`;
export const GET_SUPPLIER_PAYMENT_REPORT = `${baseURL}Reports/getsupplierpaymentreport`;

// *************************************************************************************************************************

export default LOGIN_URL;