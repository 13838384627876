
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Select from 'react-select';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import * as service from '../../../services/service';

function AddEditWorkAllocationForStock() {
    const [state, setState] = useState({ remarks: '' });
    const [allocationDate, setAllocationDate] = useState(new Date());
    const [plannedCompletionDate, setPlannedCompletionDate] = useState(new Date());
    const [productCatData, setProductCatData] = useState([]);
    const [productSubCatData, setProductSubCatData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [subAssemblyData, setSubAssemblyData] = useState([]);
    const [partData, setPartData] = useState([]);
    const [operationData, setOperationData] = useState([]);
    const [employeeData, setEmployeeData] = useState([]);
    const [productCatId, setProductCatId] = useState('');
    const [productSubCatId, setProductSubCatId] = useState('');
    const [productId, setProductId] = useState('');
    const [subAssemblyId, setSubAssemblyId] = useState('');
    const [partId, setPartId] = useState('');
    const [operationId, setOperationId] = useState('');
    const [employeeId, setEmployeeId] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const navigate = useNavigate();
    const params = useParams();
    const getDetailsById = () => {
        setLoading(true);
        const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_WORK_ALLOCATION_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        WorkAllocationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setState({ ...state, remarks: result.remarks });

                        const cat = ({ value: result.productCategoryId, label: result.productCategory });
                        setProductCatId(cat);
                        const subCat = ({ value: result.productSubCategoryId, label: result.productSubCategory });
                        setProductSubCatId(subCat);
                        const pro = ({ value: result.productId, label: result.product });
                        setProductId(pro);
                        if (result.subAssemblyId !== 0) {
                            const assem = ({ value: result.subAssemblyId, label: result.subAssembly });
                            setSubAssemblyId(assem);
                        }
                        if (result.partId !== 0) {
                            const part = ({ value: result.partId, label: result.part });
                            setPartId(part);
                        }
                        const oper = ({ value: result.operationId, label: result.operation });
                        setOperationId(oper);
                        const emp = ({ value: result.employeeId, label: result.employeeName });
                        setEmployeeId(emp);
                        setAllocationDate(new Date(result.allocationDate));
                        setPlannedCompletionDate(new Date(result.plannedCompletionDate));

                        localStorage.setItem('productCategoryId', JSON.stringify(result.productCategoryId));
                        localStorage.setItem('productCategory', JSON.stringify(result.productCategory));
                        localStorage.setItem('productSubCategoryId', JSON.stringify(result.productSubCategoryId));
                        localStorage.setItem('productSubCategory', JSON.stringify(result.productSubCategory));
                        localStorage.setItem('productId', JSON.stringify(result.productId));
                        localStorage.setItem('product', JSON.stringify(result.product));
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    };

    const getProductCategories = () => {
        fetch(
            service.PRODUCT_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.productCategoryId, label: item.productCategory }));
                setProductCatData(data2);
            });
    };
    const getProductSubCategories = (id) => {
        fetch(
            service.PRODUCT_SUB_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductCategoryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.productSubCategoryId, label: item.productSubCategory }));
                setProductSubCatData(data2);
            });
    };

    const getProducts = (catId, subCatId) => {
        fetch(
            service.PRODUCT_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductCategoryId: catId,
                    ProductSubCategoryId: subCatId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.productId, label: item.product }));
                setProductData(data2);
            });
    };
    const getSubAssemblies = (prodId) => {
        fetch(
            service.SUBASSEMBLY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductId: prodId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.subAssemblyId, label: item.subAssembly }));
                setSubAssemblyData(data2);
            });
    };

    const getParts = (assemblyId) => {
        fetch(
            service.PART_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SubAssemblyId: assemblyId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.partId, label: item.part }));
                setPartData(data2);
            });
    };
    const getOperations = (prId, assemblyId, prtId) => {
        fetch(
            service.OPERATION_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductId: prId,
                    SubAssemblyId: assemblyId,
                    PartId: prtId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.operationId, label: item.operation }));
                setOperationData(data2);
            });
    };
    const getEmployees = () => {
        fetch(
            service.EMPLOYEE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.employeeId, label: item.employeeName }));
                setEmployeeData(data2);
            });
    };

    useEffect(() => {
        getEmployees();
        getProductCategories();
        if (params.id > 0) {
            getDetailsById();
        }
        else {
            if (JSON.parse(localStorage.getItem('productCategoryIdForWA')) != null) {
                const item = ({ value: JSON.parse(localStorage.getItem('productCategoryIdForWA')), label: JSON.parse(localStorage.getItem('productCategoryForWA')) });
                setProductCatId(item);
                getProductSubCategories(item.value);
                const subCat = ({ value: JSON.parse(localStorage.getItem('productSubCategoryIdForWA')), label: JSON.parse(localStorage.getItem('productSubCategoryForWA')) });
                setProductSubCatId(subCat);
                getProducts(subCat.value);
                const prod = ({ value: JSON.parse(localStorage.getItem('productIdForWA')), label: JSON.parse(localStorage.getItem('productForWA')) });
                setProductId(prod);
                getSubAssemblies(prod.value);
                getOperations(prod.value, null, null)

                if (JSON.parse(localStorage.getItem('subAssemblyIdForWA') !== "0")) {
                    const assem = ({ value: JSON.parse(localStorage.getItem('subAssemblyIdForWA')), label: JSON.parse(localStorage.getItem('subAssemblyForWA')) });
                    setSubAssemblyId(assem);
                    getOperations(prod.value, assem.value, null);

                    getParts(assem.value);
                    if (JSON.parse(localStorage.getItem('partIdForWA') !== "0")) {
                        const pr = ({ value: JSON.parse(localStorage.getItem('partIdForWA')), label: JSON.parse(localStorage.getItem('partForWA')) });
                        setPartId(pr);
                        getOperations(prod.value, assem.value, pr.value);
                    }
                }

            };
            localStorage.setItem('productCategoryIdForWA', null);
            localStorage.setItem('productCategoryForWA', null);
            localStorage.setItem('productSubCategoryIdForWA', null);
            localStorage.setItem('productSubCategoryForWA', null);
            localStorage.setItem('productIdForWA', null);
            localStorage.setItem('productForWA', null);
            localStorage.setItem('subAssemblyIdForWA', null);
            localStorage.setItem('subAssemblyForWA', null);
            localStorage.setItem('partIdForWA', null);
            localStorage.setItem('partForWA', null);
        }
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!productCatId) {
            isValid = false;
            errs[".productcategory"] = "Please select product category";
        }
        if (!productSubCatId) {
            isValid = false;
            errs[".productsubcategory"] = "Please select product sub category";
        }
        if (!productId) {
            isValid = false;
            errs[".product"] = "Please select product";
        }
        // if (!subAssemblyId) {
        //     isValid = false;
        //     errs[".subassembly"] = "Please select sub assembly";
        // }
        if (!operationId) {
            isValid = false;
            errs[".operation"] = "Please select operation";
        }
        if (!employeeId) {
            isValid = false;
            errs[".employee"] = "Please select employee";
        }
        if (!allocationDate) {
            isValid = false;
            errs[".allocationdate"] = "Please enter allocation date";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const productCatChangeHandler = (ev) => {
        setProductCatId(ev);
        setProductSubCatId(null);
        setProductId(null);
        setSubAssemblyId(null);
        setPartId(null);
        setOperationId(null);
        getProductSubCategories(ev.value);
    }
    const productSubCatChangeHandler = (ev) => {
        setProductSubCatId(ev);
        setProductId(null);
        setSubAssemblyId(null);
        setPartId(null);
        setOperationId(null);
        getProducts(productCatId.value, ev.value);
    }
    const productChangeHandler = (ev) => {
        setProductId(ev);
        setSubAssemblyId(null);
        setPartId(null);
        setOperationId(null);
        getOperations(ev.value, null, null);
        getSubAssemblies(ev.value);
    }
    const subAssemblyChangeHandler = (ev) => {
        setSubAssemblyId(ev);
        setPartId(null);
        getParts(ev.value);
        getOperations(productId.value, ev.value, null);
    }
    const partChangeHandler = (ev) => {
        setPartId(ev);
        setOperationId(null);
        getOperations(productId.value, subAssemblyId.value, ev.value);
    }
    const operationChangeHandler = (ev) => {
        setOperationId(ev);
    }
    const employeeChangeHandler = (ev) => {
        setEmployeeId(ev);
    }

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_UPDATE_WORK_ALLOCATION,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        WorkAllocationId: params.id ? params.id : null,
                        Remarks: state.remarks,
                        CustomerId: 0,
                        WorkOrderId: 0,
                        JobNo: 0,
                        StockId: 0,
                        ProductId: productId.value,
                        SubAssemblyId: subAssemblyId ? subAssemblyId.value : 0,
                        PartId: partId ? partId.value : 0,
                        OperationId: operationId.value,
                        EmployeeId: employeeId.value,
                        AllocationDate: moment(allocationDate).format("MM/DD/YYYY"),
                        PlannedCompletionDate: moment(plannedCompletionDate).format("MM/DD/YYYY"),
                        AllocationFor: 'Stock',
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        if (params.id > 0) {
                            navigate('/workallocation/workallocationforstock');
                        }
                        else {
                            localStorage.setItem('productCategoryIdForWA', JSON.stringify(productCatId.value));
                            localStorage.setItem('productCategoryForWA', JSON.stringify(productCatId.label));
                            localStorage.setItem('productSubCategoryIdForWA', JSON.stringify(productSubCatId.value));
                            localStorage.setItem('productSubCategoryForWA', JSON.stringify(productSubCatId.label));
                            localStorage.setItem('productIdForWA', JSON.stringify(productId.value));
                            localStorage.setItem('productForWA', JSON.stringify(productId.label));
                            localStorage.setItem('subAssemblyIdForWA', JSON.stringify(subAssemblyId ? subAssemblyId.value : '0'));
                            localStorage.setItem('subAssemblyForWA', JSON.stringify(subAssemblyId ? subAssemblyId.label : 'Select Sub Assembly'));
                            localStorage.setItem('partIdForWA', JSON.stringify(partId ? partId.value : '0'));
                            localStorage.setItem('partForWA', JSON.stringify(partId ? partId.label : 'Select Part'));
                            window.location.reload();
                        }
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        if (params.id > 0) {
                            navigate('/workallocation/workallocationforstock');
                        }
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }        
        setLoading(false);
    }

    const backHandler = () => {
        navigate('/workallocation/workallocationforstock');
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <TableContainer component={Paper} style={{ width: '80%', alignSelf: 'center', }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                        <Select
                                                            name="productCategory"
                                                            options={productCatData} placeholder="Select Product Category"
                                                            value={productCatId}
                                                            onChange={productCatChangeHandler}
                                                            isSearchable
                                                            styles={selectStyles}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".productcategory"]}</div>
                                                    </MDBox>
                                                </TableCell>
                                                <TableCell>
                                                    <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                        <Select
                                                            name="productSubCategory"
                                                            options={productSubCatData} placeholder="Select Product Sub Category"
                                                            value={productSubCatId}
                                                            onChange={productSubCatChangeHandler}
                                                            isSearchable
                                                            styles={selectStyles}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".productsubcategory"]}</div>
                                                    </MDBox>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                        <Select
                                                            name="product"
                                                            options={productData} placeholder="Select Product"
                                                            value={productId}
                                                            onChange={productChangeHandler}
                                                            isSearchable
                                                            styles={selectStyles}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".product"]}</div>
                                                    </MDBox>
                                                </TableCell>
                                                <TableCell>
                                                    <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                        <Select
                                                            name="subassembly"
                                                            options={subAssemblyData} placeholder="Select Sub Assembly"
                                                            value={subAssemblyId}
                                                            onChange={subAssemblyChangeHandler}
                                                            isSearchable
                                                            styles={selectStyles}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".subassembly"]}</div>
                                                    </MDBox>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                        <Select
                                                            name="part"
                                                            options={partData} placeholder="Select Part"
                                                            value={partId}
                                                            onChange={partChangeHandler}
                                                            isSearchable
                                                            styles={selectStyles}
                                                        />
                                                    </MDBox>
                                                </TableCell>
                                                <TableCell>
                                                    <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                        <Select
                                                            name="operation"
                                                            options={operationData} placeholder="Select Operation"
                                                            value={operationId}
                                                            onChange={operationChangeHandler}
                                                            isSearchable
                                                            styles={selectStyles}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".operation"]}</div>
                                                    </MDBox>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                        <Select
                                                            name="employee"
                                                            options={employeeData} placeholder="Select Employee"
                                                            value={employeeId}
                                                            onChange={employeeChangeHandler}
                                                            isSearchable
                                                            styles={selectStyles}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".employee"]}</div>
                                                    </MDBox>
                                                </TableCell>
                                                <TableCell />
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Allocation Date
                                                    <DatePicker
                                                        id="allocationDate"
                                                        selected={allocationDate}
                                                        onChange={date => setAllocationDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                        tabIndex={0}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    Planned Completion Date
                                                    <DatePicker
                                                        id="plannedCompletionDate"
                                                        selected={plannedCompletionDate}
                                                        onChange={date => setPlannedCompletionDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                        tabIndex={0}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Remarks" fullWidth value={state.remarks || ''}
                                                        name="remarks"
                                                        onChange={changeHandler}
                                                        id="remarks"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".remarks"]}</div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>  &nbsp;&nbsp;&nbsp;&nbsp;
                                            <MDButton color="info" onClick={backHandler}>
                                                BACK
                                            </MDButton>
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddEditWorkAllocationForStock;