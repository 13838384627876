
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import Select from 'react-select';
import * as service from '../../../services/service';

function AddEditTaskAllocation() {
    const [state, setState] = useState({ remarks: '' });
    const [allocationDate, setAllocationDate] = useState(new Date());
    const [taskCategoryData, setTaskCategoryData] = useState([]);
    const [taskData, setTaskData] = useState([]);
    const [employeeData, setEmployeeData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [workOrderData, setWorkOrderData] = useState([]);
    const [supplierData, setSupplierData] = useState([]);
    const [customerId, setCustomerId] = useState('');
    const [workOrderId, setWorkOrderId] = useState('');
    const [supplierId, setSupplierId] = useState('');
    const [employeeId, setEmployeeId] = useState('');
    const [taskCategoryId, setTaskCategoryId] = useState('');
    const [taskId, setTaskId] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const navigate = useNavigate();
    const params = useParams();

    const getEmployees = () => {
        fetch(
            service.EMPLOYEE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.employeeId, label: item.employeeName }));
                setEmployeeData(data2);
            });
    };

    const getCustomers = () => {
        fetch(
            service.CUSTOMER_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.customerId, label: item.customerName }));
                setCustomerData(data2);
            });
    };

    const getWorkOrders = (id) => {
        fetch(
            service.WORK_ORDER_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CustomerId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.workOrderId, label: item.jobNo }));
                setWorkOrderData(data2);
            });
    };

    const getSuppliers = () => {
        fetch(
            service.SUPPLIER_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.supplierId, label: item.supplierName }));
                setSupplierData(data2);
            });
    };

    const getTaskCategory = () => {
        fetch(
            service.TASK_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.taskCategoryId, label: item.taskCategory }));
                setTaskCategoryData(data2);
            });
    };

    const getTasks = (id) => {
        fetch(
            service.GET_TASK_BY_CATEGORY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    TaskCategoryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.taskId, label: item.task }));
                setTaskData(data2);
            });
    };

    const getDetailsById = () => {
        setLoading(true);
        const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_TASK_ALLOCATION_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        TaskAllocationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setState({ ...state, remarks: result.remarks });

                        const emp = ({ value: result.employeeId, label: result.employeeName });
                        setEmployeeId(emp);
                        if (result.customerId > 0) {
                            const cust = ({ value: result.customerId, label: result.customerName });
                            setCustomerId(cust);
                            getWorkOrders(result.customerId);
                            const wo = ({ value: result.workOrderId, label: result.jobNo });
                            setWorkOrderId(wo);
                        }
                        if (result.supplierId > 0) {
                            const supp = ({ value: result.supplierId, label: result.supplierName });
                            setSupplierId(supp);
                        }

                        setAllocationDate(new Date(result.allocationDate));

                        const cat = ({ value: result.taskCategoryId, label: result.taskCategory });
                        setTaskCategoryId(cat);
                        getTasks(result.taskCategoryId);
                        const tk = ({ value: result.taskId, label: result.task });
                        setTaskId(tk);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    };

    useEffect(() => {
        getEmployees();
        getCustomers();
        getSuppliers();
        getTaskCategory();
        if (params.id > 0) {
            getDetailsById();
        }
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!taskCategoryId) {
            isValid = false;
            errs[".taskCategoryId"] = "Please select task category";
        }
        if (!taskId) {
            isValid = false;
            errs[".taskId"] = "Please select task";
        }
        if (!employeeId) {
            isValid = false;
            errs[".employee"] = "Please select employee";
        }
        if (!allocationDate) {
            isValid = false;
            errs[".allocationdate"] = "Please enter allocation date";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const employeeChangeHandler = (ev) => {
        setEmployeeId(ev);
    }
    const customerChangeHandler = (ev) => {
        setCustomerId(ev);
        getWorkOrders(ev.value);
    }
    const workOrderChangeHandler = (ev) => {
        setWorkOrderId(ev);
    }
    const supplierChangeHandler = (ev) => {
        setSupplierId(ev);
    }
    const taskCategoryChangeHandler = (ev) => {
        setTaskCategoryId(ev);
        getTasks(ev.value);
    }
    const taskChangeHandler = (ev) => {
        setTaskId(ev);
    }
    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_UPDATE_TASK_ALLOCATION,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        TaskAllocationId: params.id ? params.id : null,
                        Remarks: state.remarks,
                        TaskId: taskId.value,
                        CustomerId: customerId ? customerId.value : 0,
                        WorkOrderId: workOrderId ? workOrderId.value : 0,
                        JobNo: workOrderId ? workOrderId.label : 0,
                        SupplierId: supplierId ? supplierId.value : 0,
                        EmployeeId: employeeId.value,
                        AllocationDate: moment(allocationDate).format("MM/DD/YYYY"),
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/taskallocation/taskallocation');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        // navigate('/taskallocation/taskallocation');
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }
    const backHandler = () => {
        navigate('/taskallocation/taskallocation');
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <Table>
                                    <TableRow>
                                        <TableCell>
                                            <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                <Select
                                                    options={taskCategoryData}
                                                    name="taskCategory"
                                                    value={taskCategoryId}
                                                    onChange={taskCategoryChangeHandler}
                                                    placeholder="Select Task Category"
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".taskCategoryId"]}</div>
                                            </MDBox>
                                        </TableCell>
                                        <TableCell>
                                            <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                <Select
                                                    options={taskData}
                                                    name="task"
                                                    value={taskId}
                                                    onChange={taskChangeHandler}
                                                    placeholder="Select Task"
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".taskId"]}</div>
                                            </MDBox>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                <Select
                                                    options={customerData}
                                                    name="customerId"
                                                    value={customerId}
                                                    onChange={customerChangeHandler}
                                                    placeholder="Select Customer"
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".customerId"]}</div>
                                            </MDBox>
                                        </TableCell>
                                        <TableCell>
                                            <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                <Select
                                                    options={workOrderData}
                                                    name="workOrderId"
                                                    value={workOrderId}
                                                    onChange={workOrderChangeHandler}
                                                    placeholder="Select Job No"
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".workOrderId"]}</div>
                                            </MDBox>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                <Select
                                                    options={supplierData}
                                                    name="supplierId"
                                                    value={supplierId}
                                                    onChange={supplierChangeHandler}
                                                    placeholder="Select Supplier"
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".supplierId"]}</div>
                                            </MDBox>
                                        </TableCell>
                                        <TableCell>
                                            <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                <Select
                                                    options={employeeData}
                                                    name="employeeId"
                                                    value={employeeId}
                                                    onChange={employeeChangeHandler}
                                                    placeholder="Select Employee"
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".employee"]}</div>
                                            </MDBox>
                                        </TableCell>

                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            Allocation Date
                                            <DatePicker
                                                id="allocationDate"
                                                selected={allocationDate}
                                                onChange={date => setAllocationDate(date)}
                                                dateFormat="MM/dd/yyyy"
                                                tabIndex={0}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <MDInput label="Remarks" fullWidth value={state.remarks || ''}
                                                name="remarks"
                                                onChange={changeHandler}
                                                id="remarks"
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".remarks"]}</div>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>  &nbsp;&nbsp;&nbsp;&nbsp;
                                            <MDButton color="info" onClick={backHandler}>
                                                BACK
                                            </MDButton>
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddEditTaskAllocation;