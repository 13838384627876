// import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
// import MDAlert from "components/MDAlert";
// import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import { Radio } from '@mui/material';
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import * as moment from 'moment';
import * as service from '../../services/service';

function TaskDetails() {
    const [state, setState] = useState({ remarks: '' });
    const [data, setData] = useState([]);
    const [taskData, setTaskData] = useState([]);
    const [taskAllocationId, setTaskAllocationId] = useState('');
    const [taskDate, setTaskDate] = useState(new Date());
    const [timeSpent, setTimeSpentId] = useState('');
    const [taskStatus, setTaskStatusId] = useState('');
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);

    const timeData = [{ value: "1.00", label: "1.00" }, { value: "1.15", label: "1.15" }, { value: "1.30", label: "1.30" }, { value: "1.45", label: "1.45" },
    { value: "2.00", label: "2.00" }, { value: "2.15", label: "2.15" }, { value: "2.30", label: "2.30" }, { value: "2.45", label: "2.45" },
    { value: "3.00", label: "3.00" }, { value: "3.15", label: "3.15" }, { value: "3.30", label: "3.30" }, { value: "3.45", label: "3.45" },
    { value: "4.00", label: "4.00" }, { value: "4.15", label: "4.15" }, { value: "4.30", label: "4.30" }, { value: "4.45", label: "4.45" },
    { value: "5.00", label: "5.00" }, { value: "5.15", label: "5.15" }, { value: "5.30", label: "5.30" }, { value: "5.45", label: "5.45" },
    { value: "6.00", label: "6.00" }, { value: "6.15", label: "6.15" }, { value: "6.30", label: "6.30" }, { value: "6.45", label: "6.45" },
    { value: "7.00", label: "7.00" }, { value: "7.15", label: "7.15" }, { value: "7.30", label: "7.30" }, { value: "7.45", label: "7.45" },
    { value: "8.00", label: "8.00" }, { value: "8.15", label: "8.15" }, { value: "8.30", label: "8.30" }, { value: "8.45", label: "8.45" },
    { value: "9.00", label: "9.00" }, { value: "9.15", label: "9.15" }, { value: "9.30", label: "9.30" }, { value: "9.45", label: "9.45" },
    { value: "10.00", label: "10.00" }, { value: "10.15", label: "10.15" }, { value: "10.30", label: "10.30" }, { value: "10.45", label: "10.45" },
    { value: "11.00", label: "11.00" }, { value: "11.15", label: "11.15" }, { value: "11.30", label: "11.30" }, { value: "11.45", label: "11.45" },
    { value: "12.00", label: "12.00" }, { value: "12.15", label: "12.15" }, { value: "12.30", label: "12.30" }, { value: "12.45", label: "12.45" }
    ];

    const taskStatusData = [{ value: "In Progress", label: "In Progress" },
    { value: "Completed", label: "Completed" }];
    // const navigate = useNavigate();

    const getDetails = () => {
        fetch(
            service.GET_PENDING_TASK_ALLOCATION_BY_EMPLOYEE,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    EmployeeId: JSON.parse(sessionStorage.getItem('userData')).employeeId
                })
            })
            .then(res => res.json())
            .then(result => {
                setData(result);
            })
            .catch(err => {
                if (err) {
                    setError(err);
                };
            });
    };

    const getTaskDetails = (id) => {
        fetch(
            service.GET_TASK_DETAILS,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    TaskAllocationId: id
                })
            })
            .then(res => res.json())
            .then(result => {
                setTaskData(result);
            })
            .catch(err => {
                if (err) {
                    setError(err);
                };
            });
    };

    const handleRadioChange = (id) => {
        setTaskAllocationId(id);
        getTaskDetails(id);
    }

    const handleDelete = (id) => {
        setLoading(true);
        // eslint-disable-next-line
        if (window.confirm("Do you want to delete this task details?")) {
            fetch(
                service.DELETE_TASK_DETAILS,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        TaskDetailsId: id
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                });
            window.location.reload(false);
        }
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        getDetails();
        setLoading(false);
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!taskAllocationId) {
            isValid = false;
            errs[".taskAllocationId"] = "Please select task";
        }
        if (!taskDate) {
            isValid = false;
            errs[".taskDate"] = "Please select Task date";
        }
        if (!timeSpent) {
            isValid = false;
            errs[".timeSpent"] = "Please select time spent";
        }
        if (!taskStatus) {
            isValid = false;
            errs[".taskStatus"] = "Please select Task status";
        }
        setError(errs);
        return isValid;
    }

    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };
    const submitHandler = e => {       
        e.preventDefault();
       // const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_TASK_DETAILS,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        TaskAllocationId: taskAllocationId,
                        TaskStatus: taskStatus.value,
                        TaskDate: moment(taskDate).format("MM/DD/YYYY"),
                        TimeSpent: timeSpent.value,
                        Remarks: state.remarks,                       
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,                        
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        getTaskDetails(taskAllocationId);
                        setTaskDate(new Date());
                        setTimeSpentId('');
                        setTaskStatusId('');
                        setState({ remarks: "" })

                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);                        
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />            
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Task Details
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Task Category</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Task</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>TAT</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Task Remarks</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Allcoation Date</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Allcoation Remarks</TableCell>                                                
                                            </TableRow>

                                            <TableBody>
                                                {data.map((row) => (
                                                    <TableRow
                                                        key={row.taskAllocationId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell><Radio onChange={() => handleRadioChange(row.taskAllocationId)} 
                                                                    value={row.taskAllocationId}  name="radio-buttons" 
                                                                    checked={taskAllocationId === row.taskAllocationId} />
                                                        </TableCell>
                                                        <TableCell>{row.taskCategory}</TableCell>
                                                        <TableCell>{row.task}</TableCell>
                                                        <TableCell>{row.tat}</TableCell>
                                                        <TableCell>{row.taskRemarks}</TableCell>
                                                        <TableCell>{moment(row.allocationDate).format("DD/MM/YYYY")}</TableCell>                                                        
                                                        <TableCell>{row.remarks}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <div style={{ fontSize: '12px', color: 'red' }}>{error[".taskAllocationId"]}</div>
                                    </TableContainer>
                                }
                            </Card>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <TableContainer component={Paper} style={{ width: '80%', height: '300px', alignSelf: 'center', }}>
                                    <Table aria-label="simple table">
                                        <TableRow>
                                            <TableCell>
                                                Task Date
                                                <DatePicker
                                                    id="taskDate"
                                                    selected={taskDate}
                                                    onChange={date => setTaskDate(date)}
                                                    dateFormat="MM/dd/yyyy"
                                                    tabIndex={0}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{error[".taskDate"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Dropdown menuClassName='myMenuClassName' id="timeSpent"
                                                    name="timeSpent"
                                                    options={timeData} placeholder="Select Time Spent (Hrs.Mins)"
                                                    value={timeSpent}
                                                    onChange={e => setTimeSpentId(e)}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{error[".timeSpent"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Dropdown menuClassName='myMenuClassName' id="taskStatus"
                                                    name="taskStatus"
                                                    options={taskStatusData} placeholder="Select Task Status"
                                                    value={taskStatus}
                                                    onChange={e => setTaskStatusId(e)}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{error[".taskStatus"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Remarks" fullWidth value={state.remarks || ''}
                                                    name="remarks"
                                                    onChange={changeHandler}
                                                    id="remarks"
                                                    multiline
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{error[".remarks"]}</div>
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                    <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                    <MDButton color="info" onClick={submitHandler}>
                                    SAVE
                                </MDButton>
                                    }
                                    {error ? <div style={{ fontSize: '12px', color: 'red' }}>{error[".invalid"]}</div> : null}
                                </MDBox>
                                </TableContainer>                               
                            </Card>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '80%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Task Date</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Time Spent</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Task Status</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Remarks</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {taskData.map((row) => (
                                                    <TableRow
                                                        key={row.taskDetailsId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{moment(row.taskDate).format("DD/MM/YYYY")}</TableCell>
                                                        <TableCell>{row.timeSpent}</TableCell>
                                                        <TableCell>{row.taskStatus}</TableCell>
                                                        <TableCell>{row.remarks}</TableCell>
                                                        <TableCell><MDButton onClick={() => handleDelete(row.taskDetailsId)} disabled={row.taskStatus === "Completed"}>Delete</MDButton></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>                                        
                                    </TableContainer>
                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default TaskDetails;