
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// import { Button } from "react-bootstrap";
// import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import * as service from '../../../../services/service';

function AddEditMachine() {
    const [state, setState] = useState({ machinename: '', machinenumber: '' });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const params = useParams();
    const getDetailsById = () => {
        setLoading(true);
        const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_MACHINE_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        MachineId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setState({ ...state, machinename: result.machineName, machinenumber: result.machineNumber });
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }

                });
        }
        setLoading(false);
    };

    useEffect(() => {
        if (params.id > 0) {
            getDetailsById();
        }
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!state.machinename) {
            isValid = false;
            errs[".machinename"] = "Please enter machine name";
        }
        if (!state.machinenumber) {
            isValid = false;
            errs[".machinenumber"] = "Please enter machine number";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_UPDATE_MACHINE,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        MachineId: params.id ? params.id : null,
                        MachineName: state.machinename,
                        MachineNumber: state.machinenumber,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/masters/machine');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/masters/machine');
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }
    const backHandler = () => {
        navigate('/masters/machine');
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <MDBox pt={4} pb={3} px={3} style={{ width: '50%' }}>
                                    <MDBox component="form" role="form">
                                        <MDBox mb={2}>
                                            <MDInput label="Machine Name" fullWidth value={state.machinename || ''}
                                                name="machinename"
                                                onChange={changeHandler}
                                                id="machinename"
                                                required />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".machinename"]}</div>
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput label="Machine Number" fullWidth value={state.machinenumber || ''}
                                                name="machinenumber"
                                                onChange={changeHandler}
                                                id="machinenumber"
                                                required />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".machinenumber"]}</div>
                                        </MDBox>
                                        <MDBox mt={4} mb={1} textAlign="center">
                                            {loading ? <Oval
                                                heigth="20"
                                                width="20"
                                                color='grey'
                                                ariaLabel='loading'
                                            /> :
                                                <div>
                                                    <MDButton color="info" onClick={submitHandler}>
                                                        SAVE
                                                    </MDButton>  &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <MDButton color="info" onClick={backHandler}>
                                                        BACK
                                                    </MDButton>
                                                </div>
                                            }
                                            {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddEditMachine;