// import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
// import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MDInput from "components/MDInput";
import * as moment from 'moment';
import { Radio } from '@mui/material';
import * as service from '../../../../services/service';


function AddPurchaseOrderForWork() {
    const [state, setState] = useState({
        rate: '', amount: '', cgst: '', sgst: '', igst: '', totalamount: '',
        cgstpercent: '', sgstpercent: '', igstpercent: '', hsncode: '', terms: '1.Estimate as per drawing should be set to us and get approved copy.; 2.Invoice should bear our reference Delivery Challan No (JW__), Delivery Challan Date and Item Description as mentioned in the Delivery Challan.;3.Your invoice must be accompanied with certified delivery challan & inspection report.; 4.Weight should be mentioned in the challan.; 5.Delivery period – within 1-5 days.; 6.Transport Mode – By road.; 7.Transporter Name – Arranged by you / to be arranged by ourselves.; 8.Payment Term – 60 / 90 Days.; 9.GST will be paid as applicable. ; 10. Material should be supplied before 04:30 PM only. ; 11.Delivery of goods shall be made on the delivery address.; 12.Material should be with test certificate if required.'
    });
    const [allocationData, setAllocationData] = useState([]);
    const [workAllocationId, setWorkAllocationId] = useState('');
    const [purchaseOrderDate, setPurchaseOrderDate] = useState(new Date());
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    // const navigate = useNavigate();
    const geWorkAllocationToSupplier = () => {
        fetch(
            service.GET_APPROVED_WORK_ALLOCATION_TO_SUPPLIER,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                if (result[0].message.messageType === 'Failed') {
                    setAllocationData([]);
                }
                else {
                    setAllocationData(result);
                }
            });
    };
    
    // const getHSNCodeByOperationId = (id) => {
    //     fetch(
    //         service.GET_HSNCODE_BY_OPERATION_ID,
    //         {
    //             method: 'POST',
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({
    //                 OperationId: id
    //             })
    //         })
    //         .then(res => res.json())
    //         .then(result => {
    //             if (result.message.messageType === 'Failed') {
    //                 setState({
    //                     ...state, cgstpercent: '0', sgstpercent: '0',
    //                     igstpercent: '0', hsncode: 'Not Available'
    //                 });
    //             }
    //             else {
    //                 setState({
    //                     ...state, cgstpercent: result.cgst === 0 ? '0' : result.cgst,
    //                     sgstpercent: result.sgst === 0 ? '0' : result.sgst,
    //                     igstpercent: result.igst === 0 ? '0' : result.igst, hsncode: result.hsnCode
    //                 });
    //             }
    //         });
    // };

    function calculate(rt, qty, cg, sg, ig, hsn) {        
        const amt = (parseFloat(rt) * parseFloat(qty)).toFixed(2);
        const cgstAmt = ((amt * parseFloat(cg)) / 100) === 0 ? '0' : ((amt * parseFloat(cg)) / 100).toFixed(2);
        const sgstAmt = ((amt * parseFloat(sg)) / 100) === 0 ? '0' : ((amt * parseFloat(sg)) / 100).toFixed(2);;
        const igstAmt = ((amt * parseFloat(ig)) / 100) === 0 ? '0' : ((amt * parseFloat(ig)) / 100).toFixed(2);;

        const total = (parseFloat(amt) + parseFloat(cgstAmt) + parseFloat(sgstAmt) + parseFloat(igstAmt)).toFixed(2);;
        setState({ ...state, rate: rt, cgst: cgstAmt, sgst: sgstAmt, igst: igstAmt, amount: amt, totalamount: total,
            cgstpercent: cg === 0 ? '0' : cg, sgstpercent: sg === 0 ? '0' : sg,
            igstpercent: ig === 0 ? '0' : ig, hsncode: hsn        
        });
    }
    
    const getSupplierRateForOperation = (supId, opId) => {
        fetch(
            service.GET_SUPPLIER_RATE_FOR_OPERATION,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SupplierId: supId,
                    OperationId: opId
                })
            })
            .then(res => res.json())
            .then(result => {                
                if (result.message.messageType === 'Failed') {
                    setState({
                        ...state, rate: '0'
                    });
                }
                else {
                    setState({
                        ...state, rate: result.rate === 0 ? '0' : result.rate
                    });
                }

                fetch(
                    service.GET_HSNCODE_BY_OPERATION_ID,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            OperationId: opId
                        })
                    })
                    .then(res => res.json())
                    .then(result1 => {                       
                        if (result1.message.messageType === 'Failed') {
                            setState({
                                ...state, cgstpercent: '0', sgstpercent: '0',
                                igstpercent: '0', hsncode: 'Not Available'
                            });
                            calculate(result.rate, 1, 0, 0, 0, 'Not Available');
                        }
                        else {
                            setState({
                                ...state, cgstpercent: result1.cgst === 0 ? '0' : result1.cgst,
                                sgstpercent: result1.sgst === 0 ? '0' : result1.sgst,
                                igstpercent: result1.igst === 0 ? '0' : result1.igst, hsncode: result1.hsnCode
                            });

                            calculate(result.rate, 1, result1.cgst, result1.sgst, result1.igst, result1.hsnCode);
                        }
                    });
            });
    };

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!workAllocationId) {
            isValid = false;
            errs[".workAllocationId"] = "Please select work allocation";
        }
        if (!purchaseOrderDate) {
            isValid = false;
            errs[".purchaseOrderDate"] = "Please select date";
        }
        if (!state.rate) {
            isValid = false;
            errs[".rate"] = "Please enter rate";
        }
        if (!state.amount) {
            isValid = false;
            errs[".amount"] = "Please enter amount";
        }
        if (!state.totalamount) {
            isValid = false;
            errs[".totalamount"] = "Please enter total amount";
        }
        setErrors(errs);
        return isValid;
    }
    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_PURCHASE_ORDER_FOR_WORK,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        PurchaseOrderDate: moment(purchaseOrderDate).format("MM/DD/YYYY"),
                        WorkAllocationId: workAllocationId,
                        Rate: state.rate,
                        Amount: state.amount,
                        CGST: state.cgst ? state.cgst : 0,
                        SGST: state.sgst ? state.sgst : 0,
                        IGST: state.igst ? state.igst : 0,
                        TotalAmount: state.totalamount,
                        CGSTPercent: state.cgstpercent,
                        SGSTPercent: state.sgstpercent,
                        IGSTPercent: state.igstpercent,
                        TermsConditions: state.terms,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        window.location.reload();
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }
    // function calculate() {
    //     const amt = state.amount;
    //     const cgstAmt = ((amt * state.cgstpercent) / 100) === 0 ? '0' : (amt * state.cgstpercent) / 100;
    //     const sgstAmt = ((amt * state.sgstpercent) / 100) === 0 ? '0' : (amt * state.sgstpercent) / 100;
    //     const igstAmt = ((amt * state.igstpercent) / 100) === 0 ? '0' : (amt * state.igstpercent) / 100;
    //     const total = parseFloat(amt) + parseFloat(cgstAmt) + parseFloat(sgstAmt) + parseFloat(igstAmt);
    //     setState({ ...state, cgst: cgstAmt, sgst: sgstAmt, igst: igstAmt, totalamount: total })
    // }    

    useEffect(() => {
        setLoading(true);
        geWorkAllocationToSupplier();
        setLoading(false);
    }, []);

    // useEffect(() => {
    //     setLoading(true);
    //     calculate();
    //     setLoading(false);
    // }, [state.rate, state.amount]);

    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });        
    };

    const handleRadioChange = (id, operationId, supId) => {
        setWorkAllocationId(id);
        // getHSNCodeByOperationId(operationId);
        getSupplierRateForOperation(supId, operationId);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Purchase Order Details
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow>
                                                <TableCell colSpan="13" align='center' style={{ fontWeight: 'bold' }}>
                                                    Work Allocations To Suppliers
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Allocation For</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Customer</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Job No</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Product</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Sub Assembly</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Part</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Operation</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Supplier</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Allocated Date</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Planned Completion Date</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Remarks</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {allocationData.map((row) => (
                                                    <TableRow
                                                        key={row.workAllocationId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell><Radio onChange={() => handleRadioChange(row.workAllocationId, row.operationId, row.supplierId)}
                                                            value={row.workAllocationId} name="radio-buttons"
                                                            checked={workAllocationId === row.workAllocationId} />
                                                        </TableCell>
                                                        <TableCell>{row.allocationFor}</TableCell>
                                                        <TableCell>{row.customerName}</TableCell>
                                                        <TableCell>{row.jobNo}</TableCell>
                                                        <TableCell>{row.product}</TableCell>
                                                        <TableCell>{row.subAssembly}</TableCell>
                                                        <TableCell>{row.part}</TableCell>
                                                        <TableCell>{row.operation}</TableCell>
                                                        <TableCell>{row.supplierName}</TableCell>
                                                        <TableCell>{moment(row.allocationDate).format("DD/MM/YYYY")}</TableCell>
                                                        <TableCell>{moment(row.plannedCompletionDate).format("DD/MM/YYYY")}</TableCell>
                                                        <TableCell>{row.remarks}</TableCell>
                                                        <TableCell>{row.status}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".workAllocationId"]}</div>
                                    </TableContainer>
                                }
                                <br />
                                <br />
                                <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                    <Table aria-label="simple table">
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                HSN Code : {state.hsncode}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                CGST Percent : {state.cgstpercent}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                SGST Percent : {state.sgstpercent}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                IGST Percent : {state.igstpercent}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Purchase Order Date
                                                <DatePicker
                                                    id="purchaseOrderDate"
                                                    selected={purchaseOrderDate}
                                                    onChange={date => setPurchaseOrderDate(date)}
                                                    dateFormat="MM/dd/yyyy"
                                                    tabIndex={0}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".purchaseOrderDate"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Rate" fullWidth value={state.rate || ''}
                                                    name="rate"
                                                    onChange={changeHandler}
                                                    id="rate"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".rate"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Amount" fullWidth value={state.amount || ''}
                                                    name="amount"
                                                    onChange={changeHandler}
                                                    id="amount"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".amount"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="CGST" fullWidth value={state.cgst || ''}
                                                    name="cgst"
                                                    onChange={changeHandler}
                                                    id="cgst"
                                                    disabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".cgst"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="SGST" fullWidth value={state.sgst || ''}
                                                    name="sgst"
                                                    onChange={changeHandler}
                                                    id="sgst"
                                                    disabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".sgst"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="IGST" fullWidth value={state.igst || ''}
                                                    name="igst"
                                                    onChange={changeHandler}
                                                    id="igst"
                                                    disabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".igst"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Total Amount" fullWidth value={state.totalamount || ''}
                                                    name="totalamount"
                                                    onChange={changeHandler}
                                                    id="totalamount"
                                                    disabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".totalamount"]}</div>
                                            </TableCell>                                           
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '12px' }}>
                                                <MDInput label="Terms and Conditions" fullWidth value={state.terms || ''}
                                                    name="terms"
                                                    onChange={changeHandler}
                                                    id="terms"
                                                    multiline
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".terms"]}</div>
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                </TableContainer>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <MDButton color="info" onClick={submitHandler}>
                                            SAVE
                                        </MDButton>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default AddPurchaseOrderForWork;