import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { Oval } from 'react-loader-spinner';
import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from 'react-select';
import * as service from '../../../services/service';

function MaterialSubCategory() {
    const [data, setData] = useState([]);
    const [materialCategoryData, setMaterialCategoryData] = useState([]);
    const [materialCategoryId, setMaterialCategoryId] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const userDesignation = JSON.parse(sessionStorage.getItem('userData')).designation;
    const navigate = useNavigate();
    const getMaterialCategories = () => {
        fetch(
            service.MATERIAL_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.materialCategoryId, label: item.materialCategory }));
                setMaterialCategoryData(data2);
            });
    };
    const getDetails = (id) => {
        if (id > 0) {
            fetch(
                service.MATERIAL_SUB_CATEGORY_URL,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        materialCategoryId: id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    setData(result);
                })
                .catch(err => {
                    if (err) {
                        setError(err);
                    };
                });
        }
    };

    const handleEdit = (id) => {
        navigate(`/addeditmaterialsubcategory/${id}`);
    }

    const changeHandler = (ev) => {
        setMaterialCategoryId(ev);
        getDetails(ev.value);
    }

    useEffect(() => {
        setLoading(true);
        getMaterialCategories();
        if (JSON.parse(localStorage.getItem('materialCategoryId')) != null) {
            const item = ({ value: JSON.parse(localStorage.getItem('materialCategoryId')), label: JSON.parse(localStorage.getItem('materialCategory')) });
            setMaterialCategoryId(item);
            getDetails(JSON.parse(localStorage.getItem('materialCategoryId')));
        };
        localStorage.setItem('materialCategoryId', null);
        localStorage.setItem('materialCategory', null);
        setLoading(false);
    }, []);

    const addNew = () => {
        navigate('/addeditmaterialsubcategory/0');
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {error
                ? <MDAlert color="warning" dismissible>{error}</MDAlert>
                : null
            }
            {userDesignation === 'General Manager' || userDesignation === 'Manager - Production'
                || userDesignation === 'Sr Mgr - Production' || userDesignation === 'MD' ?
                <div style={{ float: 'right' }}>
                    <MDButton color="info" onClick={addNew}>
                        ADD NEW
                    </MDButton>
                </div>
                : null}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Material Sub Category Details
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <Table aria-label="simple table">
                                    <TableRow>
                                        <TableCell align='right' style={{ fontSize: '12px', width: '30%' }}>Material Category : </TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                            <Select
                                                name="materialcategory"
                                                options={materialCategoryData} placeholder="Select Material Category"
                                                value={materialCategoryId}
                                                onChange={changeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                        <TableCell style={{ fontSize: '12px', width: '30%' }} />
                                    </TableRow>
                                </Table>
                                <br />
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '80%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Material Category</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Material Sub Category</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Material Sub Category Code</TableCell>
                                                {userDesignation === 'General Manager' || userDesignation === 'Manager - Production'
                                                    || userDesignation === 'Sr Mgr - Production' || userDesignation === 'MD' ?
                                                    <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                                                    : null}
                                            </TableRow>
                                            <TableBody>
                                                {data.map((row) => (
                                                    <TableRow
                                                        key={row.materialSubcategoryId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">{row.materialCategory}</TableCell>
                                                        <TableCell component="th" scope="row">{row.materialSubCategory}</TableCell>
                                                        <TableCell component="th" scope="row">{row.materialSubCategoryCode}</TableCell>
                                                        {userDesignation === 'General Manager' || userDesignation === 'Manager - Production'
                                                            || userDesignation === 'Sr Mgr - Production' || userDesignation === 'MD' ?
                                                            <TableCell><MDButton onClick={() => handleEdit(row.materialSubCategoryId)}>Edit</MDButton></TableCell>
                                                            : null}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default MaterialSubCategory;