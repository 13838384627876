import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import Select from 'react-select';
import * as moment from 'moment';
import * as service from '../../../services/service';

function PurchaseOrder() {
    const [data, setData] = useState([]);
    const [supplierData, setSupplierData] = useState([]);
    const [supplierId, setSupplierId] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const userDesignation = JSON.parse(sessionStorage.getItem('userData')).designation;
    const navigate = useNavigate();
    const getSupplier = () => {
        fetch(
            service.SUPPLIER_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.supplierId, label: item.supplierName }));
                setSupplierData(data2);
            });
    };
    const getDetails = (id) => {
        if (id > 0) {
            fetch(
                service.GET_PURCHASE_ORDER_BY_SUPPLIER,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        SupplierId: id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    setData(result);
                })
                .catch(err => {
                    if (err) {
                        setError(err);
                    };
                });
        }
    };

    const changeHandler = (ev) => {
        setSupplierId(ev);
        getDetails(ev.value);
    }

    useEffect(() => {
        setLoading(true);
        getSupplier();
        setLoading(false);
    }, []);

    const addNew = () => {
        navigate('/addpurchaseorder/0');
    };

    const handleDelete = (id) => {
        // eslint-disable-next-line
        if (window.confirm("Do you want to delete this record?")) {
            fetch(
                service.DELETE_PURCHASE_ORDER_DETAILS_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        PurchaseOrderId: id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        window.location.reload();
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                });
        }
    };

    const handleEdit = (id) => {
        navigate(`/printpurchaseorder/${id}`);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {error
                ? <MDAlert color="warning" dismissible>{error}</MDAlert>
                : null
            }
            {userDesignation === 'General Manager' || userDesignation === 'Manager - Purchase'
                || userDesignation === 'Sr Mgr - Purchase' || userDesignation === 'MD' 
                || userDesignation === 'Jr Mgr - Purchase' ?
                <div style={{ float: 'right' }}>
                    <MDButton color="info" onClick={addNew}>
                        ADD NEW
                    </MDButton>
                </div>
                : null}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Purchase Orders
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <Table aria-label="simple table">
                                    <TableRow>
                                        <TableCell align='right' style={{ fontSize: '12px', width: '30%' }}>
                                            Supplier :
                                        </TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                            <Select
                                                name="supplier"
                                                options={supplierData} placeholder="Select Supplier"
                                                value={supplierId}
                                                onChange={changeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px', width: '30%' }} />
                                    </TableRow>
                                </Table>
                                <br />
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '90%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Purchase Order No</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Purchase Order Date</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Material</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Quantity</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Rate</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Amount</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>TotalAmount</TableCell>
                                                {userDesignation === 'General Manager' || userDesignation === 'Manager - Purchase'
                                                    || userDesignation === 'Sr Mgr - Purchase' || userDesignation === 'MD' 
                                                    || userDesignation === 'Jr Mgr - Purchase' ?
                                                    <TableCell style={{ fontWeight: 'bold' }}>Delete</TableCell>
                                                    : null}
                                                {userDesignation === 'General Manager' || userDesignation === 'Manager - Purchase'
                                                    || userDesignation === 'Sr Mgr - Purchase' || userDesignation === 'MD' 
                                                    || userDesignation === 'Jr Mgr - Purchase' ?
                                                    <TableCell style={{ fontWeight: 'bold' }}>PO Report</TableCell>
                                                    : null}
                                            </TableRow>
                                            <TableBody>
                                                {data.map((row) => (
                                                    <TableRow
                                                        key={row.purchaseOrderId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">{row.purchaseOrderNo}</TableCell>
                                                        <TableCell component="th" scope="row">{moment(row.purchaseOrderDate).format("DD/MM/YYYY")}</TableCell>
                                                        <TableCell component="th" scope="row">{row.material}</TableCell>
                                                        <TableCell component="th" scope="row">{row.quantity}</TableCell>
                                                        <TableCell component="th" scope="row">{row.rate}</TableCell>
                                                        <TableCell component="th" scope="row">{row.amount}</TableCell>
                                                        <TableCell component="th" scope="row">{row.totalAmount}</TableCell>
                                                        {userDesignation === 'General Manager' || userDesignation === 'Manager - Purchase'
                                                            || userDesignation === 'Sr Mgr - Purchase' || userDesignation === 'MD' 
                                                            || userDesignation === 'Jr Mgr - Purchase' ?
                                                            <TableCell><MDButton onClick={() => handleDelete(row.purchaseOrderId)}>Delete</MDButton></TableCell>
                                                            : null}
                                                        {userDesignation === 'General Manager' || userDesignation === 'Manager - Purchase'
                                                            || userDesignation === 'Sr Mgr - Purchase' || userDesignation === 'MD' 
                                                            || userDesignation === 'Jr Mgr - Purchase' ?
                                                            <TableCell><MDButton onClick={() => handleEdit(row.purchaseOrderId)}>View</MDButton></TableCell>
                                                            : null}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default PurchaseOrder;