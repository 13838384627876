
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Select from 'react-select';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
// import { useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import { Radio } from '@mui/material';
import * as service from '../../services/service';

function AddMaterialIssue() {
    const [state, setState] = useState({ issuedquantity: '', remarks: '' });
    const [issuedDate, setIssuedDate] = useState(new Date());
    const [allocationData, setAllocationData] = useState([]);
    const [subAssemblyMaterialData, setSubAssemblyMaterialData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [workOrderData, setWorkOrderData] = useState([]);
    const [employeeData, setEmployeeData] = useState([]);
    const [customerId, setCustomerId] = useState('');
    const [workOrderId, setWorkOrderId] = useState('');
    const [employeeId, setEmployeeId] = useState('');
    const [stockId, setStockId] = useState('');
    // const [productId, setProductId] = useState('');
    // const [subAssemblyId, setSubAssemblyId] = useState('');
    // const [partId, setPartId] = useState('');
    const [materialCategoryData, setMaterialCategoryData] = useState([]);
    const [materialSubCategoryData, setMaterialSubCategoryData] = useState([]);
    const [materialData, setMaterialData] = useState([]);
    const [materialCategoryId, setMaterialCategoryId] = useState('');
    const [materialSubCategoryId, setMaterialSubCategoryId] = useState('');
    const [materialId, setMaterialId] = useState('');
    const [availableQtyData, setAvailableQtyData] = useState([]);
    const [materialIdForWO, setMaterialIdForWO] = useState('');

    const [issueFor, setIssueFor] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const issueForData = [{ label: 'Work Order', value: 'Work Order' }, { label: 'Office Purpose', value: 'Office Purpose' }]
    const userDesignation = JSON.parse(sessionStorage.getItem('userData')).designation;
    const [qty, setQty] = useState('');

    const getCustomers = () => {
        fetch(
            service.CUSTOMER_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.customerId, label: item.customerName }));
                setCustomerData(data2);
            });
    };

    const getWorkOrders = (id) => {
        fetch(
            service.GET_PENDING_WORK_ORDER,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CustomerId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.workOrderId, label: item.jobNo }));
                setWorkOrderData(data2);
            });
    };

    const getStock = (id) => {
        const errs = {};
        fetch(
            service.GET_REQUIRED_STOCK,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    WorkOrderId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result[0].message.messageType === "Success") {
                    setAllocationData(result);
                }
                else {
                    errs[".noreqdata"] = result[0].message.message;
                    setErrors(errs);
                }
            })
            .catch(err => {
                if (err) {
                    setErrors(err);
                };
            });
    };

    const getMaterialCategories = () => {
        fetch(
            service.MATERIAL_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.materialCategoryId, label: item.materialCategory }));
                setMaterialCategoryData(data2);
            });
    };
    const getMaterialSubCategories = (id) => {
        fetch(
            service.MATERIAL_SUB_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    materialCategoryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.materialSubCategoryId, label: item.materialSubCategory }));
                setMaterialSubCategoryData(data2);
            });
    };
    const getMaterials = (id) => {
        fetch(
            service.MATERIAL_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    materialCategoryId: materialCategoryId.value,
                    materialSubCategoryId: id
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.materialId, label: item.material }));
                setMaterialData(data2);
            });
    };

    const getEmployees = () => {
        fetch(
            service.EMPLOYEE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.employeeId, label: item.employeeName }));
                setEmployeeData(data2);
            });
    };

    const getSubAssemblyPartMaterial = (subId, parId) => {
        fetch(
            service.GET_SUBASSEMBLY_MATERIAL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SubAssemblyId: subId,
                    PartId: parId
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result[0].message.messageType === "Failed") {
                    setSubAssemblyMaterialData(null);
                }
                else {
                    setSubAssemblyMaterialData(result);
                }
            })
            .catch(err => {
                if (err) {
                    setErrors(err);
                };
            });
    };

    const getMaterialAvailableQuantity = (id) => {
        setLoading(true);
        fetch(
            service.GET_MATERIAL_AVAILABLE_QUANTITY,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    MaterialId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.message.messageType === "Success") {
                    setAvailableQtyData(result);
                }
                else {
                    setAvailableQtyData(null);
                }
            })
            .catch(err => {
                if (err) {
                    setErrors(err);
                };
            });
        setLoading(false);
    };

    useEffect(() => {
        getCustomers();
        getEmployees();
        getMaterialCategories();
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!issueFor) {
            isValid = false;
            errs[".issueFor"] = "Please select issue for";
        }
        if (issueFor.value === "Work Order" && !customerId) {
            isValid = false;
            errs[".customer"] = "Please select customer";
        }
        if (issueFor.value === "Work Order" && !workOrderId) {
            isValid = false;
            errs[".workorder"] = "Please select work order";
        }
        if (issueFor.value === "Work Order" && !materialIdForWO) {
            isValid = false;
            errs[".materialIdForWO"] = "Please select material";
        }
        if (issueFor.value === "Office Purpose" && !materialCategoryId) {
            isValid = false;
            errs[".materialCategoryId"] = "Please select material category";
        }
        if (issueFor.value === "Office Purpose" && !materialSubCategoryId) {
            isValid = false;
            errs[".materialSubCategoryId"] = "Please select material sub category";
        }
        if (issueFor.value === "Office Purpose" && !materialId) {
            isValid = false;
            errs[".materialId"] = "Please select material";
        }
        if (!employeeId) {
            isValid = false;
            errs[".employee"] = "Please select issue to";
        }
        if (!issuedDate) {
            isValid = false;
            errs[".issuedDate"] = "Please enter issue date";
        }
        if (issuedDate && moment(issuedDate).format("MM/DD/YYYY") > moment(new Date()).format("MM/DD/YYYY")) {
            isValid = false;
            errs[".issuedDate"] = "Issue date can not be greater than today";
        }
        if (!state.issuedquantity) {
            isValid = false;
            errs[".issuedquantity"] = "Please enter issue quantity";
        }
        if (issueFor.value === "Office Purpose" && state.issuedquantity && state.issuedquantity > availableQtyData.availableQuantity) {
            isValid = false;
            errs[".issuedquantity"] = "Issued Quantity can not be greater than available quantity";
        }
        if (issueFor.value === "Work Order" && qty && state.issuedquantity > qty) {
            isValid = false;
            errs[".issuedquantity"] = "Issued Quantity can not be greater than available quantity";
        }

        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };
    const handleRadioChange = (stId, subId, ptId) => {
        setStockId(stId);
        // setProductId(prId);
        // setSubAssemblyId(subId);
        // setPartId(ptId);
        getSubAssemblyPartMaterial(subId, ptId);
        // getOperations(subId, ptId);
        // setWorkAllocationData(null);
        // getWorkAllocation(stId, prId, subId, ptId);
    }
    const handleMaterialRadioChange = (mtId, qt) => {
        setMaterialIdForWO(mtId);
        setQty(qt);
    }
    const issueForChangeHandler = (ev) => {
        setIssueFor(ev);
        setCustomerId(null);
        setWorkOrderId(null);
        setAllocationData([]);
        setSubAssemblyMaterialData([]);
        setMaterialCategoryId(null);
        setMaterialSubCategoryId(null);
        setMaterialId(null);
    }
    const customerChangeHandler = (ev) => {
        setCustomerId(ev);
        setWorkOrderId(null);
        getWorkOrders(ev.value);
    }
    const workOrderChangeHandler = (ev) => {
        setWorkOrderId(ev);
        getStock(ev.value);
    }
    const employeeChangeHandler = (ev) => {
        setEmployeeId(ev);
    }
    const materialCategoryChangeHandler = (ev) => {
        setMaterialCategoryId(ev);
        setMaterialSubCategoryId(null);
        setMaterialId(null);
        getMaterialSubCategories(ev.value);
    }
    const materialSubCategoryChangeHandler = (ev) => {
        setMaterialSubCategoryId(ev);
        setMaterialId(null);
        getMaterials(ev.value);
    }
    const materialChangeHandler = (ev) => {
        setMaterialId(ev);
        getMaterialAvailableQuantity(ev.value);
    }

    const submitHandler = e => {
        e.preventDefault();
        let matId = 0;
        const errs = {};
        setLoading(true);
        if (validate()) {
            // eslint-disable-next-line
            if (window.confirm("Do you really want to issue the quantity? You can't change it later...")) {
                if (issueFor.value === 'Work Order') {
                    matId = materialIdForWO;
                }
                else {
                    matId = materialId.value;
                }
                fetch(
                    service.ADD_MATERIAL_ISSUE,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            MaterialId: matId,
                            IssuedQuantity: state.issuedquantity,
                            IssuedDate: moment(issuedDate).format("MM/DD/YYYY"),
                            IssuedTo: employeeId.value,
                            Remarks: state.remarks,
                            AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                            UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                        })
                    })
                    .then(res => res.json())
                    .then(result => {
                        if (result.messageType === 'Success') {
                            // eslint-disable-next-line
                            alert(result.message);
                            window.location.reload(false);
                            // navigate('/workallocation/workallocation');
                        }
                        else if (result.messageType === 'Failed') {
                            // eslint-disable-next-line
                            alert(result.message);
                            // navigate('/workallocation/workallocation');
                        }
                        else {
                            errs[".invalid"] = result.message;
                            setErrors(errs);
                        }
                    });
            }
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '12px' }}>
                                                <Select
                                                    options={issueForData}
                                                    name="issueFor"
                                                    value={issueFor}
                                                    onChange={issueForChangeHandler}
                                                    placeholder="Select For"
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".issueFor"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                                <Select
                                                    options={customerData}
                                                    name="customer"
                                                    value={customerId}
                                                    onChange={customerChangeHandler}
                                                    placeholder="Select Customer"
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled={issueFor.value === 'Office Purpose'}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".customer"]}</div>

                                            </TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                                <Select
                                                    options={workOrderData}
                                                    name="workorder"
                                                    value={workOrderId}
                                                    onChange={workOrderChangeHandler}
                                                    placeholder="Select Job Number"
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled={issueFor.value === 'Office Purpose'}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".workorder"]}</div>

                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={3} align='center'>
                                                {loading ? <Oval
                                                    heigth="100"
                                                    width="100"
                                                    color='grey'
                                                    ariaLabel='loading'
                                                /> :
                                                    <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                                        <Table aria-label="simple table">
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                                                                <TableCell style={{ fontWeight: 'bold' }}>Product</TableCell>
                                                                <TableCell style={{ fontWeight: 'bold' }}>Sub Assembly</TableCell>
                                                                <TableCell style={{ fontWeight: 'bold' }}>Part</TableCell>
                                                                <TableCell style={{ fontWeight: 'bold' }}>Stock Number</TableCell>
                                                            </TableRow>

                                                            <TableBody>
                                                                {allocationData.map((row) => (
                                                                    <TableRow
                                                                        key={row.stockId}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell><Radio onChange={() => handleRadioChange(row.stockId, row.subAssemblyId, row.partId)}
                                                                            value={row.stockId} name="radio-buttons"
                                                                            checked={stockId === row.stockId} />
                                                                        </TableCell>
                                                                        <TableCell>{row.product}</TableCell>
                                                                        <TableCell>{row.subAssembly}</TableCell>
                                                                        <TableCell>{row.part}</TableCell>
                                                                        <TableCell>{row.stockNumber}</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".stockId"]}</div>
                                                    </TableContainer>
                                                }
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={3} align='center'>
                                                {loading ? <Oval
                                                    heigth="100"
                                                    width="100"
                                                    color='grey'
                                                    ariaLabel='loading'
                                                /> :
                                                    <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                                        <Table aria-label="simple table">
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                                                                <TableCell style={{ fontWeight: 'bold' }}>Material Category</TableCell>
                                                                <TableCell style={{ fontWeight: 'bold' }}>Material Sub Category</TableCell>
                                                                <TableCell style={{ fontWeight: 'bold' }}>Material</TableCell>
                                                                <TableCell style={{ fontWeight: 'bold' }}>Required Quantity</TableCell>
                                                                <TableCell style={{ fontWeight: 'bold' }}>Available Quantity</TableCell>
                                                            </TableRow>
                                                            {subAssemblyMaterialData ?
                                                                <TableBody>
                                                                    {subAssemblyMaterialData.map((row) => (
                                                                        <TableRow
                                                                            key={row.materialId}
                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        >
                                                                            <TableCell><Radio onChange={() => handleMaterialRadioChange(row.materialId, row.availableQuantity)}
                                                                                value={row.materialId} name="radio-buttons"
                                                                                checked={materialIdForWO === row.materialId} />
                                                                            </TableCell>
                                                                            <TableCell>{row.materialCategory}</TableCell>
                                                                            <TableCell>{row.materialSubCategory}</TableCell>
                                                                            <TableCell>{row.material}</TableCell>
                                                                            <TableCell>{row.quantity}</TableCell>
                                                                            <TableCell>{row.availableQuantity}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                                : null}
                                                        </Table>
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".materialIdForWO"]}</div>
                                                    </TableContainer>
                                                }
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                                <Select
                                                    options={materialCategoryData}
                                                    name="materialCategory"
                                                    value={materialCategoryId}
                                                    onChange={materialCategoryChangeHandler}
                                                    placeholder="Select Material Category"
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled={issueFor.value === 'Work Order'}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".materialCategoryId"]}</div>
                                            </TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                                <Select
                                                    options={materialSubCategoryData}
                                                    name="materialSubCategory"
                                                    value={materialSubCategoryId}
                                                    onChange={materialSubCategoryChangeHandler}
                                                    placeholder="Select Material Sub Category"
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled={issueFor.value === 'Work Order'}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".materialSubCategoryId"]}</div>
                                            </TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                                <Select
                                                    options={materialData}
                                                    name="material"
                                                    value={materialId}
                                                    onChange={materialChangeHandler}
                                                    placeholder="Select Material"
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled={issueFor.value === 'Work Order'}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".materialId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                {issueFor.value === 'Office Purpose' ?
                                                    <div>Available Quantity:
                                                        {loading ? <Oval
                                                            heigth="100"
                                                            width="100"
                                                            color='grey'
                                                            ariaLabel='loading'
                                                        /> :
                                                            <div>
                                                                {availableQtyData.availableQuantity} </div>} </div>
                                                    : null}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Issued Quantity" fullWidth value={state.issuedquantity || ''}
                                                    name="issuedquantity"
                                                    onChange={changeHandler}
                                                    id="issuedquantity"
                                                    required
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".issuedquantity"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                    <Select
                                                        options={employeeData}
                                                        name="employee"
                                                        value={employeeId}
                                                        onChange={employeeChangeHandler}
                                                        placeholder="Select Employee"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".employee"]}</div>
                                                </MDBox>
                                            </TableCell>
                                            <TableCell>
                                                Issue Date
                                                <DatePicker
                                                    id="issuedDate"
                                                    selected={issuedDate}
                                                    onChange={date => setIssuedDate(date)}
                                                    dateFormat="MM/dd/yyyy"
                                                    tabIndex={0}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".issuedDate"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Remarks" fullWidth value={state.remarks || ''}
                                                    name="remarks"
                                                    onChange={changeHandler}
                                                    id="remarks"
                                                    required
                                                    multiline
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".remarks"]}</div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                {userDesignation === 'General Manager' || userDesignation === 'Asst Mgr - Store'
                                    || userDesignation === 'Jr Mgr - Store' || userDesignation === 'Manager - Store'
                                    || userDesignation === 'Sr Mgr - Store' ?
                                    <MDBox mt={4} mb={1} textAlign="center">
                                        {loading ? <Oval
                                            heigth="20"
                                            width="20"
                                            color='grey'
                                            ariaLabel='loading'
                                        /> :
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>
                                        }
                                        {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                    </MDBox>
                                    : null}
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddMaterialIssue;