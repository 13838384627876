
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
// import { Alert } from "bootstrap";
import * as service from '../../../../services/service';

function AddEditDistrict() {
    const [state, setState] = useState({ district: '' });
    const [stateId, setStateId] = useState('');
    const [stateData, setStateData] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const params = useParams();
    const getStates = () => {
        fetch(
            service.STATE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.stateId, label: item.state }));
                setStateData(data2);
            });
    };

    const getDetailsById = () => {
        setLoading(true);
        const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_DISTRICT_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        DistrictId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setState({ ...state, district: result.district });
                        const data = ({ value: result.stateId, label: result.state });
                        setStateId(data);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }

                });
        }
        setLoading(false);
    };

    useEffect(() => {
        getStates();
        if (params.id > 0) {
            getDetailsById();
        }
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!stateId) {
            isValid = false;
            errs[".state"] = "Please select state";
        }
        if (!state.district) {
            isValid = false;
            errs[".district"] = "Please enter district";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            if (params.id > 0) {
                fetch(
                    service.UPDATE_DISTRICT,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            DistrictId: params.id,
                            District: state.district,
                            StateId: stateId.value,
                            UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                        })
                    })
                    .then(res => res.json())
                    .then(result => {
                        if (result.messageType === 'Success') {
                            // eslint-disable-next-line
                            alert(result.message);
                            navigate('/masters/district');
                        }
                        else if (result.messageType === 'Failed') {
                            // eslint-disable-next-line
                            alert(result.message);
                            navigate('/masters/district');
                        }
                        else {
                            errs[".invalid"] = result.message.message;
                            setErrors(errs);
                        }
                    });
            }
            else {
                fetch(
                    service.ADD_DISTRICT,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            District: state.district,
                            StateId: stateId.value,
                            AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                        })
                    })
                    .then(res => res.json())
                    .then(result => {
                        if (result.messageType === 'Success') {
                            // eslint-disable-next-line
                            alert(result.message);
                            navigate('/masters/district');
                        }
                        else if (result.messageType === 'Failed') {
                            // eslint-disable-next-line
                            alert(result.message);
                            navigate('/masters/district');
                        }
                        else {
                            errs[".invalid"] = result.message.message;
                            setErrors(errs);
                        }
                    });
            }
        }
        setLoading(false);
    }

    const backHandler = () => {
        navigate('/masters/district');
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <MDBox pt={4} pb={3} px={3} style={{ width: '50%' }}>
                                    <MDBox component="form" role="form">
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                            <Dropdown menuClassName='myMenuClassName' id="state"
                                                name="state"
                                                options={stateData} placeholder="Select State"
                                                value={stateId}
                                                onChange={ev => setStateId(ev)}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".state"]}</div>
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput label="District" fullWidth value={state.district || ''}
                                                name="district"
                                                onChange={changeHandler}
                                                id="district"
                                                required />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".district"]}</div>
                                        </MDBox>
                                        <MDBox mt={4} mb={1} textAlign="center">
                                            {loading ? <Oval
                                                heigth="20"
                                                width="20"
                                                color='grey'
                                                ariaLabel='loading'
                                            /> :
                                                <div>
                                                    <MDButton color="info" onClick={submitHandler}>
                                                        SAVE
                                                    </MDButton>  &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <MDButton color="info" onClick={backHandler}>
                                                        BACK
                                                    </MDButton>
                                                </div>
                                            }
                                            {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddEditDistrict;