import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
// import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MDInput from "components/MDInput";
import * as moment from 'moment';
import Select from 'react-select';
import * as service from '../../../services/service';

function AddCustomerReceipt() {
    const [state, setState] = useState({ invoiceno: '', receivedamount: '', utrcode: '' });
    const [customerData, setCustomerData] = useState([]);
    const [workOrderData, setWorkOrderData] = useState([]);
    const [customerId, setCustomerId] = useState('');
    const [workOrderId, setWorkOrderId] = useState('');
    const [invoiceData, setInvoiceData] = useState([]);
    const [invoiceId, setInvoiceId] = useState('');
    const [invoiceCompelteData, setInvoiceCompleteData] = useState([]);
    const [receiptDate, setReceipDate] = useState(new Date());
    const [invoiceDate, setInvoiceDate] = useState('');
    const [invoiceAmount, setInvoiceAmount] = useState('');
    const [balance, setBalance] = useState('');
    const [bankData, setBankData] = useState([]);
    const [branchData, setBranchData] = useState([]);
    const [bankId, setBankId] = useState('');
    const [branchId, setBranchId] = useState('');
    const [paymentMode, setPaymentMode] = useState('');
    const paymentModeData = [{ label: 'Cash', value: 'Cash' }, { label: 'Cheque', value: 'Cheque' },
    { label: 'Bank Transfer', value: 'Bank Transfer' }]
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const navigate = useNavigate();
    const getCustomers = () => {
        fetch(
            service.CUSTOMER_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.customerId, label: item.customerName }));
                setCustomerData(data2);
            });
    };

    const getWorkOrders = (id) => {
        fetch(
            service.GET_PENDING_WORK_ORDER,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CustomerId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.workOrderId, label: item.jobNo }));
                setWorkOrderData(data2);
            });
    };

    const getInvoices = (id) => {
        if (id > 0) {
            fetch(
                service.GET_INVOICES_BY_WORK_ORDER_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        WorkOrderId: id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    setInvoiceCompleteData(result);
                    const data2 = result.map((item) => ({ value: item.invoiceId, label: item.invoiceNo }));
                    setInvoiceData(data2);
                })
                .catch(err => {
                    if (err) {
                        setErrors(err);
                    };
                });
        }
    };

    const getBalance = (id) => {
        fetch(
            service.GET_CUSTOMER_RECEIPT_BALANCE,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({                   
                    InvoiceId: id
                })
            })
            .then(res => res.json())
            .then(result => {
                 setBalance(result.balance);
            });
    };
    const getBanks = () => {
        fetch(
            service.BANK_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },                
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.bankId, label: item.bank }));
                setBankData(data2);
            });
    };

    const getBranch = (id) => {        
        fetch(
            service.GET_BRANCH_BY_BANK_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    BankId: id,
                })
            })
            .then(res => res.json())
            .then(result => {               
                const data2 = result.map((item) => ({ value: item.branchId, label: item.branch }));
                setBranchData(data2);
            });
    };

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!customerId) {
            isValid = false;
            errs[".customerId"] = "Please select customer";
        }
        if (!workOrderId) {
            isValid = false;
            errs[".workOrderId"] = "Please select job no";
        }
        if (!invoiceId) {
            isValid = false;
            errs[".invoiceId"] = "Please select invoice";
        }
        if (!receiptDate) {
            isValid = false;
            errs[".receiptDate"] = "Please select date";
        }
        if (!state.receivedamount) {
            isValid = false;
            errs[".receivedamount"] = "Please enter amount";
        }
        if (!paymentMode) {
            isValid = false;
            errs[".paymentMode"] = "Please select payment mode";
        }
        if (paymentMode && paymentMode.value !== "Cash" && !bankId) {
            isValid = false;
            errs[".bankId"] = "Please select bank";
        }
        if (paymentMode && paymentMode.value !== "Cash" && !branchId) {
            isValid = false;
            errs[".branchId"] = "Please select branch";
        }
        if (paymentMode && paymentMode.value !== "Cash" && !state.utrcode) {
            isValid = false;
            errs[".utrcode"] = "Please enter UTR Code";
        }

        setErrors(errs);
        return isValid;
    }
    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_CUSTOMER_RECEIPT,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        InvoiceId: invoiceId.value,
                        ReceiptDate: moment(receiptDate).format("MM/DD/YYYY"),
                        ReceiptAmount: state.receivedamount,
                        PaymentMode: paymentMode.value,
                        BankId: bankId ? bankId.value : 0,
                        BranchId: branchId ? branchId.value : 0,
                        UTRCode: state.utrcode,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/customer/customerreceipts');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }
    const backHandler = () => {
        navigate('/customer/customerreceipts');
    }
    
    useEffect(() => {
        setLoading(true);
        getCustomers();
        getBanks();
        setLoading(false);
    }, []);

    const customerChangeHandler = (ev) => {
        setCustomerId(ev);
        setWorkOrderId(null);
        getWorkOrders(ev.value);
        setInvoiceData([]);
    }
    const workOrderChangeHandler = (ev) => {
        setWorkOrderId(ev);
        getInvoices(ev.value);        
    }

    const invoiceChangeHandler = (ev) => {
        setInvoiceId(ev);
        const inv = invoiceCompelteData.find(element => element.invoiceId === ev.value)            
        setInvoiceDate(moment(new Date(inv.invoiceDate)).format('DD/MM/YYYY'));
        setInvoiceAmount(inv.totalAmount);
        getBalance(ev.value);
    }
    const paymentModeChangeHandler = (ev) => {
        setPaymentMode(ev);
    }
    const bankChangeHandler = (ev) => {
        setBankId(ev);
        setBranchId(null);
        getBranch(ev.value);
    }
    const branchChangeHandler = (ev) => {
        setBranchId(ev);
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Customer Receipt
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <TableContainer component={Paper} style={{ width: '95%', height:'500px', alignSelf: 'center', }}>
                                    <Table aria-label="simple table">
                                        <TableRow>
                                            <TableCell align='left' style={{ fontSize: '12px' }}>
                                                <Select
                                                    name="customer"
                                                    options={customerData} placeholder="Select Customer"
                                                    value={customerId}
                                                    onChange={customerChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".customerId"]}</div>
                                            </TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px' }}>
                                                <Select
                                                    name="workorder"
                                                    options={workOrderData} placeholder="Select Job Number"
                                                    value={workOrderId}
                                                    onChange={workOrderChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".workOrderId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                                <Select
                                                    name="invoiceId"
                                                    options={invoiceData} placeholder="Select Invoice"
                                                    value={invoiceId}
                                                    onChange={invoiceChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invoiceId"]}</div>
                                            </TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                                <MDInput label="Invoice Date" fullWidth value={invoiceDate}
                                                    name="invoiceDate"
                                                    id="invoiceDate"
                                                    disabled
                                                />
                                            </TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                                <MDInput label="Invoice Amount" fullWidth value={invoiceAmount}
                                                    name="invoiceamount"
                                                    id="invoiceamount"
                                                    disabled
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='left' style={{ fontSize: '12px' }}>
                                                Receipt Date
                                                <DatePicker
                                                    id="receiptDate"
                                                    selected={receiptDate}
                                                    onChange={date => setReceipDate(date)}
                                                    dateFormat="MM/dd/yyyy"
                                                    tabIndex={0}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".receiptDate"]}</div>
                                            </TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px' }}>
                                                <MDInput label="Received Amount" fullWidth value={state.receivedamount || ''}
                                                    name="receivedamount"
                                                    onChange={changeHandler}
                                                    id="receivedamount"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".receivedamount"]}</div>
                                            </TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px' }}>
                                                <Select
                                                    name="paymentMode"
                                                    options={paymentModeData} placeholder="Select Payment Mode"
                                                    value={paymentMode}
                                                    onChange={paymentModeChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".paymentMode"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='left' style={{ fontSize: '12px' }}>
                                                <Select
                                                    name="bankId"
                                                    options={bankData} placeholder="Select Bank"
                                                    value={bankId}
                                                    onChange={bankChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".bankId"]}</div>
                                            </TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px' }}>
                                                <Select
                                                    name="branchId"
                                                    options={branchData} placeholder="Select Branch"
                                                    value={branchId}
                                                    onChange={branchChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".branchId"]}</div>
                                            </TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px' }}>
                                                <MDInput label="UTR Code" fullWidth value={state.utrcode || ''}
                                                    name="utrcode"
                                                    onChange={changeHandler}
                                                    id="utrcode"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".utrcode"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                                <MDInput label="Balance" fullWidth value={balance}
                                                    name="balance"
                                                    id="balance"
                                                    disabled
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                </TableContainer>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>  &nbsp;&nbsp;&nbsp;&nbsp;
                                            <MDButton color="info" onClick={backHandler}>
                                                BACK
                                            </MDButton>
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default AddCustomerReceipt;