import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import * as moment from 'moment';
import Select from 'react-select';
import * as service from '../../services/service';

function CustomerReceipts() {
    const [data, setData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [workOrderData, setWorkOrderData] = useState([]);
    const [customerId, setCustomerId] = useState('');
    const [workOrderId, setWorkOrderId] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const userDesignation = JSON.parse(sessionStorage.getItem('userData')).designation;
    const navigate = useNavigate();

    const getCustomers = () => {
        fetch(
            service.CUSTOMER_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.customerId, label: item.customerName }));
                setCustomerData(data2);
            });
    };

    const getWorkOrders = (id) => {
        fetch(
            service.GET_PENDING_WORK_ORDER,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CustomerId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.workOrderId, label: item.jobNo }));
                setWorkOrderData(data2);
            });
    };

    const getDetails = (id) => {
        fetch(
            service.GET_CUSTOMER_RECEIPTS,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    WorkOrderId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                setData(result);
            })
            .catch(err => {
                if (err) {
                    setError(err);
                };
            });
    };

    const handleDelete = (id) => {
        setLoading(true);
        // eslint-disable-next-line
        if (window.confirm("Do you want to delete this details?")) {
            fetch(
                service.DELETE_CUSTOMER_RECEIPT_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ReceiptId: id
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                });
            window.location.reload(false);
        }
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        getCustomers();
        setLoading(false);
    }, []);

    const customerChangeHandler = (ev) => {
        setCustomerId(ev);
        setWorkOrderId(null);
        getWorkOrders(ev.value);
        setData([]);
    }
    const workOrderChangeHandler = (ev) => {
        setWorkOrderId(ev);
        getDetails(ev.value);
    }

    const addNew = () => {
        navigate('/addcustomerreceipt');
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {error
                ? <MDAlert color="warning" dismissible>{error}</MDAlert>
                : null
            }
            {userDesignation === 'General Manager' || userDesignation === 'Manager - Accounts'
                || userDesignation === 'Sr Mgr - Accounts' || userDesignation === 'MD' 
                || userDesignation === 'Asst Mgr - Accounts' || userDesignation === 'Jr Mgr - Accounts' ?
                <div style={{ float: 'right' }}>
                    <MDButton color="info" onClick={addNew}>
                        ADD NEW
                    </MDButton>
                </div>
                : null}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Customer Receipts
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <Table style={{ width: '50%' }}>
                                    <TableRow>
                                        <TableCell align='left' style={{ fontSize: '12px', width: '50%' }}>
                                            <Select
                                                name="customer"
                                                options={customerData} placeholder="Select Customer"
                                                value={customerId}
                                                onChange={customerChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px', width: '50%' }}>
                                            <Select
                                                name="workorder"
                                                options={workOrderData} placeholder="Select Job Number"
                                                value={workOrderId}
                                                onChange={workOrderChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                <br />
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Invoice No</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Invoice Amount</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Receipt Date</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Receipt Amount</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Payment Mode</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>UTRCode</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Bank</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Branch</TableCell>
                                                {userDesignation === 'General Manager' || userDesignation === 'Manager - Accounts'
                                                    || userDesignation === 'Sr Mgr - Accounts' || userDesignation === 'MD' 
                                                    || userDesignation === 'Asst Mgr - Accounts' || userDesignation === 'Jr Mgr - Accounts' ?
                                                    <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                                                    : null}
                                            </TableRow>
                                            <TableBody>
                                                {data.map((row) => (
                                                    <TableRow
                                                        key={row.receiptId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{row.invoiceNo}</TableCell>
                                                        <TableCell>{row.invoiceAmount}</TableCell>
                                                        <TableCell>{moment(row.receiptDate).format("DD/MM/YYYY")}</TableCell>
                                                        <TableCell>{row.receiptAmount}</TableCell>
                                                        <TableCell>{row.paymentMode}</TableCell>
                                                        <TableCell>{row.utrCode}</TableCell>
                                                        <TableCell>{row.bank}</TableCell>
                                                        <TableCell>{row.branch}</TableCell>
                                                        {userDesignation === 'General Manager' || userDesignation === 'Manager - Accounts'
                                                            || userDesignation === 'Sr Mgr - Accounts' || userDesignation === 'MD' 
                                                            || userDesignation === 'Asst Mgr - Accounts' || userDesignation === 'Jr Mgr - Accounts' ?
                                                            <TableCell><MDButton onClick={() => handleDelete(row.receiptId)}>Delete</MDButton></TableCell>
                                                            : null}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default CustomerReceipts;