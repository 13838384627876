
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Select from 'react-select';
import MDInput from "components/MDInput";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import * as service from '../../../services/service';

function AddEditWorkOrder() {
    const [state, setState] = useState({
        workorderno: '', jobno: '', quantity: '', rate: '', cgst: '', igst: '', sgst: '', amount: '', totalamount: '', status: '', shippingaddress: '',
        receivername: ''
    });
    const [productCatData, setProductCatData] = useState([]);
    const [productSubCatData, setProductSubCatData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [productCatId, setProductCatId] = useState('');
    const [productSubCatId, setProductSubCatId] = useState('');
    const [productId, setProductId] = useState('');
    const [subAssemblyId, setSubAssemblyId] = useState('');
    const [partId, setPartId] = useState('');
    const [subAssemblyData, setSubAssemblyData] = useState([]);
    const [partData, setPartData] = useState([]);
    const [customerId, setCustomerId] = useState('');
    const [customerData, setCustomerData] = useState([]);
    const [allCustomerData, setAllCustomerData] = useState([]);
    const [completionDate, setCompletionDate] = useState(new Date());
    const [stateId, setStateId] = useState('');
    const [districtId, setDistrictId] = useState('');
    const [stateData, setStateData] = useState([]);
    const [districtData, setDistrictData] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);    
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const navigate = useNavigate();
    const params = useParams();
    const getCustomers = () => {
        fetch(
            service.CUSTOMER_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                setAllCustomerData(result);
                const data2 = result.map((item) => ({ value: item.customerId, label: item.customerName }));
                setCustomerData(data2);
            });
    };
    const getProductCategories = () => {
        fetch(
            service.PRODUCT_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.productCategoryId, label: item.productCategory }));
                setProductCatData(data2);
            });
    };
    const getProductSubCategories = (id) => {
        fetch(
            service.PRODUCT_SUB_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductCategoryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.productSubCategoryId, label: item.productSubCategory }));
                setProductSubCatData(data2);
            });
    };

    const getProducts = (catId, subCatId) => {
        fetch(
            service.PRODUCT_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductCategoryId: catId,
                    ProductSubCategoryId: subCatId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.productId, label: item.product }));
                setProductData(data2);
            });
    };
    const getSubAssemblies = (prodId) => {
        fetch(
            service.SUBASSEMBLY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductId: prodId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.subAssemblyId, label: item.subAssembly }));
                setSubAssemblyData(data2);
            });
    };

    const getParts = (assemblyId) => {
        fetch(
            service.PART_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SubAssemblyId: assemblyId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.partId, label: item.part }));
                setPartData(data2);
            });
    };

    const getStates = () => {
        fetch(
            service.STATE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.stateId, label: item.state }));
                setStateData(data2);
            });
    };

    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    StateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));
                setDistrictData(data2);
            });
    };

    const getNextJobNo = () => {
        fetch(
            service.GET_NEXT_JOB_NO,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                setState({ ...state, jobno: result.jobNo });
            });
    };

    const getDetailsById = () => {
        setLoading(true);
        const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_WORK_ORDER_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        WorkOrderId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        const prod = ({ value: result.productCategoryId, label: result.productCategory });
                        setProductCatId(prod);
                        getProductSubCategories(result.productCategoryId)
                        const proc = ({ value: result.productSubCategoryId, label: result.productSubCategory });
                        setProductSubCatId(proc);
                        getProducts(result.productCategoryId, result.productSubCategoryId)
                        const pro = ({ value: result.productId, label: result.product });
                        setProductId(pro);
                        getSubAssemblies(result.productId)
                        if (result.subAssemblyId) {
                            const sub = ({ value: result.subAssemblyId, label: result.subAssembly });
                            setSubAssemblyId(sub);
                            getParts(result.subAssemblyId);
                        }

                        if (result.partId) {
                            const pt = ({ value: result.partId, label: result.part });
                            setPartId(pt);
                        }
                        const custData = ({ value: result.customerId, label: result.customerName });
                        setCustomerId(custData);

                        setState({
                            ...state, workorderno: result.workOrderNo, quantity: result.quantity,
                            rate: result.rate, cgst: result.cgst, sgst: result.sgst,
                            igst: result.igst, amount: result.amount, totalamount: result.totalAmount,
                            jobno: result.jobNo, shippingaddress: result.shippingAddress
                        });

                        setCompletionDate(new Date(result.completionDate));

                        localStorage.setItem('customerId', JSON.stringify(result.customerId));
                        localStorage.setItem('customerName', JSON.stringify(result.customerName));
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }

                });
        }
        setLoading(false);
    };

    const CalculateAmount = () => {
        if (state.quantity && state.rate && customerId) {
            const amt = (parseFloat(state.rate) * parseFloat(state.quantity)).toFixed(2);

            const filtered = allCustomerData.filter(obj =>
                obj.customerId === customerId.value
            );

            if (filtered[0].state === 'Maharashtra') {
                const sg = parseFloat(amt * 0.09).toFixed(2);
                const cg = parseFloat(amt * 0.09).toFixed(2);
                const ig = 0;
                const total = (parseFloat(amt) + parseFloat(sg) + parseFloat(cg)).toFixed(2);

                setState({ ...state, amount: amt, sgst: sg, cgst: cg, igst: ig, totalamount: total });
            }
            else {
                const sg = 0;
                const cg = 0;
                const ig = parseFloat(amt * 0.09).toFixed(2);
                const total = (parseFloat(amt) + parseFloat(ig)).toFixed(2);
                setState({ ...state, amount: amt, sgst: sg, cgst: cg, igst: ig, totalamount: total });
            }
        }
    }    
    useEffect(() => {
        getCustomers();
        getProductCategories();
        getNextJobNo();     
        getStates();
        if (params.id > 0) {
            getDetailsById();
        }
    }, []);

    useEffect(() => {
        if (state.rate && state.quantity && customerId) {
            CalculateAmount();
        }
    }, [state.rate, state.quantity, customerId]);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!customerId) {
            isValid = false;
            errs[".customer"] = "Please select customer";
        }
        if (!productCatId) {
            isValid = false;
            errs[".productcategory"] = "Please select product category";
        }
        if (!productSubCatId) {
            isValid = false;
            errs[".productsubcategory"] = "Please select product sub category";
        }
        if (!productId) {
            isValid = false;
            errs[".product"] = "Please select product";
        }
        if (!state.workorderno) {
            isValid = false;
            errs[".workorderno"] = "Please enter work order no.";
        }
        if (!state.rate) {
            isValid = false;
            errs[".rate"] = "Please enter rate";
        }
        if (!state.quantity) {
            isValid = false;
            errs[".quantity"] = "Please enter quantity";
        }
        if (!state.amount) {
            isValid = false;
            errs[".amount"] = "Please enter amount";
        }
        if (!state.totalamount) {
            isValid = false;
            errs[".totalamount"] = "Please enter totalamount";
        }

        setErrors(errs);
        return isValid;
    }

    const changeHandler = (event) => {
        if (event.target.name === 'quantity') {
            if (Number.isNaN(parseFloat(event.target.value))) {
                // eslint-disable-next-line
                alert("Please enter numbers only");
                event.target.value = ""; // eslint-disable-line no-param-reassign
            }
        }
        if (event.target.name === 'rate') {
            if (Number.isNaN(parseFloat(event.target.value))) {
                // eslint-disable-next-line
                alert("Please enter numbers only");
                event.target.value = ""; // eslint-disable-line no-param-reassign
            }
        }
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const stateChangeHandler = (ev) => {
        setStateId(ev);
        setDistrictId(null);
        getDistricts(ev.value);
    }
    const districtChangeHandler = (ev) => {
        setDistrictId(ev);
    }
    const customerChangeHandler = (ev) => {
        setCustomerId(ev);
    }

    const productCatChangeHandler = (ev) => {
        setProductCatId(ev);
        setProductSubCatId(null);
        setProductId(null);
        setSubAssemblyId(null);
        setPartId(null);
        getProductSubCategories(ev.value);
    }
    const productSubCatChangeHandler = (ev) => {
        setProductSubCatId(ev);
        setProductId(null);
        setSubAssemblyId(null);
        setPartId(null);
        getProducts(productCatId.value, ev.value);
    }
    const productChangeHandler = (ev) => {
        setProductId(ev);
        setSubAssemblyId(null);
        setPartId(null);
        getSubAssemblies(ev.value);
    }

    const subAssemblyChangeHandler = (ev) => {
        setSubAssemblyId(ev);
        setPartId(null);
        getParts(ev.value);
    }
    const partChangeHandler = (ev) => {
        setPartId(ev);
    }
    
    const submitHandler = e => {
        e.preventDefault();       
        setLoading(true);
        if (validate()) {          
            fetch(
                service.ADD_UPDATE_WORK_ORDER,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        WorkOrderId: params.id ? params.id : null,
                        WorkOrderNo: state.workorderno,
                        JobNo: state.jobno,
                        CustomerId: customerId.value,
                        ProductCategoryId: productCatId.value,
                        ProductSubCategoryId: productSubCatId.value,
                        ProductId: productId.value,
                        SubAssemblyId: subAssemblyId ? subAssemblyId.value : 0,
                        PartId: partId ? partId.value : 0,
                        Quantity: state.quantity,
                        Rate: state.rate,
                        Amount: state.amount,
                        CGST: state.cgst ? state.cgst : 0,
                        SGST: state.sgst ? state.sgst : 0,
                        IGST: state.igst ? state.igst : 0,
                        TotalAmount: state.totalamount,
                        ShippingAddress: state.shippingaddress,
                        StateId: stateId ? stateId.value : 0,
                        DistrictId: districtId ? districtId.value : 0,
                        ReceiverName: state.receivername,
                        CompletionDate: moment(completionDate).format("MM/DD/YYYY"),
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                            // eslint-disable-next-line
                            alert("Data Saved Successfully");
                            navigate('/customer/workorder');                       
                    }
                    else{
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                });            
        }
        setLoading(false);
    }
    const backHandler = () => {
        navigate('/customer/workorder');
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                {/* <TableContainer component={Paper} style={{ width: '90%', alignSelf: 'center' }}> */}
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell width="25%">
                                                <MDInput label="Work Order No." fullWidth value={state.workorderno || ''}
                                                    name="workorderno"
                                                    onChange={changeHandler}
                                                    id="workorderno"
                                                    required
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".workorderno"]}</div>
                                            </TableCell>
                                            <TableCell width="25%">
                                                <MDInput label="Job No." fullWidth value={state.jobno || ''}
                                                    name="jobno"
                                                    onChange={changeHandler}
                                                    id="jobno"
                                                    required
                                                    disabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".jobno"]}</div>
                                            </TableCell>
                                            <TableCell width="25%">
                                                <MDBox mb={2} style={{ fontSize: '12px' }}>
                                                    <Select
                                                        name="customer"
                                                        options={customerData} placeholder="Select Customer"
                                                        value={customerId}
                                                        onChange={customerChangeHandler}
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".customer"]}</div>
                                                </MDBox>
                                            </TableCell>
                                            <TableCell width="25%">
                                                <MDBox mb={2} style={{ fontSize: '12px' }}>
                                                    <Select
                                                        name="productCategory"
                                                        options={productCatData} placeholder="Select Product Category"
                                                        value={productCatId}
                                                        onChange={productCatChangeHandler}
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".productcategory"]}</div>
                                                </MDBox>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDBox mb={2} style={{ fontSize: '12px' }}>
                                                    <Select
                                                        name="productSubCategory"
                                                        options={productSubCatData} placeholder="Select Product Sub Category"
                                                        value={productSubCatId}
                                                        onChange={productSubCatChangeHandler}
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".productsubcategory"]}</div>
                                                </MDBox>
                                            </TableCell>
                                            <TableCell>
                                                <MDBox mb={2} style={{ fontSize: '12px' }}>
                                                    <Select
                                                        name="product"
                                                        options={productData} placeholder="Select Product"
                                                        value={productId}
                                                        onChange={productChangeHandler}
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".product"]}</div>
                                                </MDBox>
                                            </TableCell>
                                            <TableCell>
                                                <MDBox mb={2} style={{ fontSize: '12px' }}>
                                                    <Select
                                                        name="subassembly"
                                                        options={subAssemblyData} placeholder="Select Sub Assembly"
                                                        value={subAssemblyId}
                                                        onChange={subAssemblyChangeHandler}
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".subassembly"]}</div>
                                                </MDBox>
                                            </TableCell>
                                            <TableCell>
                                                <MDBox mb={2} style={{ fontSize: '12px' }}>
                                                    <Select
                                                        name="part"
                                                        options={partData} placeholder="Select Part"
                                                        value={partId}
                                                        onChange={partChangeHandler}
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".part"]}</div>
                                                </MDBox>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Quantity" fullWidth value={state.quantity || ''}
                                                    name="quantity"
                                                    onChange={changeHandler}
                                                    id="quantity"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".quantity"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Rate" fullWidth value={state.rate || ''}
                                                    name="rate"
                                                    onChange={changeHandler}
                                                    id="rate"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".rate"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Amount" fullWidth value={state.amount || ''}
                                                    name="amount"
                                                    onChange={changeHandler}
                                                    id="amount"
                                                    disabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".amount"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="CGST" fullWidth value={state.cgst || ''}
                                                    name="cgst"
                                                    onChange={changeHandler}
                                                    id="cgst"
                                                    disabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".cgst"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="SGST" fullWidth value={state.sgst || ''}
                                                    name="sgst"
                                                    onChange={changeHandler}
                                                    id="sgst"
                                                    disabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".sgst"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="IGST" fullWidth value={state.igst || ''}
                                                    name="igst"
                                                    onChange={changeHandler}
                                                    id="igst"
                                                    disabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".igst"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Total Amount" fullWidth value={state.totalamount || ''}
                                                    name="totalamount"
                                                    onChange={changeHandler}
                                                    id="totalamount"
                                                    disabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".totalamount"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                Completion Date
                                                <DatePicker
                                                    id="completionDate"
                                                    selected={completionDate}
                                                    onChange={date => setCompletionDate(date)}
                                                    dateFormat="MM/dd/yyyy"
                                                    tabIndex={0}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Receiver Name" fullWidth value={state.receivername || ''}
                                                    name="receivername"
                                                    onChange={changeHandler}
                                                    id="receivername"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".receivername"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Shipping Address" fullWidth value={state.shippingaddress || ''}
                                                    name="shippingaddress"
                                                    onChange={changeHandler}
                                                    id="shippingaddress"
                                                    multiline
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".shippingaddress"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDBox mb={2} style={{ fontSize: '12px' }}>
                                                    <Select
                                                        name="state"
                                                        options={stateData} placeholder="Select State"
                                                        value={stateId}
                                                        onChange={stateChangeHandler}
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".state"]}</div>
                                                </MDBox>
                                            </TableCell>
                                            <TableCell>
                                                <MDBox mb={2} style={{ fontSize: '12px' }}>
                                                    <Select
                                                        name="district"
                                                        options={districtData} placeholder="Select District"
                                                        value={districtId}
                                                        onChange={districtChangeHandler}
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".district"]}</div>
                                                </MDBox>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                {/* </TableContainer> */}                               
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>  &nbsp;&nbsp;&nbsp;&nbsp;
                                            <MDButton color="info" onClick={backHandler}>
                                                BACK
                                            </MDButton>
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>

                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddEditWorkOrder;