// import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
// import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import Select from 'react-select';
import { Checkbox } from '@mui/material';
import MDInput from "components/MDInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import * as service from '../../services/service';


function DeliveryChallan() {
    const [state, setState] = useState({ challanno: '' });
    const [challanDate, setChallanDate] = useState(new Date());
    const [data, setData] = useState([]);
    const [challanFor, setChallanFor] = useState('');
    const challanForData = [{ label: 'Product', value: 'Product' }, { label: 'Sub Assembly', value: 'Sub Assembly' }, { label: 'Part', value: 'Part' }]
    const [stockIds, setStockIds] = useState([]);
    const [isSelected, setIsSelected] = useState([]);
    const [workOrderId, setWorkOrderId] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const userDesignation = JSON.parse(sessionStorage.getItem('userData')).designation;
    const getDetails = (val) => {
        let url = '';
        if (val === 'Product') {
            url = service.GET_PRODUCTS_FOR_DELIVERY_CHALLAN
        }
        else if (val === 'Sub Assembly') {
            url = service.GET_SUBASSEMBLY_FOR_DELIVERY_CHALLAN
        }
        else if (val === 'Part') {
            url = service.GET_PARTS_FOR_DELIVERY_CHALLAN
        }

        fetch(
            url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                setData(result);
                result.forEach((element, index) => {
                    stockIds[index] = element.stockId;
                    setStockIds([...stockIds]);
                    isSelected[index] = false;
                    setIsSelected([...isSelected]);
                });
            })
            .catch(err => {
                if (err) {
                    setErrors(err);
                };
            });
    };

    const getNextChallanNo = () => {
        fetch(
            service.GET_NEXT_CHALLAN_NO,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                setState({ ...state, challanno: result.challanNo });
            });
    };

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!challanFor) {
            isValid = false;
            errs[".challanFor"] = "Please select challan for";
        }
        if (!workOrderId) {
            isValid = false;
            errs[".workOrderId"] = "Please select stock";
        }
        if (!challanDate) {
            isValid = false;
            errs[".taskId"] = "Please enter challan date";
        }
        setErrors(errs);
        return isValid;
    }

    const submitHandler = e => {
        if (validate()) {
            // eslint-disable-next-line
            console.log(stockIds); // do not remove
            e.preventDefault();
            const errs = {};
            setLoading(true);
            let flag = true;
            fetch(
                service.ADD_DELIVERY_CHALLAN,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ChallanNo: state.challanno,
                        ChallanDate: moment(challanDate).format("MM/DD/YYYY"),
                        WorkOrderId: workOrderId,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        // navigate('/supplier/suppliermaterialmap');
                    }
                    else if (result.messageType === 'Success') {
                        stockIds.forEach((element, index) => {
                            if (isSelected[index] === true) {
                                fetch(
                                    service.ADD_DELIVERY_CHALLAN_STOCK_DETAILS,
                                    {
                                        method: 'POST',
                                        headers: {
                                            'Accept': 'application/json',
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            DeliveryChallanId: result.id,
                                            StockId: element,
                                            AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId
                                        })
                                    })
                                    .then(resp => resp.json())
                                    .then(respo => {
                                        if (respo.messageType === 'Failed') {
                                            flag = false;
                                            // eslint-disable-next-line
                                            alert(respo.message);
                                            // navigate('/supplier/suppliermaterialmap');
                                        }
                                        else {
                                            flag = false;
                                            errs[".invalid"] = respo.message.message;
                                            setErrors(errs);
                                        }
                                    });
                            }
                        });
                        if (flag) {
                            // eslint-disable-next-line
                            alert("Data Saved Successfully");
                            // navigate('/masters/operationmachinemap');
                            window.location.reload(false);
                        }
                    }
                });
            setLoading(false);
        }
    }

    useEffect(() => {
        setLoading(true);
        getNextChallanNo();
        setLoading(false);
    }, []);

    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const challanForChangeHandler = (ev) => {
        setChallanFor(ev);
        setErrors('');
        setData([]);
        getDetails(ev.value);
    }

    const handleCheckboxChange = (checked, index, woId) => {
        if (workOrderId && workOrderId !== woId) {
            // eslint-disable-next-line
            alert("Please Select Stock from same Job No.")
        }
        else {
            let updatedObject = isSelected[index];
            updatedObject = checked;
            isSelected[index] = updatedObject;
            setIsSelected([...isSelected]);
            setWorkOrderId(woId);
        }
    }

    const clearHandler = () => {
        setWorkOrderId('');
        setChallanFor('');
        setData([]);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Delivery Challan
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>

                                <Table aria-label="simple table">
                                    <TableRow>
                                        <TableCell align="right" style={{ fontSize: '12px', width: '30%' }}>Delivery Challan For :</TableCell>
                                        <TableCell align="left" style={{ fontSize: '12px', width: '30%' }}>
                                            <Select
                                                options={challanForData}
                                                name="challanFor"
                                                value={challanFor}
                                                onChange={challanForChangeHandler}
                                                placeholder="Select Challan For"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".challanFor"]}</div>
                                        </TableCell>
                                        <TableCell align="right" style={{ fontSize: '12px', width: '30%' }} />
                                    </TableRow>
                                </Table>

                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '90%', alignSelf: 'center', }}>
                                        {challanFor.value === 'Product' ?
                                            <Table aria-label="simple table">
                                                <TableRow>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Customer</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Job No</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Product</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Stock No</TableCell>
                                                </TableRow>
                                                <TableBody>
                                                    {data.map((row, index) => (
                                                        <TableRow
                                                            key={row.stockId}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell>
                                                                <Checkbox checked={isSelected[index] || false}
                                                                    onChange={(e) => handleCheckboxChange(e.target.checked, index, row.workOrderId)} id="chkSelect" />
                                                            </TableCell>
                                                            <TableCell>{row.customerName}</TableCell>
                                                            <TableCell>{row.jobNo}</TableCell>
                                                            <TableCell>{row.product}</TableCell>
                                                            <TableCell>{row.stockNo}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".workOrderId"]}</div>
                                                </TableBody>
                                            </Table>
                                            : null}

                                        {challanFor.value === 'Sub Assembly' ?
                                            <Table aria-label="simple table">
                                                <TableRow>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Customer</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Job No</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Product</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Sub Assembly</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Stock No</TableCell>
                                                </TableRow>
                                                <TableBody>
                                                    {data.map((row, index) => (
                                                        <TableRow
                                                            key={row.stockId}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell>
                                                                <Checkbox checked={isSelected[index] || false}
                                                                    onChange={(e) => handleCheckboxChange(e.target.checked, index, row.workOrderId)} id="chkSelect" />
                                                            </TableCell>
                                                            <TableCell>{row.customerName}</TableCell>
                                                            <TableCell>{row.jobNo}</TableCell>
                                                            <TableCell>{row.product}</TableCell>
                                                            <TableCell>{row.subAssembly}</TableCell>
                                                            <TableCell>{row.stockNo}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".workOrderId"]}</div>
                                                </TableBody>
                                            </Table>
                                            : null}
                                        {challanFor.value === 'Part' ?
                                            <Table aria-label="simple table">
                                                <TableRow>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Customer</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Job No</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Product</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Sub Assembly</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Part</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>Stock No</TableCell>
                                                </TableRow>
                                                <TableBody>
                                                    {data.map((row, index) => (
                                                        <TableRow
                                                            key={row.stockId}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell>
                                                                <Checkbox checked={isSelected[index] || false}
                                                                    onChange={(e) => handleCheckboxChange(e.target.checked, index, row.workOrderId)} id="chkSelect" />
                                                            </TableCell>
                                                            <TableCell>{row.customerName}</TableCell>
                                                            <TableCell>{row.jobNo}</TableCell>
                                                            <TableCell>{row.product}</TableCell>
                                                            <TableCell>{row.subAssembly}</TableCell>
                                                            <TableCell>{row.part}</TableCell>
                                                            <TableCell>{row.stockNo}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".workOrderId"]}</div>
                                                </TableBody>
                                            </Table>
                                            : null}
                                    </TableContainer>
                                }
                                <br />
                                <TableContainer component={Paper} style={{ width: '90%', alignSelf: 'center', }}>
                                    <Table aria-label="simple table">
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Challan No." fullWidth value={state.challanno || ''}
                                                    name="challanno"
                                                    onChange={changeHandler}
                                                    id="challanno"
                                                    required
                                                    disabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".challanno"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                Challan Date
                                                <DatePicker
                                                    id="challanDate"
                                                    selected={challanDate}
                                                    onChange={date => setChallanDate(date)}
                                                    dateFormat="MM/dd/yyyy"
                                                    tabIndex={0}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".challanDate"]}</div>
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                </TableContainer>
                                {userDesignation === 'General Manager' || userDesignation === 'Asst Mgr - Store'
                                    || userDesignation === 'Jr Mgr - Store' || userDesignation === 'Manager - Store'
                                    || userDesignation === 'Sr Mgr - Store' || userDesignation === 'MD' ?
                                    <MDBox mt={4} mb={1} textAlign="center">
                                        {loading ? <Oval
                                            heigth="20"
                                            width="20"
                                            color='grey'
                                            ariaLabel='loading'
                                        /> :
                                            <div>
                                                <MDButton color="info" onClick={submitHandler}>
                                                    SAVE
                                                </MDButton>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <MDButton color="info" onClick={clearHandler}>
                                                    Clear
                                                </MDButton>
                                            </div>
                                        }
                                        {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                    </MDBox>
                                    : null}
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default DeliveryChallan;