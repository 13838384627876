import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import * as service from '../../../services/service';

function State() {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const userDesignation = JSON.parse(sessionStorage.getItem('userData')).designation;
    const navigate = useNavigate();

    const getDetails = () => {
        fetch(
            service.STATE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json())
            .then(result => {
                setData(result);
            })
            .catch(err => {
                if (err) {
                    setError(err);
                };
            });
    };

    const handleEdit = (id) => {
        navigate(`/addeditstate/${id}`);
    }

    useEffect(() => {
        setLoading(true);
        getDetails();
        setLoading(false);
    }, []);

    const addNew = () => {
        navigate('/addeditstate/0');
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {error
                ? <MDAlert color="warning" dismissible>{error}</MDAlert>
                : null
            }
            {userDesignation === 'General Manager' || userDesignation === 'Manager - Accounts'
                || userDesignation === 'Sr Mgr - Accounts' || userDesignation === 'Asst Mgr - Accounts'
                || userDesignation === 'Jr Mgr - Accounts' || userDesignation === 'MD' ?
                <div style={{ float: 'right' }}>
                    <MDButton color="info" onClick={addNew}>
                        ADD NEW
                    </MDButton>
                </div>
                : null}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    State Details
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '50%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>State</TableCell>
                                                {userDesignation === 'General Manager' || userDesignation === 'Manager - Accounts'
                                                    || userDesignation === 'Sr Mgr - Accounts' || userDesignation === 'Asst Mgr - Accounts'
                                                    || userDesignation === 'Jr Mgr - Accounts' || userDesignation === 'MD' ?
                                                    <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                                                    : null}
                                            </TableRow>
                                            <TableBody>
                                                {data.map((row) => (
                                                    <TableRow
                                                        key={row.stateId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">{row.state}</TableCell>
                                                        {userDesignation === 'General Manager' || userDesignation === 'Manager - Accounts'
                                                            || userDesignation === 'Sr Mgr - Accounts' || userDesignation === 'Asst Mgr - Accounts'
                                                            || userDesignation === 'Jr Mgr - Accounts' || userDesignation === 'MD' ?
                                                            <TableCell><MDButton onClick={() => handleEdit(row.stateId)}>Edit</MDButton></TableCell>
                                                            : null}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default State;