import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import Select from 'react-select';
import * as service from '../../../services/service';

function OperationCriticalParameter() {
    const [data, setData] = useState([]);
    const [productCatData, setProductCatData] = useState([]);
    const [productSubCatData, setProductSubCatData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [partData, setPartData] = useState([]);
    const [subAssemblyData, setSubAssemblyData] = useState([]);
    const [operationData, setOperationData] = useState([]);
    const [productCatId, setProductCatId] = useState('');
    const [productSubCatId, setProductSubCatId] = useState('');
    const [productId, setProductId] = useState('');
    const [subAssemblyId, setSubAssemblyId] = useState('');
    const [partId, setPartId] = useState('');
    const [operationId, setOperationId] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const userDesignation = JSON.parse(sessionStorage.getItem('userData')).designation;
    const navigate = useNavigate();
    const getProductCategories = () => {
        fetch(
            service.PRODUCT_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.productCategoryId, label: item.productCategory }));
                setProductCatData(data2);
            });
    };
    const getProductSubCategories = (id) => {
        fetch(
            service.PRODUCT_SUB_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductCategoryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.productSubCategoryId, label: item.productSubCategory }));
                setProductSubCatData(data2);
            });
    };
    const getProducts = (catId, subCatId) => {
        fetch(
            service.PRODUCT_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductCategoryId: catId,
                    ProductSubCategoryId: subCatId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.productId, label: item.product }));
                setProductData(data2);
            });
    };

    const getSubAssemblies = (prodId) => {
        fetch(
            service.SUBASSEMBLY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductId: prodId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.subAssemblyId, label: item.subAssembly }));
                setSubAssemblyData(data2);
            });
    };

    const getParts = (assemblyId) => {
        fetch(
            service.PART_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SubAssemblyId: assemblyId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.partId, label: item.part }));
                const all = ({ value: "0", label: "Select Part" });
                data2.unshift(all);
                setPartData(data2);
                setPartId(all);
            });
    };
    const getOperations = (prId, assemblyId, ptId) => {
        fetch(
            service.OPERATION_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductId: prId,
                    SubAssemblyId: assemblyId,
                    PartId: ptId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.operationId, label: item.operation }));
                setOperationData(data2);
            });
    };
    const getDetails = (opId) => {
        if (opId > 0) {
            fetch(
                service.OPERATION_CRITICAL_PARAMETER_URL,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        OperationId: opId
                    })
                })
                .then(res => res.json())
                .then(result => {
                    setData(result);
                })
                .catch(err => {
                    if (err) {
                        setError(err);
                    };
                });
        }
    };

    const productCategoryChangeHandler = (ev) => {
        setProductCatId(ev);
        setProductSubCatId(null);
        setProductId(null);
        setSubAssemblyId(null);
        setOperationId(null);
        setPartId(null);
        setData([]);
        getProductSubCategories(ev.value);
    }
    const productSubCategoryChangeHandler = (ev) => {
        setProductSubCatId(ev);
        setProductId(null);
        setSubAssemblyId(null);
        setPartId(null);
        setOperationId(null);
        setData([]);
        getProducts(productCatId.value, ev.value);
    }
    const productChangeHandler = (ev) => {
        setProductId(ev);
        setPartId(null);
        setOperationId(null);
        getOperations(ev.value, null, null);
        setData([]);
        getSubAssemblies(ev.value);
    }
    const subAssemblyChangeHandler = (ev) => {
        setData([]);
        setSubAssemblyId(ev);
        getParts(ev.value);
        getOperations(productId.value, ev.value, null);
    }

    const partChangeHandler = (ev) => {
        setData([]);
        setOperationId(null);
        setPartId(ev);
        getOperations(productId.value, subAssemblyId.value, ev.value);
    }
    const operationChangeHandler = (ev) => {
        setOperationId(ev);
        getDetails(ev.value);
    }

    useEffect(() => {
        setLoading(true);
        getProductCategories();
        if (JSON.parse(localStorage.getItem('productCategoryId')) != null) {
            const item = ({ value: JSON.parse(localStorage.getItem('productCategoryId')), label: JSON.parse(localStorage.getItem('productCategory')) });
            setProductCatId(item);
            const subCat = ({ value: JSON.parse(localStorage.getItem('productSubCategoryId')), label: JSON.parse(localStorage.getItem('productSubCategory')) });
            setProductSubCatId(subCat);
            const prod = ({ value: JSON.parse(localStorage.getItem('productId')), label: JSON.parse(localStorage.getItem('product')) });
            setProductId(prod);
            getSubAssemblies(JSON.parse(localStorage.getItem('productId')));
            if (JSON.parse(localStorage.getItem('subAssemblyId')) !== 0) {
                const assem = ({ value: JSON.parse(localStorage.getItem('subAssemblyId')), label: JSON.parse(localStorage.getItem('subAssembly')) });
                setSubAssemblyId(assem);
            }
            if (JSON.parse(localStorage.getItem('partId')) !== 0) {
                const prt = ({ value: JSON.parse(localStorage.getItem('partId')), label: JSON.parse(localStorage.getItem('part')) });
                setPartId(prt);
            }
            const opr = ({ value: JSON.parse(localStorage.getItem('operationId')), label: JSON.parse(localStorage.getItem('operation')) });
            setOperationId(opr);
            getDetails(JSON.parse(localStorage.getItem('operationId')));
        };
        localStorage.setItem('productCategoryId', null);
        localStorage.setItem('productCategory', null);
        localStorage.setItem('productSubCategoryId', null);
        localStorage.setItem('productSubCategory', null);
        localStorage.setItem('productId', null);
        localStorage.setItem('product', null);
        localStorage.setItem('subAssemblyId', null);
        localStorage.setItem('subAssembly', null);
        localStorage.setItem('partId', null);
        localStorage.setItem('part', null);
        localStorage.setItem('operationId', null);
        localStorage.setItem('operation', null);
        setLoading(false);
    }, []);

    const handleEdit = (id) => {
        navigate(`/addeditoperationcriticalparameter/${id}`);
    }
    const addNew = () => {
        navigate('/addeditoperationcriticalparameter/0');
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {error
                ? <MDAlert color="warning" dismissible>{error}</MDAlert>
                : null
            }
            {userDesignation === 'General Manager' || userDesignation === 'Manager - Production'
                || userDesignation === 'Sr Mgr - Production' || userDesignation === 'MD' 
                || userDesignation === 'Asst Mgr - Production' || userDesignation === 'Jr Mgr - Production' ?
                <div style={{ float: 'right' }}>
                    <MDButton color="info" onClick={addNew}>
                        ADD NEW
                    </MDButton>
                </div>
                : null}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Operation Critical Parameters
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <Table aria-label="simple table">
                                    <TableRow>
                                        <TableCell align='right' style={{ fontSize: '12px', width: '10%' }}>Product Category :</TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px', width: '20%' }}>
                                            <Select
                                                name="productCategory"
                                                options={productCatData} placeholder="Select Product Category"
                                                value={productCatId}
                                                onChange={productCategoryChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                        <TableCell align='right' style={{ fontSize: '12px', width: '15%' }}>Product Sub Category :</TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px', width: '20%' }}>
                                            <Select
                                                name="productSubCategory"
                                                options={productSubCatData} placeholder="Select Product Sub Category"
                                                value={productSubCatId}
                                                onChange={productSubCategoryChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                        <TableCell align='right' style={{ fontSize: '12px', width: '10%' }}>Product :</TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px', width: '20%' }}>
                                            <Select
                                                name="product"
                                                options={productData} placeholder="Select Product"
                                                value={productId}
                                                onChange={productChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align='right' style={{ fontSize: '12px' }}>Sub Assembly :</TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px' }}>
                                            <Select
                                                name="subassembly"
                                                options={subAssemblyData} placeholder="Select Sub Assembly"
                                                value={subAssemblyId}
                                                onChange={subAssemblyChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                        <TableCell align='right' style={{ fontSize: '12px' }}>Part :</TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px' }}>
                                            <Select
                                                name="part"
                                                options={partData} placeholder="Select Part"
                                                value={partId}
                                                onChange={partChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                        <TableCell align='right' style={{ fontSize: '12px' }}>Operation :</TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px' }}>
                                            <Select
                                                name="operation"
                                                options={operationData} placeholder="Select Operation"
                                                value={operationId}
                                                onChange={operationChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                <br />
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '80%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Critical Parameter</TableCell>
                                                {userDesignation === 'General Manager' || userDesignation === 'Manager - Production'
                                                    || userDesignation === 'Sr Mgr - Production' || userDesignation === 'MD' 
                                                    || userDesignation === 'Asst Mgr - Production' || userDesignation === 'Jr Mgr - Production' ?
                                                    <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                                                    : null}
                                            </TableRow>
                                            <TableBody>
                                                {data.map((row) => (
                                                    <TableRow
                                                        key={row.operationParameterId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{row.criticalParameter}</TableCell>
                                                        {userDesignation === 'General Manager' || userDesignation === 'Manager - Production'
                                                            || userDesignation === 'Sr Mgr - Production' || userDesignation === 'MD' 
                                                            || userDesignation === 'Asst Mgr - Production' || userDesignation === 'Jr Mgr - Production' ?
                                                            <TableCell><MDButton onClick={() => handleEdit(row.operationParameterId)}>Edit</MDButton></TableCell>
                                                            : null}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default OperationCriticalParameter;