import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import 'react-dropdown/style.css';
// import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from 'react-select';
import * as moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MDButton from 'components/MDButton';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
// import { jsPDF } from 'jspdf';
// import html2canvas from "html2canvas";
import * as service from '../../services/service';

function PurchaseOrderForWorkReport() {
    const [reportDetailsData, setReportDetailsData] = useState([]);
    const [showDetailsReport, setShowDetailsReport] = useState(false);
    const [supplierData, setSupplierData] = useState([]);
    const [supplierId, setSupplierId] = useState('');
    const [purchaseOrderData, setPurchaseOrderData] = useState([]);
    const [purchaseOrderId, setPurchaseOrderId] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const userDesignation = JSON.parse(sessionStorage.getItem('userData')).designation;
    const getSuppliers = () => {
        fetch(
            service.SUPPLIER_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.supplierId, label: item.supplierName }));
                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setSupplierData(data2);
                setSupplierId(all);
            });
    };
    const getPurchaseOrders = (id) => {
        fetch(
            service.GET_PURCHASE_ORDER_FOR_WORK_BY_SUPPLIER,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SupplierId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.purchaseOrderId, label: item.purchaseOrderNo }));
                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setPurchaseOrderData(data2);
                setPurchaseOrderId(all);
            });
    };
    function GetReport() {
        const errs = {};
        fetch(
            service.GET_PURCHASE_ORDER_FOR_WORK_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SupplierId: supplierId ? supplierId.value : 0,
                    PurchaseOrderId: purchaseOrderId ? purchaseOrderId.value : 0,
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowDetailsReport(true);
                    setReportDetailsData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
            });
    }

    useEffect(() => {
        setLoading(true);
        getSuppliers();
        setLoading(false);
    }, []);

    const changeHandler = (ev) => {
        setSupplierId(ev);
        getPurchaseOrders(ev.value);
        setReportDetailsData([]);
    }
    const purchaseOrderchangeHandler = (ev) => {
        setPurchaseOrderId(ev);
        setReportDetailsData([]);
    }

    const submitHandler = e => {
        setReportDetailsData([]);
        setShowDetailsReport(false);
        e.preventDefault();
        setLoading(true);
        GetReport();
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        {userDesignation === 'General Manager' || userDesignation === 'Asst Mgr - Purchase'
                            || userDesignation === 'Jr Mgr - Purchase' || userDesignation === 'Manager - Purchase'
                            || userDesignation === 'Sr Mgr - Purchase' || userDesignation === 'MD' ?
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="white">
                                        Purchase Order For Work Report
                                    </MDTypography>
                                </MDBox>
                                <br />
                                <Table aria-label="simple table">
                                    <TableRow>
                                        <TableCell width="25%" align="right" style={{ fontSize: '12px' }}>
                                            Supplier :
                                        </TableCell>
                                        <TableCell width="25%" align="left" style={{ fontSize: '12px' }}>
                                            <Select
                                                options={supplierData}
                                                name="supplierId"
                                                value={supplierId}
                                                onChange={changeHandler}
                                                placeholder="Select Supplier"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                        <TableCell width="25%" align="right" style={{ fontSize: '12px' }}>
                                            Purchase Order :
                                        </TableCell>
                                        <TableCell align="left" style={{ fontSize: '12px' }}>
                                            <Select
                                                options={purchaseOrderData}
                                                name="purchaseOrderId"
                                                value={purchaseOrderId}
                                                onChange={purchaseOrderchangeHandler}
                                                placeholder="Select Purchase Order"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="right" style={{ fontSize: '12px' }}>
                                            From Date :
                                        </TableCell>
                                        <TableCell style={{ fontSize: '12px' }}>
                                            <DatePicker
                                                id="fromDate"
                                                selected={fromDate}
                                                onChange={date => setFromDate(date)}
                                                dateFormat="MM/dd/yyyy"
                                            />
                                        </TableCell>
                                        <TableCell align="right" style={{ fontSize: '12px' }}>
                                            To Date :
                                        </TableCell>
                                        <TableCell style={{ fontSize: '12px' }}>
                                            <DatePicker
                                                id="toDate"
                                                selected={toDate}
                                                onChange={date => setToDate(date)}
                                                dateFormat="MM/dd/yyyy"
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan="6" align="center">
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                {loading ?
                                                    <Oval
                                                        heigth="20"
                                                        width="20"
                                                        color='grey'
                                                        ariaLabel='loading'
                                                    />
                                                    :
                                                    <MDButton color="info" onClick={submitHandler}>
                                                        Show
                                                    </MDButton>
                                                }
                                                {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                            </MDBox>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                <Card style={{ alignItems: 'center' }}>
                                    {loading ?
                                        <Oval
                                            heigth="100"
                                            width="100"
                                            color='grey'
                                            ariaLabel='loading'
                                        /> :
                                        <TableContainer component={Paper} style={{ width: '98%', alignSelf: 'center' }}>
                                            <Table id="dvDetailReport">
                                                <TableRow>
                                                    <TableCell colSpan="19" align="center">
                                                        <Table>
                                                            <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                Supplier : {supplierId ? supplierId.label : 'All'}
                                                            </TableCell>
                                                            <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                Purchase Order No : {purchaseOrderId ? purchaseOrderId.label : 'All'}
                                                            </TableCell>
                                                            <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                From Date : {fromDate ? moment(fromDate).format("DD/MM/YYYY") : ''}
                                                            </TableCell>
                                                            <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                                To Date : {toDate ? moment(toDate).format("DD/MM/YYYY") : ''}
                                                            </TableCell>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Supplier</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Purchase Order No.</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Purchase Order Date</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Product</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>SubAssembly</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Part</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Operation</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Rate</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Amount</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>CGST</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>SGST</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>IGST</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Total Amount</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Customer</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Job No.</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Challan No.</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Terms And Conditions</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Status</TableCell>
                                                </TableRow>
                                                {reportDetailsData.map((row) => (
                                                    <TableRow
                                                        key={row.purchaseOrderId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell style={{ fontSize: '14px' }}>{row.supplierName}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.purchaseOrderNo}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{moment(row.purchaseOrderDate).format("DD/MM/YYYY")}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.product}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.subAssembly}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.part}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.operation}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.rate}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.amount}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.cgst}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.sgst}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.igst}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.totalAmount}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.customerName}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.jobNo}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.challanNo}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.termsConditions}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.status}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </Table>
                                            <br />
                                            <Table>
                                                <TableRow>
                                                    <TableCell align="center">
                                                        {showDetailsReport ?
                                                            <ReactHTMLTableToExcel table="dvDetailReport" className="info"
                                                                filename="ReportData" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                                                            />
                                                            : null}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>{errors[".invalid"]}</TableCell>
                                                </TableRow>
                                            </Table>
                                        </TableContainer>
                                    }
                                </Card>
                            </Card>
                            :
                            <Card style={{ alignItems: 'center' }}>
                                <div style={{ fontWeight: 'bold', fontSize: '14px', color: 'red' }}>You do not have access to this page</div>
                            </Card>
                        }
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default PurchaseOrderForWorkReport;
