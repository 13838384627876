// import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
// import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import Select from 'react-select';
import { Checkbox } from '@mui/material';
import MDInput from "components/MDInput";
import * as service from '../../../services/service';

function SupplierOperationMap() {
    const [data, setData] = useState([]);
    const [supplierData, setSupplierData] = useState([]);
    const [supplierId, setSupplierId] = useState('');
    const [productCatData, setProductCatData] = useState([]);
    const [productSubCatData, setProductSubCatData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [subAssemblyData, setSubAssemblyData] = useState([]);
    const [partData, setPartData] = useState([]);
    const [productCatId, setProductCatId] = useState('');
    const [productSubCatId, setProductSubCatId] = useState('');
    const [productId, setProductId] = useState('');
    const [subAssemblyId, setSubAssemblyId] = useState('');
    const [partId, setPartId] = useState('');
    const [operationIds, setOperationIds] = useState([]);
    const [rate, setRate] = useState([]);
    const [isSelected, setIsSelected] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const userDesignation = JSON.parse(sessionStorage.getItem('userData')).designation;
    // const navigate = useNavigate();

    const getSuppliers = () => {
        fetch(
            service.SUPPLIER_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.supplierId, label: item.supplierName }));
                setSupplierData(data2);
            });
    };

    const getProductCategories = () => {
        fetch(
            service.PRODUCT_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.productCategoryId, label: item.productCategory }));
                setProductCatData(data2);
            });
    };
    const getProductSubCategories = (id) => {
        fetch(
            service.PRODUCT_SUB_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductCategoryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.productSubCategoryId, label: item.productSubCategory }));
                setProductSubCatData(data2);
            });
    };

    const getProducts = (catId, subCatId) => {
        fetch(
            service.PRODUCT_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductCategoryId: catId,
                    ProductSubCategoryId: subCatId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.productId, label: item.product }));
                setProductData(data2);
            });
    };
    const getSubAssemblies = (prodId) => {
        fetch(
            service.SUBASSEMBLY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductId: prodId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.subAssemblyId, label: item.subAssembly }));
                setSubAssemblyData(data2);
            });
    };

    const getParts = (assemblyId) => {
        fetch(
            service.PART_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SubAssemblyId: assemblyId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.partId, label: item.part }));
                setPartData(data2);
            });
    };

    const getDetails = (prId, assemblyId, ptId) => {
        fetch(
            service.GET_SUPPLIER_OPERATION_MAP,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SupplierId: supplierId.value,
                    ProductId: prId,
                    SubAssemblyId: assemblyId,
                    PartId: ptId
                })
            })
            .then(res => res.json())
            .then(result => {
                setData(result);
                result.forEach((element, index) => {
                    operationIds[index] = element.operationId;
                    setOperationIds([...operationIds]);
                    isSelected[index] = element.isSelected;
                    setIsSelected([...isSelected]);
                    rate[index] = element.rate;
                    setRate([...rate]);
                });
            })
            .catch(err => {
                if (err) {
                    setErrors(err);
                };
            });
    };

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!supplierId) {
            isValid = false;
            errs[".supplierId"] = "Please select supplier";
        }
        if (!productCatId) {
            isValid = false;
            errs[".productCatId"] = "Please select product category";
        }
        if (!productSubCatId) {
            isValid = false;
            errs[".productSubCatId"] = "Please select product sub category";
        }
        if (!productId) {
            isValid = false;
            errs[".productId"] = "Please select product";
        }
        if (!isSelected.includes(true)) {
            isValid = false;
            errs[".operationId"] = "Please select operation";
        }
        setErrors(errs);
        return isValid;
    }

    const submitHandler = e => {
        if (validate()) {
            // eslint-disable-next-line
            console.log(operationIds); // do not remove
            e.preventDefault();
            const errs = {};
            setLoading(true);
            let flag = true;
            fetch(
                service.DELETE_SUPPLIER_OPERATION_MAP,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        SupplierId: supplierId.value,
                        ProductId: productId.value,
                        SubAssemblyId: subAssemblyId ? subAssemblyId.value : 0,
                        PartId: partId ? partId.value : 0
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else if (result.messageType === 'Success') {
                        operationIds.forEach((element, index) => {
                            if (isSelected[index] === true) {
                                fetch(
                                    service.ADD_SUPPLIER_OPERATION_MAP,
                                    {
                                        method: 'POST',
                                        headers: {
                                            'Accept': 'application/json',
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            SupplierId: supplierId.value,
                                            OperationId: element,
                                            Rate: rate[index],
                                            AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId
                                        })
                                    })
                                    .then(resp => resp.json())
                                    .then(respo => {
                                        if (respo.messageType === 'Failed') {
                                            flag = false;
                                            // eslint-disable-next-line
                                            alert(respo.message);
                                        }
                                        else {
                                            flag = false;
                                            errs[".invalid"] = respo.message.message;
                                            setErrors(errs);
                                        }
                                    });
                            }
                        });
                        if (flag) {
                            // eslint-disable-next-line
                            alert("Data Saved Successfully");
                            window.location.reload(false);
                        }
                    }
                });
            setLoading(false);
        }
    }

    const supplierChangeHandler = (ev) => {
        setSupplierId(ev);
        setProductSubCatId(null);
        setProductId(null);
        setSubAssemblyId(null);
        setPartId(null);
        setData([]);
        setOperationIds([]);
        setIsSelected([]);
    }
    const productCategoryChangeHandler = (ev) => {
        setProductCatId(ev);
        setProductSubCatId(null);
        setProductId(null);
        setSubAssemblyId(null);
        setPartId(null);
        setData([]);
        setOperationIds([]);
        setIsSelected([]);
        getProductSubCategories(ev.value);
    }
    const productSubCategoryChangeHandler = (ev) => {
        setProductSubCatId(ev);
        setProductId(null);
        setSubAssemblyId(null);
        setPartId(null);
        setData([]);
        setOperationIds([]);
        setIsSelected([]);
        getProducts(productCatId.value, ev.value);
    }
    const productChangeHandler = (ev) => {
        setProductId(ev);
        setData([]);
        setOperationIds([]);
        setIsSelected([]);
        getSubAssemblies(ev.value);
        getDetails(ev.value, null, null);
    }
    const subAssemblyChangeHandler = (ev) => {
        setSubAssemblyId(ev);
        getDetails(productId.value, ev.value, null);
        setData([]);
        setOperationIds([]);
        setIsSelected([]);
        setPartId(null);
        getParts(ev.value);
    }
    const partChangeHandler = (ev) => {
        setPartId(ev);
        setData([]);
        getDetails(productId.value, subAssemblyId.value, ev.value);
    }
    useEffect(() => {
        setLoading(true);
        getSuppliers();
        getProductCategories();
        setLoading(false);
    }, []);

    const rateChangeHandler = (event, index) => {
        if (Number.isNaN(parseFloat(event.target.value))) {
            // eslint-disable-next-line
            alert("Please enter numbers only");
            event.target.value = ""; // eslint-disable-line no-param-reassign
        }
        else {
            let updatedObject = rate[index];
            updatedObject = event.target.value;
            rate[index] = updatedObject;
        }

        setRate([...rate]);
    };
    const handleCheckboxChange = (checked, index) => {
        let updatedObject = isSelected[index];
        updatedObject = checked;
        isSelected[index] = updatedObject;
        setIsSelected([...isSelected]);
    }
    const clearHandler = () => {
        setProductCatId(null);
        setProductSubCatId(null);
        setProductId(null);
        setSubAssemblyId(null);
        setPartId(null);
        setSupplierId(null);
        setData([]);
        setOperationIds([]);
        setIsSelected([]);
        setErrors('');
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Supplier Operation Mapping
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <Table aria-label="simple table">
                                    <TableRow>
                                        <TableCell align='right' style={{ fontSize: '12px', width: '10%' }}>Supplier :</TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px', width: '20%' }}>
                                            <Select
                                                options={supplierData}
                                                name="supplier"
                                                value={supplierId}
                                                onChange={supplierChangeHandler}
                                                placeholder="Select Supplier"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".supplierId"]}</div>
                                        </TableCell>
                                        <TableCell align='right' style={{ fontSize: '12px', width: '15%' }}>Product Category :</TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px', width: '20%' }}>
                                            <Select
                                                options={productCatData}
                                                name="productCategory"
                                                value={productCatId}
                                                onChange={productCategoryChangeHandler}
                                                placeholder="Select Product Category"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".productCatId"]}</div>
                                        </TableCell>
                                        <TableCell align='right' style={{ fontSize: '12px', width: '15%' }}>Product Sub Category :</TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px', width: '20%' }}>
                                            <Select
                                                options={productSubCatData}
                                                name="productSubCategory"
                                                value={productSubCatId}
                                                onChange={productSubCategoryChangeHandler}
                                                placeholder="Select Product Sub Category"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".productSubCatId"]}</div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align='right' style={{ fontSize: '12px' }}>Product :</TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px' }}>
                                            <Select
                                                options={productData}
                                                name="product"
                                                value={productId}
                                                onChange={productChangeHandler}
                                                placeholder="Select Product"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".productId"]}</div>
                                        </TableCell>
                                        <TableCell align='right' style={{ fontSize: '12px' }}>Sub Assembly :</TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px' }}>
                                            <Select
                                                options={subAssemblyData}
                                                name="subAssembly"
                                                value={subAssemblyId}
                                                onChange={subAssemblyChangeHandler}
                                                placeholder="Select Sub Assembly"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                        <TableCell align='right' style={{ fontSize: '12px' }}>Part :</TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px' }}>
                                            <Select
                                                options={partData}
                                                name="part"
                                                value={partId}
                                                onChange={partChangeHandler}
                                                placeholder="Select Part"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                <br />
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '90%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Operation</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Rate</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {data.map((row, index) => (
                                                    <TableRow
                                                        key={row.materialId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>
                                                            <Checkbox checked={isSelected[index] || false}
                                                                onChange={(e) => handleCheckboxChange(e.target.checked, index)} id="chkSelect" />
                                                        </TableCell>
                                                        <TableCell>{row.operation}</TableCell>
                                                        <TableCell>
                                                            <MDInput label="Rate" fullWidth value={rate[index] || ''}
                                                                name="rate"
                                                                onChange={ev => rateChangeHandler(ev, index)}
                                                                id="rate"
                                                            />
                                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".rate"]}</div>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".operationId"]}</div>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            {userDesignation === 'General Manager' || userDesignation === 'Manager - Production'
                                                || userDesignation === 'Sr Mgr - Production' || userDesignation === 'MD' 
                                                || userDesignation === 'Jr Mgr - Purchase' || userDesignation === 'Sr Mgr - Purchase' 
                                                || userDesignation === 'Jr Mgr - Production' || userDesignation === 'Asst Mgr - Production' ?
                                                <MDButton color="info" onClick={submitHandler}>
                                                    SAVE
                                                </MDButton>
                                                : null}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <MDButton color="info" onClick={clearHandler}>
                                                Clear
                                            </MDButton>
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default SupplierOperationMap;