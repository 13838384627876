
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import 'react-dropdown/style.css';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import Select from 'react-select';
import * as service from '../../../../services/service';

function AddEditTask() {
    const [state, setState] = useState({ task: '', tat: '', remarks: '' });
    const [taskCategoryId, setTaskCategoryId] = useState('');
    const [taskCategoryData, setTaskCategoryData] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const navigate = useNavigate();
    const params = useParams();
    const getTaskCategories = () => {
        fetch(
            service.TASK_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.taskCategoryId, label: item.taskCategory }));
                setTaskCategoryData(data2);
            });
    };

    const getDetailsById = () => {
        setLoading(true);
        const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_TASK_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        TaskId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setState({ ...state, task: result.task, tat: result.tat, remarks: result.remarks });
                        const data = ({ value: result.taskCategoryId, label: result.taskCategory });
                        setTaskCategoryId(data);

                        localStorage.setItem('taskCategoryId', JSON.stringify(result.taskCategoryId));
                        localStorage.setItem('taskCategory', JSON.stringify(result.taskCategory));
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }

                });
        }
        setLoading(false);
    };

    useEffect(() => {
        getTaskCategories();
        if (params.id > 0) {
            getDetailsById();
        }
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!taskCategoryId) {
            isValid = false;
            errs[".taskCategory"] = "Please select task category";
        }
        if (!state.task) {
            isValid = false;
            errs[".task"] = "Please enter task";
        }
        if (!state.tat) {
            isValid = false;
            errs[".tat"] = "Please enter TAT";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_UPDATE_TASK,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        TaskId: params.id ? params.id : 0,
                        Task: state.task,
                        TaskCategoryId: taskCategoryId.value,
                        TAT: state.tat,
                        Remarks: state.remarks,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/masters/tasklist');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        // navigate('/masters/tasksubcategory');
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }
    const backHandler = () => {
        navigate('/masters/tasklist');
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <MDBox pt={4} pb={3} px={3} style={{ width: '50%' }}>
                                    <MDBox component="form" role="form">
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                            <Select
                                                options={taskCategoryData}
                                                name="taskcategory"
                                                value={taskCategoryId}
                                                onChange={ev => setTaskCategoryId(ev)}
                                                placeholder="Select Task Category"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".taskCategory"]}</div>
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput label="Task" fullWidth value={state.task || ''}
                                                name="task"
                                                onChange={changeHandler}
                                                id="task"
                                                required />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".task"]}</div>
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput label="TAT" fullWidth value={state.tat || ''}
                                                name="tat"
                                                onChange={changeHandler}
                                                id="tat"
                                                required
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".tat"]}</div>
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput label="Remarks" fullWidth value={state.remarks || ''}
                                                name="remarks"
                                                onChange={changeHandler}
                                                id="remarks"
                                                multiline
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".remarks"]}</div>
                                        </MDBox>
                                        <MDBox mt={4} mb={1} textAlign="center">
                                            {loading ? <Oval
                                                heigth="20"
                                                width="20"
                                                color='grey'
                                                ariaLabel='loading'
                                            /> :
                                                <div>
                                                    <MDButton color="info" onClick={submitHandler}>
                                                        SAVE
                                                    </MDButton>  &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <MDButton color="info" onClick={backHandler}>
                                                        BACK
                                                    </MDButton>
                                                </div>
                                            }
                                            {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddEditTask;