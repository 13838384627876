
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
// import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import * as moment from 'moment';
import { jsPDF } from 'jspdf';
import html2canvas from "html2canvas";
import logo from "assets/images/skglogo.jpg"
import * as service from '../../../services/service';

function PrintSupplierChallan() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const params = useParams();

    const getDetailsById = () => {
        setLoading(true);
        if (params.id > 0) {
            fetch(
                service.GET_PURCHASE_ORDER_FOR_WORK_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        PurchaseOrderId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    setData(result);
                });
        }
        setLoading(false);
    };

    useEffect(() => {
        getDetailsById();
    }, []);

    const printDocument = () => {
        setTimeout(() => {
            setTimeout(() => {
                setLoading(true);
            }, 100);
            const divToPrint = document.querySelector('#divForm');
            html2canvas(divToPrint).then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                const imgWidth = 190;
                const pageHeight = 290;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                // eslint-disable-next-line new-cap
                const doc = new jsPDF('pt', 'mm');
                let position = 0;
                doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25);
                heightLeft -= pageHeight;
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25);
                    heightLeft -= pageHeight;
                }
                doc.save('SupplierChallan.pdf');
                setLoading(false);
            });
        }, 1000);
    }

    const backHandler = () => {
        navigate('/supplier/purchaseorderforwork');
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    {/* <Card style={{ alignItems: 'center' }}> */}
                    <div id="divForm" width="100%">
                        <Table width="100%" style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', }}>
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan="2" align="center">
                                        <Table>
                                            <TableRow>
                                                <TableCell width="20%" align="left">
                                                    <img src={logo} alt="logo" width="50%" height="50%" />
                                                </TableCell>
                                                <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                    ShriKrishna Agro Equipment Pvt. Ltd.
                                                    <br />
                                                    <div style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                        S No.17/B,Plot No.5,Gala No.26, Pragati Industrial Complex,
                                                        <br />
                                                        Kothrud Industrial Estate, Pune-411038, India. Tel: +91-20- 25433054 • 25434328 •
                                                        <br />
                                                        Fax: +91-20-25469440,  Mobile : 9284964949, Email : vinayakduvedi@gmail.com / gm@shrikrishnagroup.co.in
                                                        <br />
                                                        Web :www.rocksplitter.in, GSTN: 27AAHC55679M12H, CIN: U2921PN1998PTC013074
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </Table>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan="2" align="center" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }} />
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan="2" align="center" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                        SUPPLIER CHALLAN
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width="50%" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                        To,
                                        <br />
                                        {data.supplierName}
                                        <br />
                                        {data.supplierAddress}, {data.supplierDistrict}, {data.supplierState}
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                        <Table width="100%" style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', }}>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                    PO No :
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                    {data.purchaseOrderNo}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                    PO Date :
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                    {moment(data.purchaseOrderDate).format("DD/MM/YYYY")}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                    Challan No :
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                    {data.challanNo}
                                                </TableCell>
                                            </TableRow>
                                        </Table>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan="2">
                                        <Table>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Sr.No.</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Product</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Sub Assembly</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Part</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Operation</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Rate</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Amount</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>CGST</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>SGST</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>IGST</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Total</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>1</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{data.product}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{data.subAssembly}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{data.part}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{data.operation}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{data.rate}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{data.amount}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{data.cgst}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{data.sgst}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{data.igst}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{data.totalAmount}</TableCell>
                                            </TableRow>
                                        </Table>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                        Prepared By
                                        <br />
                                        <br />
                                        <br />
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                        For ShriKrishna Agro Equipment Pvt. Ltd.
                                        <br />
                                        <br />
                                        <br />
                                        Authorized Signatory
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                    <MDBox mt={4} mb={1} textAlign="center">
                        {loading ? <Oval
                            heigth="20"
                            width="20"
                            color='grey'
                            ariaLabel='loading'
                        /> :
                            <div>
                                <MDButton color="info" onClick={() => printDocument()}>
                                    PRINT
                                </MDButton>  &nbsp;&nbsp;&nbsp;&nbsp;
                                <MDButton color="info" onClick={backHandler}>
                                    BACK
                                </MDButton>
                            </div>
                        }
                        {/* {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null} */}
                    </MDBox>

                    {/* </Card> */}

                </Grid>
            </Grid>

            <Footer />
        </DashboardLayout>
    );
}

export default PrintSupplierChallan;