import { useParams } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
// import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
// import Dropdown from 'react-dropdown';
// import 'react-dropdown/style.css';
import { Checkbox } from '@mui/material';
// import MDInput from "components/MDInput";
import * as service from '../../../services/service';

function ViewStock() {
    const [stockData, setStockData] = useState([]);
    const [workOrder, setWorkOrder] = useState([]);
    const [stockId, setStockId] = useState([]);
    const [reqStockData, setReqStockData] = useState([]);
    const [isSelected, setIsSelected] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    // const navigate = useNavigate();
    const params = useParams();

    const getWorkOrderById = () => {
        const errs = {};
        fetch(
            service.GET_WORK_ORDER_BY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    WorkOrderId: params.id,
                })
            })
            .then(res => res.json())
            .then(result => {
                setWorkOrder(result);
                fetch(
                    service.GET_AVAILABLE_STOCK,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ProductId: result.productId,
                            SubAssemblyId: result.subAssemblyId,
                            PartId: result.partId
                        })
                    })
                    .then(res1 => res1.json())
                    .then(result1 => {
                        if (result1[0].message.messageType === "Success") {
                            setStockData(result1);
                            result1.forEach((element, index) => {
                                stockId[index] = element.stockId;
                                setStockId([...stockId]);
                            });
                        }
                        else {
                            errs[".nodata"] = result1[0].message.message;
                            setErrors(errs);
                        }
                    })
                    .catch(err => {
                        if (err) {
                            setErrors(err);
                        };
                    });
            });
    };

    const getRequiredStockDetails = () => {
        setErrors('')
        // eslint-disable-next-line
        if (window.confirm("Do you want to create this stock? You won't be able to change it later...")) {
            const errs = {};
            fetch(
                service.ADD_REQUIRED_STOCK,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        WorkOrderId: workOrder.workOrderId,
                        JobNo: workOrder.jobNo,
                        ProductId: workOrder.productId,
                        SubAssemblyId: workOrder.subAssemblyId,
                        PartId: workOrder.partId,
                        Quantity: workOrder.quantity
                    })
                })
                .then(res => res.json())
                .then(res1 => {
                    // eslint-disable-next-line
                    console.log(res1);
                    fetch(
                        service.GET_REQUIRED_STOCK,
                        {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                WorkOrderId: workOrder.workOrderId,
                                ProductId: workOrder.productId,
                                SubAssemblyId: workOrder.subAssemblyId,
                                PartId: workOrder.partId,
                                Quantity: workOrder.quantity
                            })
                        })
                        .then(res => res.json())
                        .then(result => {
                            if (result[0].message.messageType === "Success") {
                                setReqStockData(result);
                            }
                            else {
                                errs[".noreqdata"] = result[0].message.message;
                                setErrors(errs);
                            }
                        })
                        .catch(err => {
                            if (err) {
                                setErrors(err);
                            };
                        });
                })
                .catch(err => {
                    if (err) {
                        setErrors(err);
                    };
                });
        }
    };

    const viewRequiredStockDetails = () => {
        setErrors('')
        const errs = {};
        fetch(
            service.GET_REQUIRED_STOCK,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    WorkOrderId: workOrder.workOrderId,
                    ProductId: workOrder.productId,
                    SubAssemblyId: workOrder.subAssemblyId,
                    PartId: workOrder.partId,
                    Quantity: workOrder.quantity
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result[0].message.messageType === "Success") {
                    setReqStockData(result);
                }
                else {
                    errs[".noreqdata"] = result[0].message.message;
                    setErrors(errs);
                }
            })
            .catch(err => {
                if (err) {
                    setErrors(err);
                };
            });
    };

    useEffect(() => {
        setLoading(true);
        getWorkOrderById();
        // getStockDetails();
        setLoading(false);
    }, []);


    const handleCheckboxChange = (checked, index) => {
        let updatedObject = isSelected[index];
        updatedObject = checked;
        isSelected[index] = updatedObject;
        setIsSelected([...isSelected]);
    }

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        let flag = true;
        // eslint-disable-next-line
        if (window.confirm("Do you want to save this stock allocation? You won't be able to change it later...")) {
            stockId.forEach((element, index) => {
                if (isSelected[index] === true) {
                    fetch(
                        service.ADD_STOCK_WORK_ORDER_MAPPING,
                        {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                WorkOrderId: workOrder.workOrderId,
                                StockId: element,
                                Status: 'Completed',
                                AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId
                            })
                        })
                        .then(resp => resp.json())
                        .then(respo => {
                            if (respo.messageType === 'Success') {
                                // eslint-disable-next-line
                                // alert(respo.message);
                                // navigate('/masters/operationmachinemap');
                            }
                            else if (respo.messageType === 'Failed') {
                                flag = false;
                                // eslint-disable-next-line
                                alert(respo.message);
                            }
                            else {
                                flag = false;
                                errs[".invalid"] = respo.message.message;
                                setErrors(errs);
                            }
                        });
                }
            });
            if (flag) {
                // eslint-disable-next-line
                alert("Data Saved Successfully");
                // navigate('/masters/operationmachinemap');
                window.location.reload(false);
            }
        }

        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Available Stock
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '90%', alignSelf: 'center', }}>
                                        <Table>
                                            <TableRow>
                                                <TableCell style={{ width: '25%', fontWeight: 'bold' }} align='right'>
                                                    Work Order No.
                                                </TableCell>
                                                <TableCell style={{ width: '20%', fontWeight: 'bold' }} align='left'>
                                                    {workOrder.workOrderNo}
                                                </TableCell>
                                                <TableCell style={{ width: '25%', fontWeight: 'bold' }} align='right'>
                                                    Job No.
                                                </TableCell>
                                                <TableCell style={{ width: '20%', fontWeight: 'bold' }} align='left'>
                                                    {workOrder.jobNo}
                                                </TableCell>
                                            </TableRow>
                                        </Table>
                                        <Table aria-label="simple table">
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Stock Number</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Product</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Sub Assembly</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Part</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {stockData.map((row, index) => (
                                                    <TableRow
                                                        key={row.stockId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>
                                                            <Checkbox checked={isSelected[index] || false}
                                                                onChange={(e) => handleCheckboxChange(e.target.checked, index)} id="chkSelect" />
                                                        </TableCell>
                                                        <TableCell>{row.stockNumber}</TableCell>
                                                        <TableCell>{row.product}</TableCell>
                                                        <TableCell>{row.subAssembly}</TableCell>
                                                        <TableCell>{row.part}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                                {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".nodata"]}</div> : null}
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <MDButton color="info" onClick={submitHandler} disabled={!stockData.length > 0 || reqStockData.length > 0}>
                                            Allocate Stock
                                        </MDButton>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Create/View Required Stock
                                </MDTypography>
                            </MDBox>
                            <br />
                            <MDBox mt={1} mb={1} textAlign="left" style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                Important Note
                                <br />
                                First click on View Created Stock button to view already created stock for the work order.
                                <br />
                                If stock is not created then Data not found message will be displayed.
                                <br />
                                Then click on Create Required Stock button to create the required stock for the work order.
                            </MDBox>
                            <MDBox mt={4} mb={1} textAlign="center">
                                {loading ? <Oval
                                    heigth="20"
                                    width="20"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :

                                    <div>
                                        <MDButton color="info" onClick={viewRequiredStockDetails}>
                                            View Created Stock
                                        </MDButton>  &nbsp;&nbsp;&nbsp;&nbsp;
                                        <MDButton color="info" onClick={getRequiredStockDetails}>
                                            Create Required Stock
                                        </MDButton>
                                    </div>
                                }
                                {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".noreqdata"]}</div> : null}
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '90%', alignSelf: 'center', }}>
                                        <Table>
                                            <TableRow>
                                                <TableCell style={{ width: '25%', fontWeight: 'bold' }} align='right'>
                                                    Work Order No.
                                                </TableCell>
                                                <TableCell style={{ width: '20%', fontWeight: 'bold' }} align='left'>
                                                    {workOrder.workOrderNo}
                                                </TableCell>
                                                <TableCell style={{ width: '25%', fontWeight: 'bold' }} align='right'>
                                                    Job No.
                                                </TableCell>
                                                <TableCell style={{ width: '20%', fontWeight: 'bold' }} align='left'>
                                                    {workOrder.jobNo}
                                                </TableCell>
                                            </TableRow>
                                        </Table>
                                        <Table aria-label="simple table">
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Stock Number</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Product</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Sub Assembly</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Part</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {reqStockData.map((row) => (
                                                    <TableRow
                                                        key={row.stockId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{row.stockNumber}</TableCell>
                                                        <TableCell>{row.product}</TableCell>
                                                        <TableCell>{row.subAssembly}</TableCell>
                                                        <TableCell>{row.part}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default ViewStock;