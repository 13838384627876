
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import * as moment from 'moment';
import { Radio } from '@mui/material';
import * as service from '../../../services/service';

function AddEditWorkAllocationToSupplier() {
    const [state, setState] = useState({ remarks: '' });
    const [allocationDate, setAllocationDate] = useState(new Date());
    const [plannedCompletionDate, setPlannedCompletionDate] = useState(new Date());
    const [productCatData, setProductCatData] = useState([]);
    const [productSubCatData, setProductSubCatData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [subAssemblyData, setSubAssemblyData] = useState([]);
    const [partData, setPartData] = useState([]);
    const [operationData, setOperationData] = useState([]);
    const [productCatId, setProductCatId] = useState('');
    const [productSubCatId, setProductSubCatId] = useState('');
    const [productId, setProductId] = useState('');
    const [subAssemblyId, setSubAssemblyId] = useState('');
    const [partId, setPartId] = useState('');
    const [operationId, setOperationId] = useState('');
    const [supplierId, setSupplierId] = useState('');
    const [supplierName, setSupplierName] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [allocationFor, setAllocationFor] = useState('');
    const allocationForData = [{ label: 'Work Order', value: 'Work Order' }, { label: 'Office Purpose', value: 'Office Purpose' }]
    const [customerData, setCustomerData] = useState([]);
    const [workOrderData, setWorkOrderData] = useState([]);
    const [customerId, setCustomerId] = useState('');
    const [workOrderId, setWorkOrderId] = useState('');
    const [allocationData, setAllocationData] = useState([]);
    const [stockId, setStockId] = useState('');
    const [prodId, setProdId] = useState('');
    const [subAssemId, setSubAssemId] = useState('');
    const [prtId, setPrtId] = useState('');
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const navigate = useNavigate();
    const params = useParams();

    const getCustomers = () => {
        fetch(
            service.CUSTOMER_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.customerId, label: item.customerName }));
                setCustomerData(data2);
            });
    };

    const getWorkOrders = (id) => {
        fetch(
            service.GET_PENDING_WORK_ORDER,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CustomerId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.workOrderId, label: item.jobNo }));
                setWorkOrderData(data2);
            });
    };

    const getStock = (id) => {
        const errs = {};
        fetch(
            service.GET_REQUIRED_STOCK,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    WorkOrderId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result[0].message.messageType === "Success") {
                    setAllocationData(result);
                }
                else {
                    errs[".noreqdata"] = result[0].message.message;
                    setErrors(errs);
                }
            })
            .catch(err => {
                if (err) {
                    setErrors(err);
                };
            });
    };

    const getProductCategories = () => {
        fetch(
            service.PRODUCT_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.productCategoryId, label: item.productCategory }));
                setProductCatData(data2);
            });
    };
    const getProductSubCategories = (id) => {
        fetch(
            service.PRODUCT_SUB_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductCategoryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.productSubCategoryId, label: item.productSubCategory }));
                setProductSubCatData(data2);
            });
    };

    const getProducts = (catId, subCatId) => {
        fetch(
            service.PRODUCT_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductCategoryId: catId,
                    ProductSubCategoryId: subCatId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.productId, label: item.product }));
                setProductData(data2);
            });
    };
    const getSubAssemblies = (id) => {
        fetch(
            service.SUBASSEMBLY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductId: id
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.subAssemblyId, label: item.subAssembly }));
                setSubAssemblyData(data2);
            });
    };

    const getParts = (assemblyId) => {
        fetch(
            service.PART_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SubAssemblyId: assemblyId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.partId, label: item.part }));
                setPartData(data2);
            });
    };
    const getOperations = (prId, assemblyId, id) => {
        fetch(
            service.OPERATION_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductId: prId,
                    SubAssemblyId: assemblyId,
                    PartId: id
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.operationId, label: item.operation }));
                setOperationData(data2);
            });
    };
    const getSuppliers = (id) => {
        fetch(
            service.GET_SUPPLIER_FOR_OPERATION,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    OperationId: id
                })
            })
            .then(res => res.json())
            .then(result => {
                setSupplierId(result.supplierId);
                setSupplierName(result.supplierName);
                // const data2 = ({ value: result.supplierId, label: result.supplierName });
                // setSupplierData(data2);
            });
    };

    useEffect(() => {
        getProductCategories();
        getCustomers();
        // if (params.id > 0) {
        //     getDetailsById();
        // }
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!allocationFor) {
            isValid = false;
            errs[".allocationFor"] = "Please select allocation for";
        }
        if (allocationFor.value === "Work Order" && !customerId) {
            isValid = false;
            errs[".customer"] = "Please select customer";
        }
        if (allocationFor.value === "Work Order" && !workOrderId) {
            isValid = false;
            errs[".workorder"] = "Please select work order";
        }
        if (allocationFor.value === "Work Order" && !stockId) {
            isValid = false;
            errs[".stockId"] = "Please select stock";
        }
        if (allocationFor.value === "Office Purpose" && !productCatId) {
            isValid = false;
            errs[".productcategory"] = "Please select product category";
        }
        if (allocationFor.value === "Office Purpose" && !productSubCatId) {
            isValid = false;
            errs[".productsubcategory"] = "Please select product sub category";
        }
        if (allocationFor.value === "Office Purpose" && !productId) {
            isValid = false;
            errs[".product"] = "Please select product";
        }
        // if (allocationFor.value === "Office Purpose" && !subAssemblyId) {
        //     isValid = false;
        //     errs[".subassembly"] = "Please select sub assembly";
        // }
        if (!operationId) {
            isValid = false;
            errs[".operation"] = "Please select operation";
        }
        if (!supplierId) {
            isValid = false;
            errs[".supplier"] = "Please select supplier";
        }
        if (!allocationDate) {
            isValid = false;
            errs[".allocationdate"] = "Please enter allocation date";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const handleRadioChange = (stId, prId, subId, ptId) => {
        setStockId(stId);
        setProdId(prId);
        setSubAssemId(subId);
        setPrtId(ptId);
        getOperations(prId, subId, ptId);
    }

    const allocationForChangeHandler = (ev) => {
        setAllocationFor(ev);
        setCustomerId(null);
        setWorkOrderId(null);
    }

    const customerChangeHandler = (ev) => {
        setCustomerId(ev);
        setWorkOrderId(null);
        getWorkOrders(ev.value);
    }
    const workOrderChangeHandler = (ev) => {
        setWorkOrderId(ev);
        setOperationId(null);
        getStock(ev.value);
    }

    const productCatChangeHandler = (ev) => {
        setProductCatId(ev);
        setProductSubCatId(null);
        setProductId(null);
        setSubAssemblyId(null);
        setOperationId(null);
        setSupplierId(null);
        getProductSubCategories(ev.value);
    }
    const productSubCatChangeHandler = (ev) => {
        setProductSubCatId(ev);
        setProductId(null);
        setSubAssemblyId(null);
        setOperationId(null);
        setSupplierId(null);
        getProducts(productCatId.value, ev.value);
    }
    const productChangeHandler = (ev) => {
        setProductId(ev);
        setOperationId(null);
        setSupplierId(null);
        getSubAssemblies(ev.value);
        getOperations(ev.value, null, null);
    }
    const subAssemblyChangeHandler = (ev) => {
        setSubAssemblyId(ev);
        getParts(ev.value);
        setSupplierId(null);
        getOperations(productId.value, ev.value, null);
    }
    const partChangeHandler = (ev) => {
        setPartId(ev);
        setOperationId(null);
        setSupplierId(null);
        getOperations(productId.value, subAssemblyId.value, ev.value);
    }
    const operationChangeHandler = (ev) => {
        setOperationId(ev);
        setSupplierId(null);
        getSuppliers(ev.value);
    }

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            let subAssId = 0;
            let pId = 0;
            if (allocationFor.value === "Office Purpose") {
                pId = partId ? partId.value : 0;
                subAssId = subAssemblyId ? subAssemblyId.value : 0
            }
            else {
                pId = prtId;
                subAssId = subAssemId;
            }
            fetch(
                service.ADD_UPDATE_WORK_ALLOCATION_TO_SUPPLIER,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        WorkAllocationId: params.id ? params.id : null,
                        AllocationFor: allocationFor.value,
                        Remarks: state.remarks,
                        CustomerId: customerId ? customerId.value : 0,
                        WorkOrderId: workOrderId ? workOrderId.value : 0,
                        JobNo: workOrderId ? workOrderId.label : 0,
                        StockId: stockId,
                        ProductId: allocationFor.value === "Office Purpose" ? productId.value : prodId,
                        SubAssemblyId: subAssId,
                        PartId: pId,
                        OperationId: operationId.value,
                        SupplierId: supplierId,
                        AllocationDate: moment(allocationDate).format("MM/DD/YYYY"),
                        PlannedCompletionDate: moment(plannedCompletionDate).format("MM/DD/YYYY"),
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/workallocation/workallocationtosupplier');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/workallocation/workallocationtosupplier');
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    const backHandler = () => {
        navigate('/workallocation/workallocationtosupplier');
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <TableContainer component={Paper} style={{ width: '80%', alignSelf: 'center', }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <div style={{ fontSize: '12px' }}>
                                                        <Select
                                                            options={allocationForData}
                                                            name="allocationFor"
                                                            value={allocationFor}
                                                            onChange={allocationForChangeHandler}
                                                            placeholder="Select For"
                                                            isSearchable
                                                            styles={selectStyles}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".allocationFor"]}</div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                        <Select
                                                            options={customerData}
                                                            name="customer"
                                                            value={customerId}
                                                            onChange={customerChangeHandler}
                                                            placeholder="Select Customer"
                                                            isSearchable
                                                            styles={selectStyles}
                                                            isDisabled={allocationFor.value === 'Office Purpose'}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".customer"]}</div>
                                                    </MDBox>
                                                </TableCell>
                                                <TableCell>
                                                    <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                        <Select
                                                            options={workOrderData}
                                                            name="workorder"
                                                            value={workOrderId}
                                                            onChange={workOrderChangeHandler}
                                                            placeholder="Select Job Number"
                                                            isSearchable
                                                            styles={selectStyles}
                                                            isDisabled={allocationFor.value === 'Office Purpose'}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".workorder"]}</div>
                                                    </MDBox>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={3} align='center'>
                                                    {loading ? <Oval
                                                        heigth="100"
                                                        width="100"
                                                        color='grey'
                                                        ariaLabel='loading'
                                                    /> :
                                                        <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                                            <Table aria-label="simple table">
                                                                <TableRow>
                                                                    <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold' }}>Product</TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold' }}>Sub Assembly</TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold' }}>Part</TableCell>
                                                                    <TableCell style={{ fontWeight: 'bold' }}>Stock Number</TableCell>
                                                                </TableRow>

                                                                <TableBody>
                                                                    {allocationData.map((row) => (
                                                                        <TableRow
                                                                            key={row.stockId}
                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        >
                                                                            <TableCell><Radio onChange={() => handleRadioChange(row.stockId, row.productId, row.subAssemblyId, row.partId)}
                                                                                value={row.stockId} name="radio-buttons"
                                                                                checked={stockId === row.stockId} />
                                                                            </TableCell>
                                                                            <TableCell>{row.product}</TableCell>
                                                                            <TableCell>{row.subAssembly}</TableCell>
                                                                            <TableCell>{row.part}</TableCell>
                                                                            <TableCell>{row.stockNumber}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".stockId"]}</div>
                                                        </TableContainer>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                        <Select
                                                            options={productCatData}
                                                            name="productCategory"
                                                            value={productCatId}
                                                            onChange={productCatChangeHandler}
                                                            placeholder="Select Product Category"
                                                            isSearchable
                                                            styles={selectStyles}
                                                            isDisabled={allocationFor.value === 'Work Order'}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".productcategory"]}</div>
                                                    </MDBox>
                                                </TableCell>
                                                <TableCell>
                                                    <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                        <Select
                                                            options={productSubCatData}
                                                            name="productSubCategory"
                                                            value={productSubCatId}
                                                            onChange={productSubCatChangeHandler}
                                                            placeholder="Select Product Sub Category"
                                                            isSearchable
                                                            styles={selectStyles}
                                                            isDisabled={allocationFor.value === 'Work Order'}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".productsubcategory"]}</div>
                                                    </MDBox>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                        <Select
                                                            options={productData}
                                                            name="product"
                                                            value={productId}
                                                            onChange={productChangeHandler}
                                                            placeholder="Select Product"
                                                            isSearchable
                                                            styles={selectStyles}
                                                            isDisabled={allocationFor.value === 'Work Order'}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".product"]}</div>
                                                    </MDBox>
                                                </TableCell>
                                                <TableCell>
                                                    <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                        <Select
                                                            options={subAssemblyData}
                                                            name="subassembly"
                                                            value={subAssemblyId}
                                                            onChange={subAssemblyChangeHandler}
                                                            placeholder="Select Sub Assembly"
                                                            isSearchable
                                                            styles={selectStyles}
                                                            isDisabled={allocationFor.value === 'Work Order'}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".subassembly"]}</div>
                                                    </MDBox>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                        <Select
                                                            options={partData}
                                                            name="part"
                                                            value={partId}
                                                            onChange={partChangeHandler}
                                                            placeholder="Select Part"
                                                            isSearchable
                                                            styles={selectStyles}
                                                            isDisabled={allocationFor.value === 'Work Order'}
                                                        />
                                                    </MDBox>
                                                </TableCell>
                                                <TableCell>
                                                    <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                        <Select
                                                            options={operationData}
                                                            name="operation"
                                                            value={operationId}
                                                            onChange={operationChangeHandler}
                                                            placeholder="Select Operation"
                                                            isSearchable
                                                            styles={selectStyles}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".operation"]}</div>
                                                    </MDBox>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDBox mb={2} style={{ fontSize: '14px' }}>
                                                        <MDInput label="Supplier" fullWidth value={supplierName || ''}
                                                            name="supplierName"
                                                            onChange={changeHandler}
                                                            id="supplierName"
                                                            disabled
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".supplier"]}</div>
                                                    </MDBox>
                                                </TableCell>
                                                <TableCell />
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Allocation Date
                                                    <DatePicker
                                                        id="allocationDate"
                                                        selected={allocationDate}
                                                        onChange={date => setAllocationDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                        tabIndex={0}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    Planned Completion Date
                                                    <DatePicker
                                                        id="plannedCompletionDate"
                                                        selected={plannedCompletionDate}
                                                        onChange={date => setPlannedCompletionDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                        tabIndex={0}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Remarks" fullWidth value={state.remarks || ''}
                                                        name="remarks"
                                                        onChange={changeHandler}
                                                        id="remarks"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".remarks"]}</div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>  &nbsp;&nbsp;&nbsp;&nbsp;
                                            <MDButton color="info" onClick={backHandler}>
                                                BACK
                                            </MDButton>
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddEditWorkAllocationToSupplier;