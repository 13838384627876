import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { Oval } from 'react-loader-spinner';
import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/task/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from 'react-select';
import * as service from '../../../services/service';

function TaskList() {
    const [data, setData] = useState([]);
    const [taskCategoryData, setTaskCategoryData] = useState([]);
    const [taskCategoryId, setTaskCategoryId] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const navigate = useNavigate();
    const GetTaskCategories = () => {
        fetch(
            service.TASK_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.taskCategoryId, label: item.taskCategory }));
                setTaskCategoryData(data2);
            });
    };
    const getDetails = (id) => {
        if (id > 0) {
            fetch(
                service.GET_TASK_BY_CATEGORY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        TaskCategoryId: id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    setData(result);
                })
                .catch(err => {
                    if (err) {
                        setError(err);
                    };
                });
        }
    };

    const handleEdit = (id) => {
        navigate(`/addedittask/${id}`);
    }

    const changeHandler = (ev) => {
        setTaskCategoryId(ev);
        getDetails(ev.value);
    }

    useEffect(() => {
        setLoading(true);
        GetTaskCategories();
        if (JSON.parse(localStorage.getItem('taskCategoryId')) != null) {
            const item = ({ value: JSON.parse(localStorage.getItem('taskCategoryId')), label: JSON.parse(localStorage.getItem('taskCategory')) });
            setTaskCategoryId(item);
            getDetails(JSON.parse(localStorage.getItem('taskCategoryId')));
        };
        localStorage.setItem('taskCategoryId', null);
        localStorage.setItem('taskCategory', null);
        setLoading(false);
    }, []);

    const addNew = () => {
        navigate('/addedittask/0');
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {error
                ? <MDAlert color="warning" dismissible>{error}</MDAlert>
                : null
            }
            <div style={{ float: 'right' }}>
                <MDButton color="info" onClick={addNew}>
                    ADD NEW
                </MDButton>
            </div>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Task Details
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <Table aria-label="simple table">
                                    <TableRow>
                                        <TableCell align='right' style={{ fontSize: '12px', width: '30%' }}>Task Category : </TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                            <Select
                                                options={taskCategoryData}
                                                name="taskcategory"
                                                value={taskCategoryId}
                                                onChange={changeHandler}
                                                placeholder="Select Task Category"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px', width: '30%' }} />
                                    </TableRow>
                                </Table>
                                <br />
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '90%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Task Category</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Task</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>TAT</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Remarks</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {data.map((row) => (
                                                    <TableRow
                                                        key={row.taskId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">{row.taskCategory}</TableCell>
                                                        <TableCell component="th" scope="row">{row.task}</TableCell>
                                                        <TableCell component="th" scope="row">{row.tat}</TableCell>
                                                        <TableCell component="th" scope="row">{row.remarks}</TableCell>
                                                        <TableCell><MDButton onClick={() => handleEdit(row.taskId)}>Edit</MDButton></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default TaskList;