/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
// import Sales from "layouts/dashboards/sales";
// import ProfileOverview from "layouts/pages/profile/profile-overview";
// import AllProjects from "layouts/pages/profile/all-projects";
// import NewUser from "layouts/pages/users/new-user";
// import Settings from "layouts/pages/account/settings";
// import Billing from "layouts/pages/account/billing";
// import Invoice from "layouts/pages/account/invoice";
// import Timeline from "layouts/pages/projects/timeline";
// import PricingPage from "layouts/pages/pricing-page";
// import Widgets from "layouts/pages/widgets";
// import RTL from "layouts/pages/rtl";
// import Charts from "layouts/pages/charts";
// import Notifications from "layouts/pages/notifications";
// import Kanban from "layouts/applications/kanban";
// import Wizard from "layouts/applications/wizard";
// import DataTables from "layouts/applications/data-tables";
// import Calendar from "layouts/applications/calendar";

// import ProductPage from "layouts/ecommerce/products/product-page";

import SignInBasic from "layouts/authentication/sign-in/basic";
// import SignInCover from "layouts/authentication/sign-in/cover";
// import SignInIllustration from "layouts/authentication/sign-in/illustration";
// import SignUpCover from "layouts/authentication/sign-up/cover";
// import ResetCover from "layouts/authentication/reset-password/cover";

import Bank from "layouts/masters/bank";
import Branch from "layouts/masters/branch";
import State from "layouts/masters/state";
import District from "layouts/masters/district";
import Designation from "layouts/masters/designation";
import ProductCategory from "layouts/masters/productcategory";
import ProductSubCategory from "layouts/masters/productsubcategory";
import Product from "layouts/masters/product";
import SubAssembly from "layouts/masters/subassembly";
import Part from "layouts/masters/part";
import Operation from "layouts/masters/operation";
import Machine from "layouts/masters/machine";
import MaterialCategory from "layouts/masters/materialcategory";
import MaterialSubCategory from "layouts/masters/materialsubcategory";
import Material from "layouts/masters/material";
import SupplierCategory from "layouts/masters/suppliercategory";
import Supplier from "layouts/supplier";
import Employee from "layouts/employee";
import Customer from "layouts/customer";
import OperationMachineMap from "layouts/masters/operationmachinemap";
import SubAssemblyMaterialMap from "layouts/masters/subassemblymaterialmap";
import SupplierMaterialMap from "layouts/supplier/suppliermaterialmap";
import PurchaseOrder from "layouts/supplier/purchaseorder";
import OperationCriticalParameter from "layouts/masters/operationcriticalparameter";
import OperationInspectionParameter from "layouts/masters/operationinspectionparameter";
import WorkAllocation from "layouts/workallocation";
import WorkOrder from "layouts/workorder";
import DailyWorkDetails from "layouts/dailyworkdetails";
import DailyWorkStatus from "layouts/dailyworkstatus";
import AddEditWorkAllocationForWorkOrder from "layouts/workallocation/Transaction/WorkAllocationForWorkOrder";
import StockAllocation from "layouts/workallocation/stockallocation";
import StockMappingForWorOrder from "layouts/workallocation/stockmappingwo";
import AddMaterialIssue from "layouts/materialissue";
import MaterialRequisitions from "layouts/materialrequisition";
import MaterialReceipt from "layouts/supplier/materailreceipt";
import EmployeeReport from "layouts/reports/employeereport";
import SupplierReport from "layouts/reports/supplierreport";
import CustomerReport from "layouts/reports/customerreport";
import WorkOrderReport from "layouts/reports/workorderreport";
import TaskCategory from "layouts/masters/taskcategory";
import TaskList from "layouts/masters/tasklist";
import TaskAllocation from "layouts/taskallocation";
import TaskDetails from "layouts/taskdetails";
import TaskStatusReport from "layouts/reports/taskstatusreport";
import SupplierOperationMap from "layouts/supplier/supplieroperationmap";
import WorkAllocationToSupplier from "layouts/workallocationtosupplier";
import PurchaseOrderForWork from "layouts/supplier/purchaseorderforwork";
import SupplierWorkForApproval from "layouts/supplierworkforapproval";
import PreDeliveryInspectionCriteria from "layouts/masters/predeliveryinspectioncriteria";
import PreDeliveryInspection from "layouts/predeliveryinspection";
import HSNCodes from "layouts/masters/hsncodes";
import DeliveryChallan from "layouts/deliverychallan";
import Invoice from "layouts/invoice";
import ViewDeliveryChallans from "layouts/deliverychallan/viewdeliverychallans";
import ViewInvoices from "layouts/invoice/viewinvoices";
import LRDetails from "layouts/lrdetails";
import ViewLRDetails from "layouts/lrdetails/viewlrdetails";
import InspectionCheckList from "layouts/masters/inspectionchecklist";
import ViewInspections from "layouts/inspectioncommissioning/viewinspections";
import PurchaseOrderReport from "layouts/reports/purchaseorderreport";
import PurchaseOrderForWorkReport from "layouts/reports/purchaseorderforworkreport";
import InvoiceReport from "layouts/reports/invoicereport";
import WorkAllocationReport from "layouts/reports/workallocationreport";
import WorkAllocationToSupplierReport from "layouts/reports/workallocationtosupplierreport";
import WorkAllocationStatusReport from "layouts/reports/workallocationstatusreport";
import MaterialStockReport from "layouts/reports/materialstockreport";
import MaterialIssueReport from "layouts/reports/materialissuereport";
import RejectedOperationReport from "layouts/reports/rejectedoperationreport";
import RejectedOperationOfSupplierReport from "layouts/reports/rejectedoperationofsupplierreport";
import EmployeePerformanceReport from "layouts/reports/employeeperformancereport";
import CustomerReceipts from "layouts/customerreceipts";
import SupplierPayments from "layouts/supplier/supplierpayment";
import CustomerReceiptReport from "layouts/reports/customerreceiptreport";
import SupplierPaymentReport from "layouts/reports/supplierpaymentreport";
import ChangePassword from "layouts/masters/changepassword";
// Material Dashboard 2 PRO React components
// import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
// import profilePicture from "assets/images/team-3.jpg";

const routes = [  
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: "/dashboards/analytics",
    component: <Analytics />,    
    noCollapse: true
  },
  { type: "divider", key: "divider-0" }, 
  {
    type: "collapse",
    name: "Masters",
    key: "masters",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Bank",
        key: "bank",
        route: "/masters/bank",
        component: <Bank />,
      },
      {
        name: "Branch",
        key: "branch",
        route: "/masters/branch",
        component: <Branch />,
      }, 
      {
        name: "State",
        key: "state",
        route: "/masters/state",
        component: <State />,
      }, 
      {
        name: "District",
        key: "district",
        route: "/masters/district",
        component: <District />,
      }, 
      {
        name: "Designation",
        key: "designation",
        route: "/masters/designation",
        component: <Designation />,
      }, 
      {
        name: "Product Category",
        key: "productcategory",
        route: "/masters/productcategory",
        component: <ProductCategory />,
      }, 
      {
        name: "Product Sub Category",
        key: "productsubcategory",
        route: "/masters/productsubcategory",
        component: <ProductSubCategory />,
      }, 
      {
        name: "Product",
        key: "product",
        route: "/masters/product",
        component: <Product />,
      }, 
      {
        name: "Sub Assembly",
        key: "subassembly",
        route: "/masters/subassembly",
        component: <SubAssembly />,
      }, 
      {
        name: "Part",
        key: "part",
        route: "/masters/part",
        component: <Part />,
      }, 
      {
        name: "Operation",
        key: "operation",
        route: "/masters/operation",
        component: <Operation />,
      }, 
      {
        name: "Material Category",
        key: "materialcategory",
        route: "/masters/materialcategory",
        component: <MaterialCategory />,
      }, 
      {
        name: "Material Sub Category",
        key: "materialsubcategory",
        route: "/masters/materialsubcategory",
        component: <MaterialSubCategory />,
      },
      {
        name: "Material",
        key: "material",
        route: "/masters/material",
        component: <Material />,
      },  
      {
        name: "Machine",
        key: "machine",
        route: "/masters/machine",
        component: <Machine />,
      },  
      {
        name: "Operation Machine Map",
        key: "operationmachinemap",
        route: "/masters/operationmachinemap",
        component: <OperationMachineMap />,
      },    
      {
        name: "Sub Assembly/Part Material Map",
        key: "subassemblymaterialmap",
        route: "/masters/subassemblymaterialmap",
        component: <SubAssemblyMaterialMap />,
      }, 
      {
        name: "Operation Critical Parameters",
        key: "operationcriticalparameter",
        route: "/masters/operationcriticalparameter",
        component: <OperationCriticalParameter />,
      },  
      {
        name: "Operation Inspection Parameters",
        key: "operationinspectionparameter",
        route: "/masters/operationinspectionparameter",
        component: <OperationInspectionParameter />,
      }, 
      {
        name: "Pre Delivery Inspection Criteria",
        key: "predeliveryinspectioncriteria",
        route: "/masters/predeliveryinspectioncriteria",
        component: <PreDeliveryInspectionCriteria />,
      }, 
      {
        name: "Task Category",
        key: "taskcategory",
        route: "/masters/taskcategory",
        component: <TaskCategory />,
      },  
      {
        name: "Task",
        key: "tasklist",
        route: "/masters/tasklist",
        component: <TaskList />,
      },  
      {
        name: "HSN Codes",
        key: "hsncodes",
        route: "/masters/hsncodes",
        component: <HSNCodes />,
      }, 
      {
        name: "Inspection Check List",
        key: "inspectionchecklist",
        route: "/masters/inspectionchecklist",
        component: <InspectionCheckList />,
      },  
    ],
  },
  { type: "divider", key: "divider-1" },
  {
    type: "collapse",
    name: "Customer",
    key: "customer",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [
      {        
        name: "Customer",
        key: "customer",
        route: "/customer/customer",
        component: <Customer />,        
      },  
      {        
        name: "Work Order",
        key: "workorder",
        route: "/customer/workorder",
        component: <WorkOrder />,        
      },   
      {        
        name: "Customer Receipts",
        key: "customerreceipts",
        route: "/customer/customerreceipts",
        component: <CustomerReceipts />,        
      },   
    ],
  },
  { type: "divider", key: "divider-2" },
  {
    type: "collapse",
    name: "Supplier",
    key: "supplier",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [
      {        
        name: "Supplier Category",
        key: "suppliercategory",
        route: "/supplier/suppliercategory",
        component: <SupplierCategory />,        
      }, 
      {        
        name: "Supplier",
        key: "supplier",
        route: "/supplier/supplier",
        component: <Supplier />,        
      },
      {
        name: "Supplier Material Map",
        key: "suppliermaterialmap",
        route: "/supplier/suppliermaterialmap",
        component: <SupplierMaterialMap />,
      }, 
      {
        name: "Supplier Operation Map",
        key: "supplieroperationmap",
        route: "/supplier/supplieroperationmap",
        component: <SupplierOperationMap />,
      },
      {        
        name: "Supplier Payments",
        key: "supplierpayments",
        route: "/supplier/supplierpayments",
        component: <SupplierPayments />,        
      },       
    ],
  },
  { type: "divider", key: "divider-3" },
  {
    type: "collapse",
    name: "Employee",
    key: "employee",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [
      {        
        name: "Employee",
        key: "employee",
        route: "/employee/employee",
        component: <Employee />,        
      }, 
      {        
        name: "Task Allocation",
        key: "taskallocation",
        route: "/taskallocation/taskallocation",
        component: <TaskAllocation />,        
      },   
      {        
        name: "Task Details",
        key: "taskdetails",
        route: "/taskdetails/taskdetails",
        component: <TaskDetails />,        
      },   
      {        
        name: "Daily Work Details",
        key: "dailyworkdetails",
        route: "/workallocation/dailyworkdetails",
        component: <DailyWorkDetails />,        
      },       
    ],
  },
  { type: "divider", key: "divider-4" },
  {
    type: "collapse",
    name: "Material Transactions",
    key: "materialtransactions",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [  
      {        
        name: "Material Issue",
        key: "materialissue",
        route: "/materialissue",
        component: <AddMaterialIssue />,        
      }, 
      {        
        name: "Material Requisitions",
        key: "materialrequisition",
        route: "/materialrequisition",
        component: <MaterialRequisitions />,        
      },     
      {
        name: "Purchase Order",
        key: "purchaseorder",
        route: "/supplier/purchaseorder",
        component: <PurchaseOrder />,
      }, 
      {
        name: "Material Receipt",
        key: "materialreceipt",
        route: "/supplier/materialreceipt",
        component: <MaterialReceipt />,
      },       
    ],
  },
  { type: "divider", key: "divider-5" },
  {
    type: "collapse",
    name: "Work Allocations",
    key: "workallocation",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [
      
      {        
        name: "Work Allocation For Work Order",
        key: "workallocationforwordorder",
        route: "/workallocation/workallocationforwordorder",
        component: <AddEditWorkAllocationForWorkOrder />,        
      },  
      {        
        name: "Work Allocation For Stock",
        key: "workallocationforstock",
        route: "/workallocation/workallocationforstock",
        component: <WorkAllocation />,        
      }, 
      {        
        name: "Work Allocation To Supplier",
        key: "workallocationtosupplier",
        route: "/workallocation/workallocationtosupplier",
        component: <WorkAllocationToSupplier />,        
      },  
      {
        name: "Purchase Order For Work",
        key: "purchaseorderforwork",
        route: "/supplier/purchaseorderforwork",
        component: <PurchaseOrderForWork />,
      },           
    ],
  },
  { type: "divider", key: "divider-6" },
  {
    type: "collapse",
    name: "Work Approvals",
    key: "workapproval",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [
      {        
        name: "Daily Work For Approval",
        key: "dailyworkstatus",
        route: "/workallocation/dailyworkstatus",
        component: <DailyWorkStatus />,        
      }, 
      {        
        name: "Supplier Work For Approval",
        key: "supplierworkforapproval",
        route: "/workallocation/supplierworkforapproval",
        component: <SupplierWorkForApproval />,        
      }, 
    ],
  },
  { type: "divider", key: "divider-7" },
  {
    type: "collapse",
    name: "Stock Mappings",
    key: "stockmapping",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [
      {        
        name: "Stock Mapping For Office Purpose",
        key: "stockallocation",
        route: "/workallocation/stockallocation",
        component: <StockAllocation />,        
      }, 
      {        
        name: "Stock Mapping For Work Order",
        key: "stockmappingforworkorder",
        route: "/workallocation/stockmappingforworkorder",
        component: <StockMappingForWorOrder />,        
      }, 
    ],
  },
  { type: "divider", key: "divider-8" },
  {
    type: "collapse",
    name: "Delivery",
    key: "delivery",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [
      {        
        name: "Pre Delivery Inspection",
        key: "predeliveryinspection",
        route: "/predeliveryinspection",
        component: <PreDeliveryInspection />,        
      },  
      {        
        name: "Delivery Challan",
        key: "deliverychallan",
        route: "/deliverychallan",
        component: <DeliveryChallan />,        
      },  
      {        
        name: "View Delivery Challan",
        key: "viewdeliverychallans",
        route: "/viewdeliverychallans",
        component: <ViewDeliveryChallans />,        
      },  
      {        
        name: "Invoice",
        key: "invoice",
        route: "/invoice",
        component: <Invoice />,        
      },
      {        
        name: "View Invoices",
        key: "viewinvoices",
        route: "/viewinvoices",
        component: <ViewInvoices />,        
      },  
      {        
        name: "LR Details",
        key: "lrdetails",
        route: "/lrdetails",
        component: <LRDetails />,        
      },  
      {        
        name: "View LRs",
        key: "viewlrs",
        route: "/viewlrs",
        component: <ViewLRDetails />,        
      },  
      {        
        name: "View Inspections",
        key: "viewinspections",
        route: "/viewinspections",
        component: <ViewInspections />,        
      },         
    ],
  },
  { type: "divider", key: "divider-9" },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [
      {        
        name: "Employee Report",
        key: "employeereport",
        route: "/reports/employeereport",
        component: <EmployeeReport />,        
      }, 
      {        
        name: "Supplier Report",
        key: "supplierreport",
        route: "/reports/suplierreport",
        component: <SupplierReport />,        
      },
      {        
        name: "Customer Report",
        key: "customerreport",
        route: "/reports/customerreport",
        component: <CustomerReport />,        
      },    
      {        
        name: "Work Order Report",
        key: "workorderreport",
        route: "/reports/workorderreport",
        component: <WorkOrderReport />,        
      }, 
      {        
        name: "Purchase Order Report",
        key: "purchaseorderreport",
        route: "/reports/purchaseorderreport",
        component: <PurchaseOrderReport />,        
      }, 
      {        
        name: "Purchase Order For Work Report",
        key: "purchaseorderforworkreport",
        route: "/reports/purchaseorderforworkreport",
        component: <PurchaseOrderForWorkReport />,        
      },  
      {        
        name: "Invoice Report",
        key: "invoicereport",
        route: "/reports/invoicereport",
        component: <InvoiceReport />,        
      },  
      {        
        name: "Work Allocation Report",
        key: "workallocationreport",
        route: "/reports/workallocationreport",
        component: <WorkAllocationReport />,        
      },  
      {        
        name: "Work Allocation To Supplier Report",
        key: "workallocationtosupplierreport",
        route: "/reports/workallocationtosupplierreport",
        component: <WorkAllocationToSupplierReport />,        
      }, 
      {        
        name: "Work Allocation Status Report",
        key: "workallocationstatusreport",
        route: "/reports/workallocationstatusreport",
        component: <WorkAllocationStatusReport />,        
      },  
      {        
        name: "Rejected Operation Report",
        key: "rejectedoperationreport",
        route: "/reports/rejectedoperationreport",
        component: <RejectedOperationReport />,        
      },  
      {        
        name: "Rejected Operation Of Supplier Report",
        key: "rejectedoperationofsupplierreport",
        route: "/reports/rejectedoperationofsupplierreport",
        component: <RejectedOperationOfSupplierReport />,        
      },  
      {        
        name: "Material Stock Report",
        key: "materialstockreport",
        route: "/reports/materialstockreport",
        component: <MaterialStockReport />,        
      },  
      {        
        name: "Material Issue Report",
        key: "materialissuereport",
        route: "/reports/materialissuereport",
        component: <MaterialIssueReport />,        
      },  
      {        
        name: "Employee Performance Report",
        key: "employeeperformancereport",
        route: "/reports/employeeperformancereport",
        component: <EmployeePerformanceReport />,        
      },  
      {        
        name: "Customer Receipt Report",
        key: "customerreceiptreport",
        route: "/reports/customerreceiptreport",
        component: <CustomerReceiptReport />,        
      }, 
      {        
        name: "Supplier Payment Report",
        key: "supplierpaymentreport",
        route: "/reports/supplierpaymentreport",
        component: <SupplierPaymentReport />,        
      },  
      {        
        name: "Task Status Report",
        key: "taskstatusreport",
        route: "/reports/taskstatusreport",
        component: <TaskStatusReport />,        
      },        
    ],
  },
  { type: "divider", key: "divider-10" },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <Icon fontSize="medium">dashboard</Icon>,   
    collapse: [
      {
        name: "Change Password",
        key: "changepassword",
        route: "/changepassword",
        component: <ChangePassword />,
      },
      {
        name: "Logout",
        key: "logout",
        route: "/authentication/sign-in/basic",
        component: <SignInBasic />,
      },
    ],
  },  
  { type: "divider", key: "divider-11" }, 
];

export default routes;
