
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Select from 'react-select';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import * as service from '../../../../services/service';

function AddEditOperationCriticalParameter() {
    const [state, setState] = useState({ criticalparameter: '' });
    const [productCatData, setProductCatData] = useState([]);
    const [productSubCatData, setProductSubCatData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [partData, setPartData] = useState([]);
    const [subAssemblyData, setSubAssemblyData] = useState([]);
    const [operationData, setOperationData] = useState([]);
    const [productCatId, setProductCatId] = useState('');
    const [productSubCatId, setProductSubCatId] = useState('');
    const [productId, setProductId] = useState('');
    const [subAssemblyId, setSubAssemblyId] = useState('');
    const [partId, setPartId] = useState('');
    const [operationId, setOperationId] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const navigate = useNavigate();
    const params = useParams();
    const getDetailsById = () => {
        setLoading(true);
        const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_OPERATION_CRITICAL_PARAMETER_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        OperationParameterId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setState({ ...state, criticalparameter: result.criticalParameter });
                        const prod = ({ value: result.productCategoryId, label: result.productCategory });
                        setProductCatId(prod);
                        const proc = ({ value: result.productSubCategoryId, label: result.productSubCategory });
                        setProductSubCatId(proc);
                        const pro = ({ value: result.productId, label: result.product });
                        setProductId(pro);
                        if (result.subAssemblyId !== 0) {
                            const assem = ({ value: result.subAssemblyId, label: result.subAssembly });
                            setSubAssemblyId(assem);
                        }
                        if (result.partId !== 0) {
                            const part = ({ value: result.partId, label: result.part });
                            setPartId(part);
                        }
                        const oper = ({ value: result.operationId, label: result.operation });
                        setOperationId(oper);

                        localStorage.setItem('productCategoryId', JSON.stringify(result.productCategoryId));
                        localStorage.setItem('productCategory', JSON.stringify(result.productCategory));
                        localStorage.setItem('productSubCategoryId', JSON.stringify(result.productSubCategoryId));
                        localStorage.setItem('productSubCategory', JSON.stringify(result.productSubCategory));
                        localStorage.setItem('productId', JSON.stringify(result.productId));
                        localStorage.setItem('product', JSON.stringify(result.product));
                        localStorage.setItem('subAssemblyId', JSON.stringify(result.subAssemblyId));
                        localStorage.setItem('subAssembly', JSON.stringify(result.subAssembly));
                        localStorage.setItem('partId', JSON.stringify(result.partId));
                        localStorage.setItem('part', JSON.stringify(result.part));
                        localStorage.setItem('operationId', JSON.stringify(result.operationId));
                        localStorage.setItem('operation', JSON.stringify(result.operation));
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }

                });
        }
        setLoading(false);
    };

    const getProductCategories = () => {
        fetch(
            service.PRODUCT_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.productCategoryId, label: item.productCategory }));
                setProductCatData(data2);
            });
    };
    const getProductSubCategories = (id) => {
        fetch(
            service.PRODUCT_SUB_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductCategoryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.productSubCategoryId, label: item.productSubCategory }));
                setProductSubCatData(data2);
            });
    };

    const getProducts = (catId, subCatId) => {
        fetch(
            service.PRODUCT_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductCategoryId: catId,
                    ProductSubCategoryId: subCatId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.productId, label: item.product }));
                setProductData(data2);
            });
    };
    const getSubAssemblies = (prodId) => {
        fetch(
            service.SUBASSEMBLY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductId: prodId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.subAssemblyId, label: item.subAssembly }));
                setSubAssemblyData(data2);
            });
    };
    const getParts = (assemblyId) => {
        fetch(
            service.PART_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SubAssemblyId: assemblyId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.partId, label: item.part }));
                setPartData(data2);
            });
    };
    const getOperations = (prId, assemblyId, ptId) => {
        fetch(
            service.OPERATION_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductId: prId,
                    SubAssemblyId: assemblyId,
                    PartId: ptId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.operationId, label: item.operation }));
                setOperationData(data2);
            });
    };
    useEffect(() => {
        getProductCategories();
        getProductSubCategories();
        if (params.id > 0) {
            getDetailsById();
        }
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!productCatId) {
            isValid = false;
            errs[".productCategory"] = "Please select product category";
        }
        if (!productSubCatId) {
            isValid = false;
            errs[".productSubcategory"] = "Please select product sub category";
        }
        if (!productId) {
            isValid = false;
            errs[".product"] = "Please select product";
        }
        // if (!subAssemblyId) {
        //     isValid = false;
        //     errs[".subassembly"] = "Please select sub assembly";
        // }
        if (!operationId) {
            isValid = false;
            errs[".operation"] = "Please select operation";
        }
        if (!state.criticalparameter) {
            isValid = false;
            errs[".criticalparameter"] = "Please enter critical parameter";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };
    const productCatChangeHandler = (ev) => {
        setProductCatId(ev);
        setProductSubCatId(null);
        setProductId(null);
        setSubAssemblyId(null);
        setPartId(null);
        setOperationId(null); 
        getProductSubCategories(ev.value);
    }
    const productSubCatChangeHandler = (ev) => {
        setProductSubCatId(ev);
        setProductId(null);
        setSubAssemblyId(null);
        setPartId(null);
        setOperationId(null); 
        getProducts(productCatId.value, ev.value);
    }
    const productChangeHandler = (ev) => {
        setProductId(ev);
        getSubAssemblies(ev.value);
        setPartId(null);
        setOperationId(null); 
        getOperations(ev.value, null, null);
    }
    const subAssemblyChangeHandler = (ev) => {
        setSubAssemblyId(ev);
        setPartId(null);
        setOperationId(null); 
        getParts(ev.value);
        getOperations(productId.value, ev.value, null);
    }
    const partChangeHandler = (ev) => {
        setPartId(ev);        
        setOperationId(null); 
        getOperations(productId.value, subAssemblyId.value, ev.value);
    }
    const operationChangeHandler = (ev) => {
        setOperationId(ev);
    }

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_UPDATE_OPERATION_CRITICAL_PARAMETER,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        OperationParameterId: params.id ? params.id : null,
                        CriticalParameter: state.criticalparameter,
                        OperationId: operationId.value,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/masters/operationcriticalparameter');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/masters/operationcriticalparameter');
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }
    const backHandler = () => {
        navigate('/masters/operationcriticalparameter');
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <MDBox pt={4} pb={3} px={3} style={{ width: '50%' }}>
                                    <MDBox component="form" role="form">
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                            <Select
                                                name="productCategory"
                                                options={productCatData} placeholder="Select Product Category"
                                                value={productCatId}
                                                onChange={productCatChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".productCategory"]}</div>
                                        </MDBox>
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                            <Select
                                                name="productSubCategory"
                                                options={productSubCatData} placeholder="Select Product Sub Category"
                                                value={productSubCatId}
                                                onChange={productSubCatChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".productSubcategory"]}</div>
                                        </MDBox>
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                            <Select
                                                name="product"
                                                options={productData} placeholder="Select Product"
                                                value={productId}
                                                onChange={productChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".product"]}</div>
                                        </MDBox>
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                            <Select
                                                name="subassembly"
                                                options={subAssemblyData} placeholder="Select Sub Assembly"
                                                value={subAssemblyId}
                                                onChange={subAssemblyChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".subassembly"]}</div>
                                        </MDBox>
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                            <Select
                                                name="part"
                                                options={partData} placeholder="Select Part"
                                                value={partId}
                                                onChange={partChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".part"]}</div>
                                        </MDBox>
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                            <Select
                                                name="operation"
                                                options={operationData} placeholder="Select Operation"
                                                value={operationId}
                                                onChange={operationChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".operation"]}</div>
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput label="Critical Parameter" fullWidth value={state.criticalparameter || ''}
                                                name="criticalparameter"
                                                onChange={changeHandler}
                                                id="criticalparameter"
                                                required
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".criticalparameter"]}</div>
                                        </MDBox>
                                        <MDBox mt={4} mb={1} textAlign="center">
                                            {loading ? <Oval
                                                heigth="20"
                                                width="20"
                                                color='grey'
                                                ariaLabel='loading'
                                            /> :
                                                <div>
                                                    <MDButton color="info" onClick={submitHandler}>
                                                        SAVE
                                                    </MDButton>  &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <MDButton color="info" onClick={backHandler}>
                                                        BACK
                                                    </MDButton>
                                                </div>
                                            }
                                            {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddEditOperationCriticalParameter;