
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Select from 'react-select';
import MDInput from "components/MDInput";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { Checkbox } from '@mui/material';
import * as service from '../../services/service';

function AddWorkOrderForMaterial() {
    const [materialCategoryData, setMaterialCategoryData] = useState([]);
    const [materialSubCategoryData, setMaterialSubCategoryData] = useState([]);
    const [materialCategoryId, setMaterialCategoryId] = useState('');
    const [materialSubCategoryId, setMaterialSubCategoryId] = useState('');
    const [materialIds, setMaterialIds] = useState([]);
    const [materialData, setMaterialData] = useState([]);
    const [matRate, setMatRate] = useState([]);
    const [matQuantity, setMatQuantity] = useState([]);
    const [matCGST, setMatCGST] = useState([]);
    const [matSGST, setMatSGST] = useState([]);
    const [matIGST, setMatIGST] = useState([]);
    const [matCGSTPercent, setMatCGSTPercent] = useState([]);
    const [matSGSTPercent, setMatSGSTPercent] = useState([]);
    const [matIGSTPercent, setMatIGSTPercent] = useState([]);
    const [matAmount, setMatAmount] = useState([]);
    const [matTotalAmount, setMatTotalAmount] = useState([]);
    const [isSelected, setIsSelected] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [workOrder, setWorkOrder] = useState([]);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    
    const navigate = useNavigate();
    const params = useParams();
    const getWorkOrderById = () => {        
        fetch(
            service.GET_WORK_ORDER_BY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    WorkOrderId: params.id,
                })
            })
            .then(res => res.json())
            .then(result => {
                setWorkOrder(result);
            });
    };

    const getMaterialCategories = () => {
        fetch(
            service.MATERIAL_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.materialCategoryId, label: item.materialCategory }));
                setMaterialCategoryData(data2);
            });
    };
    const getMaterialSubCategories = (id) => {
        fetch(
            service.MATERIAL_SUB_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    materialCategoryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.materialSubCategoryId, label: item.materialSubCategory }));
                setMaterialSubCategoryData(data2);
            });
    };

    const getMaterials = (id) => {
        fetch(
            service.MATERIAL_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    MaterialCategoryId: materialCategoryId.value,
                    MaterialSubCategoryId: id
                })
            })
            .then(res => res.json())
            .then(result => {
                setMaterialData(result);
                result.forEach((element, index) => {
                    materialIds[index] = element.materialId;
                    setMaterialIds([...materialIds]);
                    isSelected[index] = false;
                    setIsSelected([...isSelected]);
                    matRate[index] = 0;
                    setMatRate([...matRate]);
                    matQuantity[index] = 0;
                    setMatQuantity([...matQuantity]);
                    matCGST[index] = 0;
                    setMatCGST([...matCGST]);
                    matSGST[index] = 0;
                    setMatSGST([...matSGST]);
                    matIGST[index] = 0;
                    setMatIGST([...matIGST]);
                    matAmount[index] = 0;
                    setMatAmount([...matAmount]);
                    matTotalAmount[index] = 0;
                    setMatTotalAmount([...matTotalAmount]);
                });
            })
            .catch(err => {
                if (err) {
                    setErrors(err);
                };
            });
    };
    const CalculateAmountForMaterial = (index, matId) => {
        const rate = matRate[index];
        const quantity = matQuantity[index];
        const selected = isSelected[index];

        if (quantity && rate && selected) {
            const amt = (parseFloat(rate) * parseFloat(quantity)).toFixed(2);

            const filtered = materialData.filter(obj =>
                obj.materialId === matId
            );

            const cgstPer = filtered[0].cgstPercent / 100;
            const sgstPer = filtered[0].sgstPercent / 100;
            const igstPer = filtered[0].igstPercent / 100;

            const sg = parseFloat(amt * sgstPer).toFixed(2);
            const cg = parseFloat(amt * cgstPer).toFixed(2);
            const ig = parseFloat(amt * igstPer).toFixed(2);;
            const total = (parseFloat(amt) + parseFloat(sg) + parseFloat(cg) + parseFloat(ig)).toFixed(2);

            let matCG = matCGST[index];
            matCG = cg;
            matCGST[index] = matCG;
            setMatCGST([...matCGST]);

            let matSG = matSGST[index];
            matSG = sg;
            matSGST[index] = matSG;
            setMatSGST([...matSGST]);

            let matIG = matIGST[index];
            matIG = ig;
            matIGST[index] = matIG;
            setMatIGST([...matIGST]);

            let matAmt = matAmount[index];
            matAmt = amt;
            matAmount[index] = matAmt;
            setMatAmount([...matAmount]);

            let matTAmt = matTotalAmount[index];
            matTAmt = total;
            matTotalAmount[index] = matTAmt;
            setMatTotalAmount([...matTotalAmount]);

            let matCGPer = matCGSTPercent[index];
            matCGPer = cgstPer;
            matCGSTPercent[index] = matCGPer;
            setMatCGSTPercent([...matCGSTPercent]);

            let matSGPer = matSGSTPercent[index];
            matSGPer = sgstPer;
            matSGSTPercent[index] = matSGPer;
            setMatSGSTPercent([...matSGSTPercent]);

            let matIGPer = matIGSTPercent[index];
            matIGPer = igstPer;
            matIGSTPercent[index] = matIGPer;
            setMatIGSTPercent([...matIGSTPercent]);
        }
    }

    useEffect(() => {
        getWorkOrderById();
        getMaterialCategories();
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!materialCategoryId) {
            isValid = false;
            errs[".materialCategoryId"] = "Please select material category";
        }
        if (!materialSubCategoryId) {
            isValid = false;
            errs[".materialSubCategoryId"] = "Please select material sub category";
        }
        if (!isSelected.includes(true)) {
            isValid = false;
            errs[".materialId"] = "Please select material";
        }
        setErrors(errs);
        return isValid;
    }

    const materialCategoryChangeHandler = (ev) => {
        setMaterialCategoryId(ev);
        setMaterialSubCategoryId(null);
        getMaterialSubCategories(ev.value);
        setMaterialData([]);
        setMaterialIds([]);
        setIsSelected([]);
    }
    const materialSubCategoryChangeHandler = (ev) => {
        setMaterialSubCategoryId(ev);
        setMaterialData([]);
        setMaterialIds([]);
        setIsSelected([]);
        getMaterials(ev.value);
    }
    const handleCheckboxChange = (checked, index, matId) => {
        let updatedObject = isSelected[index];
        updatedObject = checked;
        isSelected[index] = updatedObject;
        setIsSelected([...isSelected]);
        CalculateAmountForMaterial(index, matId);
    }

    const matRateChangeHandler = (event, index, matId) => {
        if (Number.isNaN(parseFloat(event.target.value))) {
            // eslint-disable-next-line
            alert("Please enter numbers only");
            event.target.value = ""; // eslint-disable-line no-param-reassign
        }
        else {
            let updatedObject = matRate[index];
            updatedObject = event.target.value;
            matRate[index] = updatedObject;
        }
        setMatRate([...matRate]);
        CalculateAmountForMaterial(index, matId);
    };

    const matQuantityChangeHandler = (event, index, matId) => {
        if (Number.isNaN(parseFloat(event.target.value))) {
            // eslint-disable-next-line
            alert("Please enter numbers only");
            event.target.value = ""; // eslint-disable-line no-param-reassign
        }
        else {
            let updatedObject = matQuantity[index];
            updatedObject = event.target.value;
            matQuantity[index] = updatedObject;
        }
        setMatQuantity([...matQuantity]);
        CalculateAmountForMaterial(index, matId);
    };

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            // eslint-disable-next-line
            console.log(materialIds); // do not remove
            let flag = true;
            materialIds.forEach((element, index) => {
                if (isSelected[index] === true) {
                    fetch(
                        service.ADD_WORK_ORDER_FOR_MATERIAL,
                        {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                WorkOrderId: params.id,
                                MaterialId: element,
                                Rate: matRate[index],
                                Quantity: matQuantity[index],
                                CGST: matCGST[index],
                                SGST: matSGST[index],
                                IGST: matIGST[index],
                                Amount: matAmount[index],
                                TotalAmount: matTotalAmount[index],
                                CGSTPercent: matCGSTPercent[index],
                                SGSTPercent: matSGSTPercent[index],
                                IGSTPercent: matIGSTPercent[index],
                                AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId
                            })
                        })
                        .then(resp => resp.json())
                        .then(respo => {
                            if (respo.messageType === 'Failed') {
                                flag = false;
                                // eslint-disable-next-line
                                alert(respo.message);
                            }
                            else {
                                flag = false;
                                errs[".invalid"] = respo.message.message;
                                setErrors(errs);
                            }
                        });
                }
            });
            if (flag) {
                // eslint-disable-next-line
                alert("Data Saved Successfully");
                window.location.reload(false);
                // navigate('/customer/workorder');

            }
            setLoading(false);
        }
    }
    const backHandler = () => {
        navigate('/customer/workorder');
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                {/* <TableContainer component={Paper} style={{ width: '90%', alignSelf: 'center' }}> */}
                                <Table>
                                    <TableRow>
                                        <TableCell style={{ width: '25%', fontWeight: 'bold' }} align='right'>
                                            Work Order No.
                                        </TableCell>
                                        <TableCell style={{ width: '20%', fontWeight: 'bold' }} align='left'>
                                            {workOrder.workOrderNo}
                                        </TableCell>
                                        <TableCell style={{ width: '25%', fontWeight: 'bold' }} align='right'>
                                            Job No.
                                        </TableCell>
                                        <TableCell style={{ width: '20%', fontWeight: 'bold' }} align='left'>
                                            {workOrder.jobNo}
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align='right' style={{ fontSize: '12px', width: '15%' }}>Material Category :</TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px', width: '25%' }}>
                                                <Select
                                                    options={materialCategoryData}
                                                    name="materialCategory"
                                                    value={materialCategoryId}
                                                    onChange={materialCategoryChangeHandler}
                                                    placeholder="Select Material Category"
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".materialCategoryId"]}</div>
                                            </TableCell>
                                            <TableCell align='right' style={{ fontSize: '12px', width: '20%' }}>Material Sub Category :</TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px', width: '25%' }}>
                                                <Select
                                                    options={materialSubCategoryData}
                                                    name="materialSubCategory"
                                                    value={materialSubCategoryId}
                                                    onChange={materialSubCategoryChangeHandler}
                                                    placeholder="Select Material Sub Category"
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".materialSubCategoryId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                {/* </TableContainer> */}
                                {materialData ?
                                    <Table aria-label="simple table">
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Select</TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Category</TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Sub Category</TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Material</TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>HSN Code</TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Rate</TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Quantity</TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Amount</TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>CGST</TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>SGST</TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>IGST</TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Total Amount</TableCell>
                                        </TableRow>
                                        <TableBody>
                                            {materialData.map((row, index) => (
                                                <TableRow
                                                    key={row.materialId}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell>
                                                        <Checkbox checked={isSelected[index] || false}
                                                            onChange={(e) => handleCheckboxChange(e.target.checked, index, row.materialId)} id="chkSelect" />
                                                    </TableCell>
                                                    <TableCell>{row.materialCategory}</TableCell>
                                                    <TableCell>{row.materialSubCategory}</TableCell>
                                                    <TableCell>{row.material}</TableCell>
                                                    <TableCell>{row.hsnCode}</TableCell>
                                                    <TableCell>
                                                        <MDInput label="Rate" fullWidth value={matRate[index] || ''}
                                                            name="matRate"
                                                            onChange={ev => matRateChangeHandler(ev, index, row.materialId)}
                                                            id="matRate"
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".matRate"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <MDInput label="Quantity" fullWidth value={matQuantity[index] || ''}
                                                            name="matQuantity"
                                                            onChange={ev => matQuantityChangeHandler(ev, index, row.materialId)}
                                                            id="matQuantity"
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".matQuantity"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <MDInput label="Amount" fullWidth value={matAmount[index] || ''}
                                                            name="matAmount"
                                                            id="matAmount"
                                                            disabled
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".matAmount"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <MDInput label="CGST" fullWidth value={matCGST[index] || ''}
                                                            name="matCGST"
                                                            id="matCGST"
                                                            disabled
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".matCGST"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <MDInput label="SGST" fullWidth value={matSGST[index] || ''}
                                                            name="matSGST"
                                                            id="matSGST"
                                                            disabled
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".matSGST"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <MDInput label="IGST" fullWidth value={matIGST[index] || ''}
                                                            name="matIGST"
                                                            id="matIGST"
                                                            disabled
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".matIGST"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <MDInput label="Total" fullWidth value={matTotalAmount[index] || ''}
                                                            name="matTotalAmount"
                                                            id="matTotalAmount"
                                                            disabled
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".matTotalAmount"]}</div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".workOrderId"]}</div>
                                        </TableBody>
                                    </Table>
                                    : null}
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>  &nbsp;&nbsp;&nbsp;&nbsp;
                                            <MDButton color="info" onClick={backHandler}>
                                                BACK
                                            </MDButton>
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>

                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddWorkOrderForMaterial;