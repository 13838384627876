import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
// import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MDInput from "components/MDInput";
import * as moment from 'moment';
import { Radio } from '@mui/material';
import * as service from '../../../../services/service';


function AddPurchaseOrder() {
    const [state, setState] = useState({
        quantity: '', rate: '', amount: '', cgst: '', sgst: '', igst: '', totalamount: '',
        cgstpercent: '', sgstpercent: '', igstpercent: '', hsncode: ''
    });
    const [requisitionData, setRequisitionData] = useState([]);
    const [supplierName, setSupplierName] = useState([]);
    const [supplierId, setSupplierId] = useState('');
    const [requisitionId, setRequisitionId] = useState('');
    const [materialId, setMaterialId] = useState('');
    const [terms, setTerms] = useState('');
    const [purchaseOrderDate, setPurchaseOrderDate] = useState(new Date());
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const getMaterialRequistionsForPO = () => {
        fetch(
            service.GET_MATERIAL_REQUISITION_FOR_PO,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                if (result[0].message.messageType === 'Failed') {
                    setRequisitionData([]);
                }
                else {
                    setRequisitionData(result);
                }
            });
    };

    function calculate(rt, qty, cg, sg, ig, hsn) {
        const amt = (parseFloat(rt) * parseFloat(qty)).toFixed(2);

        const cgstAmt = ((amt * parseFloat(cg)) / 100) === 0 ? '0' : ((amt * parseFloat(cg)) / 100).toFixed(2);
        const sgstAmt = ((amt * parseFloat(sg)) / 100) === 0 ? '0' : ((amt * parseFloat(sg)) / 100).toFixed(2);;
        const igstAmt = ((amt * parseFloat(ig)) / 100) === 0 ? '0' : ((amt * parseFloat(ig)) / 100).toFixed(2);;

        const total = (parseFloat(amt) + parseFloat(cgstAmt) + parseFloat(sgstAmt) + parseFloat(igstAmt)).toFixed(2);;
        setState({
            ...state, rate: rt, quantity: qty, cgst: cgstAmt, sgst: sgstAmt, igst: igstAmt, amount: amt, totalamount: total,
            cgstpercent: cg === 0 ? '0' : cg, sgstpercent: sg === 0 ? '0' : sg,
            igstpercent: ig === 0 ? '0' : ig, hsncode: hsn
        });
    }

    const getTermsByMaterialCategory = (id) => {
        fetch(
            service.GET_MATERIAL_CATEGORY_BY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    MaterialCategoryId: id
                })
            })
            .then(res => res.json())
            .then(result => {
                setTerms(result.terms);                                              
            });
    };

    const getSupplierByMaterial = (id, qty) => {
        fetch(
            service.GET_SUPPLIER_FOR_MATERIAL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    MaterialId: id
                })
            })
            .then(res => res.json())
            .then(result => {
                setSupplierId(result.supplierId);
                setSupplierName(result.supplierName);
                setState({
                    ...state, rate: result.rate === 0 ? '0' : result.rate
                });
                fetch(
                    service.GET_HSNCODE_BY_MATERIAL_ID,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            MaterialId: id
                        })
                    })
                    .then(res => res.json())
                    .then(result1 => {
                        if (result1.message.messageType === 'Failed') {
                            setState({
                                ...state, cgstpercent: '0', sgstpercent: '0',
                                igstpercent: '0', hsncode: 'Not Available'
                            });

                            calculate(result.rate, qty, 0, 0, 0, 'Not Available');
                        }
                        else {
                            setState({
                                ...state, cgstpercent: result1.cgst === 0 ? '0' : result1.cgst,
                                sgstpercent: result1.sgst === 0 ? '0' : result1.sgst,
                                igstpercent: result1.igst === 0 ? '0' : result1.igst, hsncode: result1.hsnCode
                            });

                            calculate(result.rate, qty, result1.cgst, result1.sgst, result1.igst, result1.hsnCode);
                        }
                    });
            });
    };


    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!requisitionId) {
            isValid = false;
            errs[".requisitionId"] = "Please select requistion";
        }
        if (!supplierId) {
            isValid = false;
            errs[".supplierId"] = "Please select supplier";
        }
        if (!purchaseOrderDate) {
            isValid = false;
            errs[".purchaseOrderDate"] = "Please select date";
        }
        if (!state.quantity) {
            isValid = false;
            errs[".quantity"] = "Please enter quantity";
        }
        if (!state.rate) {
            isValid = false;
            errs[".rate"] = "Please enter rate";
        }
        if (!state.amount) {
            isValid = false;
            errs[".amount"] = "Please enter amount";
        }
        if (!state.totalamount) {
            isValid = false;
            errs[".totalamount"] = "Please enter total amount";
        }
        setErrors(errs);
        return isValid;
    }
    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_PURCHASE_ORDER,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        PurchaseOrderDate: moment(purchaseOrderDate).format("MM/DD/YYYY"),
                        SupplierId: supplierId,
                        RequisitionId: requisitionId,
                        MaterialId: materialId,
                        Quantity: state.quantity,
                        Rate: state.rate,
                        Amount: state.amount,
                        CGST: state.cgst ? state.cgst : 0,
                        SGST: state.sgst ? state.sgst : 0,
                        IGST: state.igst ? state.igst : 0,
                        TotalAmount: state.totalamount,
                        CGSTPercent: state.cgstpercent,
                        SGSTPercent: state.sgstpercent,
                        IGSTPercent: state.igstpercent,
                        TermsConditions: terms,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        window.location.reload();
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        getMaterialRequistionsForPO();
        setLoading(false);
    }, []);

    const changeHandler = (event) => {
        if (event.target.name === 'quantity') {
            if (Number.isNaN(parseFloat(event.target.value))) {
                // eslint-disable-next-line
                alert("Please enter numbers only");
                event.target.value = ""; // eslint-disable-line no-param-reassign
            }
        }
        if (event.target.name === 'rate') {
            if (Number.isNaN(parseFloat(event.target.value))) {
                // eslint-disable-next-line
                alert("Please enter numbers only");
                event.target.value = ""; // eslint-disable-line no-param-reassign
            }
        }
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const termChangeHandler = (event) => {
        setTerms(event.target.value);
    }
    const handleRadioChange = (reqId, matId, qty, catId) => {
        setState({ ...state, quantity: qty });
        setRequisitionId(reqId);
        setMaterialId(matId);
        getTermsByMaterialCategory(catId);
        getSupplierByMaterial(matId, qty, catId);
    }

    const backHandler = () => {
        navigate('/supplier/purchaseorder');
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Purchase Order Details
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow>
                                                <TableCell colSpan="7" align='center' style={{ fontWeight: 'bold' }}>
                                                    Material Requisitions
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Requisition No</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Requisition Date</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Material</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>MaterialCode</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Quantity</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Requisition By</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {requisitionData.map((row) => (
                                                    <TableRow
                                                        key={row.requisitionId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell><Radio onChange={() => handleRadioChange(row.requisitionId, row.materialId, row.quantity, row.materialCategoryId)}
                                                            value={row.requisitionId} name="radio-buttons"
                                                            checked={requisitionId === row.requisitionId} />
                                                        </TableCell>
                                                        <TableCell>{row.requisitionNo}</TableCell>
                                                        <TableCell>{moment(row.requisitionDate).format("DD/MM/YYYY")}</TableCell>
                                                        <TableCell>{row.material}</TableCell>
                                                        <TableCell>{row.materialCode}</TableCell>
                                                        <TableCell>{row.quantity}</TableCell>
                                                        <TableCell>{row.requisitionByName}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".requisitionId"]}</div>
                                    </TableContainer>
                                }
                                <br />
                                <br />
                                <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                    <Table aria-label="simple table">
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                HSN Code : {state.hsncode}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', width: '30%' }}>
                                                CGST Percent : {state.cgstpercent}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', width: '30%' }}>
                                                SGST Percent : {state.sgstpercent}
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', width: '30%' }}>
                                                IGST Percent : {state.igstpercent}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '12px' }}>
                                                <MDInput label="Supplier" fullWidth value={supplierName || ''}
                                                    name="supplierName"
                                                    onChange={changeHandler}
                                                    id="supplierName"
                                                    disabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".supplierId"]}</div>
                                            </TableCell>
                                            <TableCell style={{ fontSize: '12px' }}>
                                                Purchase Order Date
                                                <DatePicker
                                                    id="purchaseOrderDate"
                                                    selected={purchaseOrderDate}
                                                    onChange={date => setPurchaseOrderDate(date)}
                                                    dateFormat="MM/dd/yyyy"
                                                    tabIndex={0}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".purchaseOrderDate"]}</div>
                                            </TableCell>
                                            <TableCell style={{ fontSize: '12px' }}>
                                                <MDInput label="Quantity" fullWidth value={state.quantity || ''}
                                                    name="quantity"
                                                    onChange={changeHandler}
                                                    id="quantity"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".quantity"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '12px' }}>
                                                <MDInput label="Rate" fullWidth value={state.rate || ''}
                                                    name="rate"
                                                    onChange={changeHandler}
                                                    id="rate"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".rate"]}</div>
                                            </TableCell>
                                            <TableCell style={{ fontSize: '12px' }}>
                                                <MDInput label="Amount" fullWidth value={state.amount || ''}
                                                    name="amount"
                                                    onChange={changeHandler}
                                                    id="amount"
                                                    disabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".amount"]}</div>
                                            </TableCell>
                                            <TableCell style={{ fontSize: '12px' }}>
                                                <MDInput label="CGST" fullWidth value={state.cgst || ''}
                                                    name="cgst"
                                                    onChange={changeHandler}
                                                    id="cgst"
                                                    disabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".cgst"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '12px' }}>
                                                <MDInput label="SGST" fullWidth value={state.sgst || ''}
                                                    name="sgst"
                                                    onChange={changeHandler}
                                                    id="sgst"
                                                    disabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".sgst"]}</div>
                                            </TableCell>
                                            <TableCell style={{ fontSize: '12px' }}>
                                                <MDInput label="IGST" fullWidth value={state.igst || ''}
                                                    name="igst"
                                                    onChange={changeHandler}
                                                    id="igst"
                                                    disabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".igst"]}</div>
                                            </TableCell>
                                            <TableCell style={{ fontSize: '12px' }}>
                                                <MDInput label="Total Amount" fullWidth value={state.totalamount || ''}
                                                    name="totalamount"
                                                    onChange={changeHandler}
                                                    id="totalamount"
                                                    disabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".totalamount"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '12px' }}>
                                                <MDInput label="Terms and Conditions" fullWidth value={terms || ''}
                                                    name="terms"
                                                    onChange={termChangeHandler}
                                                    id="terms"
                                                    multiline
                                                   // InputProps={{class: {whiteSpace: ''}}}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".terms"]}</div>
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                </TableContainer>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>  &nbsp;&nbsp;&nbsp;&nbsp;
                                            <MDButton color="info" onClick={backHandler}>
                                                BACK
                                            </MDButton>
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default AddPurchaseOrder;