
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import * as service from '../../../services/service';

function AddEditEmployee() {
    const [state, setState] = useState({
        firstname: '', middlename: '', lastname: '', employeecode: '', address: '',
        emailid: '', contactno: '', adharcardno: '', password: ''
    });
    const [birthDate, setBirthDate] = useState(new Date());
    const [designationId, setDesignationId] = useState('');
    const [designationData, setDesignationData] = useState([]);
    const [active, setActive] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const statusData = [{ label: 'Active', value: true }, { label: 'Inactive', value: false }]

    const navigate = useNavigate();
    const params = useParams();
    const getDesignations = () => {
        fetch(
            service.DESIGNATION_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.designationId, label: item.designation }));
                setDesignationData(data2);
            });
    };
    const getDetailsById = () => {
        setLoading(true);
        const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_EMPLOYEE_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        EmployeeId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setState({
                            ...state, firstname: result.firstName, middlename: result.middleName, lastname: result.lastName, employeecode: result.employeeCode,
                            address: result.address, emailid: result.emailId, contactno: result.contactNo, adharcardno: result.adharCardNo, password: result.password
                        });
                        setBirthDate(new Date(result.birthDate));
                        const desData = ({ value: result.designationId, label: result.designation });
                        setDesignationId(desData);
                        const stData = ({ value: result.isActive, label: result.isActive ? 'Active' : 'Inactive' });
                        setActive(stData);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }

                });
        }
        setLoading(false);
    };

    useEffect(() => {
        getDesignations();
        if (params.id > 0) {
            getDetailsById();
        }
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!state.employeecode) {
            isValid = false;
            errs[".employeecode"] = "Please enter employee code";
        }
        if (!state.firstname) {
            isValid = false;
            errs[".firstname"] = "Please enter first name";
        }
        if (!state.lastname) {
            isValid = false;
            errs[".lastname"] = "Please enter last name";
        }
        if (!designationId) {
            isValid = false;
            errs[".designation"] = "Please select designation";
        }
        if (!state.emailid) {
            isValid = false;
            errs[".emailId"] = "Please enter emailId";
        }
        if (!active) {
            isValid = false;
            errs[".status"] = "Please select status";
        }
        // if (state.emailid !== "undefined") {
        //     const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        //     if (!pattern.test(state.emailid)) {
        //       isValid = false;
        //       errs[".emailid"] = "Please enter valid email address";
        //     }
        //   }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_UPDATE_EMPLOYEE,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        EmployeeId: params.id ? params.id : null,
                        FirstName: state.firstname,
                        MiddleName: state.middlename,
                        LastName: state.lastname,
                        EmployeeCode: state.employeecode,
                        Address: state.address,
                        EmailId: state.emailid,
                        Password: state.password ? state.password : `${state.firstname}@123`,
                        ContactNo: state.contactno,
                        BirthDate: moment(birthDate).format("MM/DD/YYYY"),
                        DesignationId: designationId.value,
                        AdharCardNo: state.adharcardno,
                        IsActive: active.value,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/employee/employee');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/employee/employee');
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }
    const backHandler = () => {
        navigate('/employee/employee');
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                {/* <TableContainer component={Paper}  style={{width: '80%', alignSelf: 'center',}}> */}
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="First Name" fullWidth value={state.firstname || ''}
                                                    name="firstname"
                                                    onChange={changeHandler}
                                                    id="firstname"
                                                    required />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstname"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Middle Name" fullWidth value={state.middlename || ''}
                                                    name="middlename"
                                                    onChange={changeHandler}
                                                    id="middlename"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".middlename"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Last Name" fullWidth value={state.lastname || ''}
                                                    name="lastname"
                                                    onChange={changeHandler}
                                                    id="lastname"
                                                    required />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lastname"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Employee Code" fullWidth value={state.employeecode || ''}
                                                    name="employeecode"
                                                    onChange={changeHandler}
                                                    id="employeecode"
                                                    required />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".employeecode"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Dropdown menuClassName='myMenuClassName' id="designation"
                                                    name="designation"
                                                    options={designationData} placeholder="Select Designation"
                                                    value={designationId}
                                                    onChange={e => setDesignationId(e)}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".designation"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Email" fullWidth value={state.emailid || ''}
                                                    name="emailid"
                                                    type="email"
                                                    onChange={changeHandler}
                                                    id="emailid"
                                                    required
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".emailId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Contact No" fullWidth value={state.contactno || ''}
                                                    name="contactno"
                                                    onChange={changeHandler}
                                                    id="contactno"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".contactno"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Address" fullWidth value={state.address || ''}
                                                    name="address"
                                                    onChange={changeHandler}
                                                    id="address"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".address"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Adhar Card No" fullWidth value={state.adharcardno || ''}
                                                    name="adharcardno"
                                                    onChange={changeHandler}
                                                    id="adharcardno"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".adharcardno"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Birth Date
                                                <DatePicker
                                                    id="birthDate"
                                                    selected={birthDate}
                                                    onChange={date => setBirthDate(date)}
                                                    dateFormat="MM/dd/yyyy"
                                                    tabIndex={0}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Dropdown menuClassName='myMenuClassName' id="status"
                                                    name="status"
                                                    options={statusData} placeholder="Select Status"
                                                    value={active}
                                                    onChange={e => setActive(e)}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".status"]}</div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                {/* </TableContainer> */}
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>  &nbsp;&nbsp;&nbsp;&nbsp;
                                            <MDButton color="info" onClick={backHandler}>
                                                BACK
                                            </MDButton>
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>

                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddEditEmployee;