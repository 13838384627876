/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import AddEditBank from "layouts/masters/bank/Transaction";
import AddEditBranch from "layouts/masters/branch/Transaction"; 
import AddEditState from "layouts/masters/state/Transaction";
import AddEditDistrict from "layouts/masters/district/Transaction";
import AddEditDesignation from "layouts/masters/designation/Transaction";
import AddEditProductCategory from "layouts/masters/productcategory/Transaction";
import AddEditProductSubCategory from "layouts/masters/productsubcategory/Transaction";
import AddEditProduct from "layouts/masters/product/Transaction";
import AddEditSubAssembly from "layouts/masters/subassembly/Transaction";
import AddEditPart from "layouts/masters/part/Transaction";
import AddEditOperation from "layouts/masters/operation/Transaction";
import AddEditMaterialCategory from "layouts/masters/materialcategory/Transaction";
import AddEditMaterialSubCategory from "layouts/masters/materialsubcategory/Transaction";
import AddEditMaterial from "layouts/masters/material/Transaction";
import AddEditMachine from "layouts/masters/machine/Transaction";
import AddEditSupplierCategory from "layouts/masters/suppliercategory/Transaction"; 
import AddEditSupplier from "layouts/supplier/Transaction";
import AddEditEmployee from "layouts/employee/Transaction";
import AddEditCustomer from "layouts/customer/Transaction";
import AddEditOperationCriticalParameter from "layouts/masters/operationcriticalparameter/Transaction";
import AddEditOperationInspectionParameter from "layouts/masters/operationinspectionparameter/Transaction";
import AddEditWorkAllocation from "layouts/workallocation/Transaction";
import AddEditWorkOrder from "layouts/workorder/Transaction";
import ViewStock from "layouts/workorder/Transaction/viewstock";
import AddPurchaseOrder from "layouts/supplier/purchaseorder/Transaction";
import AddEditTaskCategory from "layouts/masters/taskcategory/Transaction";
import AddEditTask from "layouts/masters/tasklist/Transaction";
import AddEditTaskAllocation from "layouts/taskallocation/Transaction";
import AddEditWorkAllocationToSupplier from "layouts/workallocationtosupplier/Transaction";
import AddPurchaseOrderForWork from "layouts/supplier/purchaseorderforwork/Transaction";
import AddEditPreDeliveryInspectionCriteria from "layouts/masters/predeliveryinspectioncriteria/Transaction";
import AddEditHSNCode from "layouts/masters/hsncodes/Transaction";
import PrintDeliveryChallan from "layouts/deliverychallan/printdeliverychallan";
import PrintInvoice from "layouts/invoice/printinvoice";
import PrintLR from "layouts/lrdetails/printlr";
import AddWorkOrderForMaterial from "layouts/workorder/workorderformaterial";
import AddEditInspectionCheckList from "layouts/masters/inspectionchecklist/Transaction";
import PrintPurchaseOrder from "layouts/supplier/purchaseorder/printpurchaseorder";
import PrintPurchaseOrderForWork from "layouts/supplier/purchaseorderforwork/printpurchaseorderforwork";
import PrintSupplierChallan from "layouts/supplier/purchaseorderforwork/printsupplierchallan";
import PrintInspection from "layouts/inspectioncommissioning/printinspection";
import AddCustomerReceipt from "layouts/customerreceipts/Transaction";
import AddSupplierPayment from "layouts/supplier/supplierpayment/Transaction";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // Check authentication
  const userData = sessionStorage.getItem('userData');
  
  useEffect(() => {
    if(userData == null){
      navigate('/authentication/sign-in/basic');    
    }
  }, []);
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  // const configsButton = (
  //   <MDBox
  //     display="flex"
  //     justifyContent="center"
  //     alignItems="center"
  //     width="3.25rem"
  //     height="3.25rem"
  //     bgColor="white"
  //     shadow="sm"
  //     borderRadius="50%"
  //     position="fixed"
  //     right="2rem"
  //     bottom="2rem"
  //     zIndex={99}
  //     color="dark"
  //     sx={{ cursor: "pointer" }}
  //     onClick={handleConfiguratorOpen}
  //   >
  //     <Icon fontSize="small" color="inherit">
  //       settings
  //     </Icon>
  //   </MDBox>
  // );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Shri Krishna OAS"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {/* {configsButton} */}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/dashboards/analytics" />} />
          <Route path="/addeditbank/:id" element={<AddEditBank />} />
          <Route path="/addeditbranch/:id" element={<AddEditBranch />} />    
          <Route path="/addeditstate/:id" element={<AddEditState />} />  
          <Route path="/addeditdistrict/:id" element={<AddEditDistrict />} /> 
          <Route path="/addeditdesignation/:id" element={<AddEditDesignation />} /> 
          <Route path="/addeditproductcategory/:id" element={<AddEditProductCategory />} /> 
          <Route path="/addeditproductsubcategory/:id" element={<AddEditProductSubCategory />} /> 
          <Route path="/addeditproduct/:id" element={<AddEditProduct />} /> 
          <Route path="/addeditsubassembly/:id" element={<AddEditSubAssembly />} /> 
          <Route path="/addeditpart/:id" element={<AddEditPart />} /> 
          <Route path="/addeditoperation/:id" element={<AddEditOperation />} /> 
          <Route path="/addeditmaterialcategory/:id" element={<AddEditMaterialCategory />} /> 
          <Route path="/addeditmaterialsubcategory/:id" element={<AddEditMaterialSubCategory />} /> 
          <Route path="/addeditmaterial/:id" element={<AddEditMaterial />} /> 
          <Route path="/addeditmachine/:id" element={<AddEditMachine />} /> 
          <Route path="/addeditsuppliercategory/:id" element={<AddEditSupplierCategory />} /> 
          <Route path="/addeditsupplier/:id" element={<AddEditSupplier />} /> 
          <Route path="/addeditemployee/:id" element={<AddEditEmployee />} />   
          <Route path="/addeditcustomer/:id" element={<AddEditCustomer />} />   
          <Route path="/addeditoperationcriticalparameter/:id" element={<AddEditOperationCriticalParameter />} />   
          <Route path="/addeditoperationinspectionparameter/:id" element={<AddEditOperationInspectionParameter />} />   
          <Route path="/addeditworkallocation/:id" element={<AddEditWorkAllocation />} />   
          <Route path="/addeditworkorder/:id" element={<AddEditWorkOrder />} />  
          <Route path="/viewstock/:id" element={<ViewStock />} />   
          <Route path="/addpurchaseorder/:id" element={<AddPurchaseOrder />} />   
          <Route path="/addedittaskcategory/:id" element={<AddEditTaskCategory />} />   
          <Route path="/addedittask/:id" element={<AddEditTask />} />   
          <Route path="/addedittaskallocation/:id" element={<AddEditTaskAllocation />} />   
          <Route path="/addeditworkallocationtosupplier/:id" element={<AddEditWorkAllocationToSupplier />} />   
          <Route path="/addpurchaseorderforwork/:id" element={<AddPurchaseOrderForWork />} />   
          <Route path="/addeditpredeliveryinspectioncriteria/:id" element={<AddEditPreDeliveryInspectionCriteria />} />   
          <Route path="/addedithsncode/:id" element={<AddEditHSNCode />} />   
          <Route path="/printdeliverychallan/:id" element={<PrintDeliveryChallan />} />   
          <Route path="/printinvoice/:id" element={<PrintInvoice />} />   
          <Route path="/printlr/:id" element={<PrintLR />} />   
          <Route path="/workorderformaterial/:id" element={<AddWorkOrderForMaterial />} />   
          <Route path="/addeditinspectionchecklist/:id" element={<AddEditInspectionCheckList />} />  
          <Route path="/printpurchaseorder/:id" element={<PrintPurchaseOrder />} />   
          <Route path="/printpurchaseorderforwork/:id" element={<PrintPurchaseOrderForWork />} /> 
          <Route path="/printsupplierchallan/:id" element={<PrintSupplierChallan />} />   
          <Route path="/printinspection/:id" element={<PrintInspection />} />  
          <Route path="/addcustomerreceipt" element={<AddCustomerReceipt />} /> 
          <Route path="/addsupplierpayment" element={<AddSupplierPayment />} />        
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Shri Krishna OAS"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {/* {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboards/analytics" />} />
        <Route path="/addeditbank/:id" element={<AddEditBank />} />
        <Route path="/addeditbranch/:id" element={<AddEditBranch />} />    
          <Route path="/addeditstate/:id" element={<AddEditState />} />  
          <Route path="/addeditdistrict/:id" element={<AddEditDistrict />} /> 
          <Route path="/addeditdesignation/:id" element={<AddEditDesignation />} /> 
          <Route path="/addeditproductcategory/:id" element={<AddEditProductCategory />} /> 
          <Route path="/addeditproductsubcategory/:id" element={<AddEditProductSubCategory />} /> 
          <Route path="/addeditproduct/:id" element={<AddEditProduct />} /> 
          <Route path="/addeditsubassembly/:id" element={<AddEditSubAssembly />} /> 
          <Route path="/addeditpart/:id" element={<AddEditPart />} /> 
          <Route path="/addeditoperation/:id" element={<AddEditOperation />} /> 
          <Route path="/addeditmaterialcategory/:id" element={<AddEditMaterialCategory />} /> 
          <Route path="/addeditmaterialsubcategory/:id" element={<AddEditMaterialSubCategory />} /> 
          <Route path="/addeditmaterial/:id" element={<AddEditMaterial />} /> 
          <Route path="/addeditmachine/:id" element={<AddEditMachine />} /> 
          <Route path="/addeditsuppliercategory/:id" element={<AddEditSupplierCategory />} /> 
          <Route path="/addeditsupplier/:id" element={<AddEditSupplier />} /> 
          <Route path="/addeditemployee/:id" element={<AddEditEmployee />} />
          <Route path="/addeditcustomer/:id" element={<AddEditCustomer />} />  
          <Route path="/addeditoperationcriticalparameter/:id" element={<AddEditOperationCriticalParameter />} />         
          <Route path="/addeditoperationinspectionparameter/:id" element={<AddEditOperationInspectionParameter />} />   
          <Route path="/addeditworkallocation/:id" element={<AddEditWorkAllocation />} />  
          <Route path="/addeditworkorder/:id" element={<AddEditWorkOrder />} />
          <Route path="/viewstock/:id" element={<ViewStock />} />       
          <Route path="/addpurchaseorder/:id" element={<AddPurchaseOrder />} /> 
          <Route path="/addedittaskcategory/:id" element={<AddEditTaskCategory />} />   
          <Route path="/addedittask/:id" element={<AddEditTask />} />     
          <Route path="/addedittaskallocation/:id" element={<AddEditTaskAllocation />} />   
          <Route path="/addeditworkallocationtosupplier/:id" element={<AddEditWorkAllocationToSupplier />} />
          <Route path="/addpurchaseorderforwork/:id" element={<AddPurchaseOrderForWork />} /> 
          <Route path="/addeditpredeliveryinspectioncriteria/:id" element={<AddEditPreDeliveryInspectionCriteria />} />
          <Route path="/addedithsncode/:id" element={<AddEditHSNCode />} />    
          <Route path="/printdeliverychallan/:id" element={<PrintDeliveryChallan />} />   
          <Route path="/printinvoice/:id" element={<PrintInvoice />} />     
          <Route path="/printlr/:id" element={<PrintLR />} />    
          <Route path="/workorderformaterial/:id" element={<AddWorkOrderForMaterial />} />  
          <Route path="/addeditinspectionchecklist/:id" element={<AddEditInspectionCheckList />} />  
          <Route path="/printpurchaseorder/:id" element={<PrintPurchaseOrder />} />  
          <Route path="/printpurchaseorderforwork/:id" element={<PrintPurchaseOrderForWork />} />  
          <Route path="/printsupplierchallan/:id" element={<PrintSupplierChallan />} />    
          <Route path="/printinspection/:id" element={<PrintInspection />} />    
          <Route path="/addcustomerreceipt" element={<AddCustomerReceipt />} />       
          <Route path="/addsupplierpayment" element={<AddSupplierPayment />} />       
      </Routes>
    </ThemeProvider>
  );
}
