import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
// import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MDInput from "components/MDInput";
import * as moment from 'moment';
import Select from 'react-select';
import * as service from '../../../../services/service';

function AddSupplierPayment() {
    const [state, setState] = useState({ paidamount: '', utrcode: '' });
    const [supplierData, setSupplierData] = useState([]);
    const [poData, setPOData] = useState([]);
    const [supplierId, setSupplierId] = useState('');
    const [poId, setPOId] = useState('');
    const [poFor, setPOFor] = useState('');
    const poForData = [{ label: 'Work', value: 'Work' }, { label: 'Material', value: 'Material' }]
    const [billData, setBillData] = useState([]);
    const [billId, setBillId] = useState('');
    const [poCompelteData, setPOCompleteData] = useState([]);
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [poDate, setPODate] = useState('');
    const [poAmount, setPOAmount] = useState('');
    const [balance, setBalance] = useState('');
    const [bankData, setBankData] = useState([]);
    const [branchData, setBranchData] = useState([]);
    const [bankId, setBankId] = useState('');
    const [branchId, setBranchId] = useState('');
    const [paymentMode, setPaymentMode] = useState('');
    const paymentModeData = [{ label: 'Cash', value: 'Cash' }, { label: 'Cheque', value: 'Cheque' },
    { label: 'Bank Transfer', value: 'Bank Transfer' }]
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const navigate = useNavigate();
    const getSuppliers = () => {
        fetch(
            service.SUPPLIER_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.supplierId, label: item.supplierName }));
                setSupplierData(data2);
            });
    };

    const getPurchaseOrders = (id) => {
        fetch(
            service.GET_PURCHASE_ORDER_BY_TYPE,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    PurchaseOrderFor: poFor.value,
                    SupplierId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                setPOCompleteData(result);
                const data2 = result.map((item) => ({ value: item.purchaseOrderId, label: item.purchaseOrderNo }));
                setPOData(data2);
            });
    };

    const getBillNos = (id) => {
        fetch(
            service.GET_BILL_NO,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    PurchaseOrderId: id
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.billNo, label: item.billNo }));
                setBillData(data2);
            });
    };

    const getBalance = (id) => {
        fetch(
            service.GET_SUPPLIER_PAYMENT_BALANCE,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    PurchaseOrderFor: poFor.value,
                    PurchaseOrderId: id
                })
            })
            .then(res => res.json())
            .then(result => {
               setBalance(result.balance);
            });
    };

    const getBanks = () => {
        fetch(
            service.BANK_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.bankId, label: item.bank }));
                setBankData(data2);
            });
    };

    const getBranch = (id) => {
        fetch(
            service.GET_BRANCH_BY_BANK_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    BankId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.branchId, label: item.branch }));
                setBranchData(data2);
            });
    };

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!poFor) {
            isValid = false;
            errs[".poFor"] = "Please select for";
        }
        if (!supplierId) {
            isValid = false;
            errs[".supplierId"] = "Please select supplier";
        }
        if (!poId) {
            isValid = false;
            errs[".poId"] = "Please select PO no";
        }
        if (!paymentDate) {
            isValid = false;
            errs[".paymentDate"] = "Please select date";
        }
        if (!state.paidamount) {
            isValid = false;
            errs[".paidamount"] = "Please enter amount";
        }
        if (!paymentMode) {
            isValid = false;
            errs[".paymentMode"] = "Please select payment mode";
        }
        if (paymentMode && paymentMode.value !== "Cash" && !bankId) {
            isValid = false;
            errs[".bankId"] = "Please select bank";
        }
        if (paymentMode && paymentMode.value !== "Cash" && !branchId) {
            isValid = false;
            errs[".branchId"] = "Please select branch";
        }
        if (paymentMode && paymentMode.value !== "Cash" && !state.utrcode) {
            isValid = false;
            errs[".utrcode"] = "Please enter UTR Code";
        }

        setErrors(errs);
        return isValid;
    }
    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_SUPPLIER_PAYMENT,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        PurchaseOrderFor: poFor.value,
                        PurchaseOrderId: poId.value,
                        PaymentDate: moment(paymentDate).format("MM/DD/YYYY"),
                        PaidAmount: state.paidamount,
                        PaymentMode: paymentMode.value,
                        BankId: bankId ? bankId.value : 0,
                        BranchId: branchId ? branchId.value : 0,
                        UTRCode: state.utrcode,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/supplier/supplierpayments');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }
    const backHandler = () => {
        navigate('/supplier/supplierpayments');
    }

    useEffect(() => {
        setLoading(true);
        getSuppliers();
        getBanks();
        setLoading(false);
    }, []);

    const poForChangeHandler = (ev) => {
        setPOFor(ev);
        setSupplierId(null);
        setPOData(null);
    }

    const supplierChangeHandler = (ev) => {
        setSupplierId(ev);
        setPOId(null);
        getPurchaseOrders(ev.value);
        setBillData([]);
    }
    const poChangeHandler = (ev) => {
        setPOId(ev);
        getBillNos(ev.value);
        getBalance(ev.value);
        const inv = poCompelteData.find(element => element.purchaseOrderId === ev.value)        
        setPODate(moment(new Date(inv.purchaseOrderDate)).format('DD/MM/YYYY'));
        setPOAmount(inv.totalAmount);        
    }

    const billChangeHandler = (ev) => {
        setBillId(ev);
    }
    const paymentModeChangeHandler = (ev) => {
        setPaymentMode(ev);
    }
    const bankChangeHandler = (ev) => {
        setBankId(ev);
        setBranchId(null);
        getBranch(ev.value);
    }
    const branchChangeHandler = (ev) => {
        setBranchId(ev);
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Supplier Payment
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <TableContainer component={Paper} style={{ width: '95%', height: '500px', alignSelf: 'center', }}>
                                    <Table aria-label="simple table">
                                        <TableRow>
                                            <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                                <Select
                                                    name="poFor"
                                                    options={poForData} placeholder="Select For"
                                                    value={poFor}
                                                    onChange={poForChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".poFor"]}</div>
                                            </TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                                <Select
                                                    name="supplier"
                                                    options={supplierData} placeholder="Select Supplier"
                                                    value={supplierId}
                                                    onChange={supplierChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".supplierId"]}</div>
                                            </TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                                <Select
                                                    name="po"
                                                    options={poData} placeholder="Select PO"
                                                    value={poId}
                                                    onChange={poChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                                <MDInput label="PO Date" fullWidth value={poDate}
                                                    name="poDate"
                                                    id="poDate"
                                                    disabled
                                                />
                                            </TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                                <MDInput label="PO Amount" fullWidth value={poAmount}
                                                    name="poamount"
                                                    id="poamount"
                                                    disabled
                                                />
                                            </TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                                <Select
                                                    name="billId"
                                                    options={billData} placeholder="Select Bill"
                                                    value={billId}
                                                    onChange={billChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".billId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='left' style={{ fontSize: '12px' }}>
                                                Payment Date
                                                <DatePicker
                                                    id="paymentDate"
                                                    selected={paymentDate}
                                                    onChange={date => setPaymentDate(date)}
                                                    dateFormat="MM/dd/yyyy"
                                                    tabIndex={0}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".paymentDate"]}</div>
                                            </TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px' }}>
                                                <MDInput label="Paid Amount" fullWidth value={state.paidamount || ''}
                                                    name="paidamount"
                                                    onChange={changeHandler}
                                                    id="paidamount"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".paidamount"]}</div>
                                            </TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px' }}>
                                                <Select
                                                    name="paymentMode"
                                                    options={paymentModeData} placeholder="Select Payment Mode"
                                                    value={paymentMode}
                                                    onChange={paymentModeChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".paymentMode"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='left' style={{ fontSize: '12px' }}>
                                                <Select
                                                    name="bankId"
                                                    options={bankData} placeholder="Select Bank"
                                                    value={bankId}
                                                    onChange={bankChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".bankId"]}</div>
                                            </TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px' }}>
                                                <Select
                                                    name="branchId"
                                                    options={branchData} placeholder="Select Branch"
                                                    value={branchId}
                                                    onChange={branchChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".branchId"]}</div>
                                            </TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px' }}>
                                                <MDInput label="UTR Code" fullWidth value={state.utrcode || ''}
                                                    name="utrcode"
                                                    onChange={changeHandler}
                                                    id="utrcode"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".utrcode"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                                <MDInput label="Balance" fullWidth value={balance}
                                                    name="balance"
                                                    id="balance"
                                                    disabled
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                </TableContainer>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>  &nbsp;&nbsp;&nbsp;&nbsp;
                                            <MDButton color="info" onClick={backHandler}>
                                                BACK
                                            </MDButton>
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default AddSupplierPayment;