import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import * as moment from 'moment';
import Select from 'react-select';
import * as service from '../../../services/service';

function SupplierPayments() {
    const [data, setData] = useState([]);
    const [supplierData, setSupplierData] = useState([]);
    const [poData, setPOData] = useState([]);
    const [supplierId, setSupplierId] = useState('');
    const [poId, setPOId] = useState('');
    const [poFor, setPOFor] = useState('');
    const poForData = [{ label: 'Work', value: 'Work' }, { label: 'Material', value: 'Material' }]
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const userDesignation = JSON.parse(sessionStorage.getItem('userData')).designation;
    const navigate = useNavigate();

    const getSuppliers = () => {
        fetch(
            service.SUPPLIER_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.supplierId, label: item.supplierName }));
                setSupplierData(data2);
            });
    };

    const getPurchaseOrders = (id) => {
        fetch(
            service.GET_PURCHASE_ORDER_BY_TYPE,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    PurchaseOrderFor: poFor.value,
                    SupplierId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.purchaseOrderId, label: item.purchaseOrderNo }));
                setPOData(data2);
            });
    };

    const getDetails = (id) => {
        fetch(
            service.GET_SUPPLIER_PAYMENT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    PurchaseOrderFor: poFor.value,
                    SupplierId: supplierId.value,
                    PurchaseOrderId: id,
                })
            })
            .then(res => res.json())
            .then(result => {                
                setData(result);
            })
            .catch(err => {
                if (err) {
                    setError(err);
                };
            });
    };

    const handleDelete = (id) => {
        setLoading(true);
        // eslint-disable-next-line
        if (window.confirm("Do you want to delete this details?")) {
            fetch(
                service.DELETE_SUPPLIER_PAYMENT_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        PaymentId: id
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                });
            window.location.reload(false);
        }
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        getSuppliers();
        setLoading(false);
    }, []);

    const poForChangeHandler = (ev) => {
        setPOFor(ev);
        setSupplierId(null);
        setPOData(null);
        setData([]);
    }

    const supplierChangeHandler = (ev) => {
        setSupplierId(ev);
        setPOData(null);
        getPurchaseOrders(ev.value);
        setData([]);
    }
    const poChangeHandler = (ev) => {
        setPOId(ev);
        getDetails(ev.value);
    }

    const addNew = () => {
        navigate('/addsupplierpayment');
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {error
                ? <MDAlert color="warning" dismissible>{error}</MDAlert>
                : null
            }
            {userDesignation === 'General Manager' || userDesignation === 'Manager - Accounts'
                || userDesignation === 'Sr Mgr - Accounts' || userDesignation === 'MD' 
                || userDesignation === 'Jr Mgr - Accounts' || userDesignation === 'Asst Mgr - Accounts' ?
                <div style={{ float: 'right' }}>
                    <MDButton color="info" onClick={addNew}>
                        ADD NEW
                    </MDButton>
                </div>
                : null}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Supplier Payments
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <Table style={{ width: '80%' }}>
                                    <TableRow>
                                        <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                            <Select
                                                name="poFor"
                                                options={poForData} placeholder="Select For"
                                                value={poFor}
                                                onChange={poForChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                            <Select
                                                name="supplier"
                                                options={supplierData} placeholder="Select Supplier"
                                                value={supplierId}
                                                onChange={supplierChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                            <Select
                                                name="po"
                                                options={poData} placeholder="Select PO"
                                                value={poId}
                                                onChange={poChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                <br />
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>PO For</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>PO No</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>PO Amount</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Payment Date</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Paid Amount</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Payment Mode</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>UTRCode</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Bank</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Branch</TableCell>
                                                {userDesignation === 'General Manager' || userDesignation === 'Manager - Accounts'
                                                    || userDesignation === 'Sr Mgr - Accounts' ?
                                                    <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                                                    : null}
                                            </TableRow>
                                            <TableBody>
                                                {data.map((row) => (
                                                    <TableRow
                                                        key={row.paymentId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{row.purchaseOrderFor}</TableCell>
                                                        <TableCell>{row.purchaseOrderNo}</TableCell>
                                                        <TableCell>{row.purchaseAmount}</TableCell>
                                                        <TableCell>{moment(row.paymentDate).format("DD/MM/YYYY")}</TableCell>
                                                        <TableCell>{row.paidAmount}</TableCell>
                                                        <TableCell>{row.paymentMode}</TableCell>
                                                        <TableCell>{row.utrCode}</TableCell>
                                                        <TableCell>{row.bank}</TableCell>
                                                        <TableCell>{row.branch}</TableCell>
                                                        {userDesignation === 'General Manager' || userDesignation === 'Manager - Accounts'
                                                            || userDesignation === 'Sr Mgr - Accounts' ?
                                                            <TableCell><MDButton onClick={() => handleDelete(row.paymentId)}>Delete</MDButton></TableCell>
                                                            : null}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default SupplierPayments;