import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import 'react-dropdown/style.css';
// import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import * as moment from 'moment';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
// import { jsPDF } from 'jspdf';
// import html2canvas from "html2canvas";
import * as service from '../../services/service';

function CustomerReport() {
    const [reportDetailsData, setReportDetailsData] = useState([]);
    const [showDetailsReport, setShowDetailsReport] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const userDesignation = JSON.parse(sessionStorage.getItem('userData')).designation;

    function GetReport() {
        const errs = {};
        fetch(
            service.GET_CUSTOMER_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowDetailsReport(true);
                    setReportDetailsData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
            });
    }

    useEffect(() => {
        setLoading(true);
        GetReport();
        setLoading(false);
    }, []);

    // const ShowDetailsReport = React.memo(() => (        
    //     <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>

    //     </TableContainer>          

    // ));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Customer Reports
                                </MDTypography>
                            </MDBox>
                            <br />
                            {userDesignation === 'General Manager' || userDesignation === 'Asst Mgr - Marketing'
                                || userDesignation === 'Jr Mgr - Marketing' || userDesignation === 'Manager - Marketing'
                                || userDesignation === 'Sr Mgr - Marketing' || userDesignation === 'MD' || userDesignation === 'Assistant - Marketing' ?
                                <Card style={{ alignItems: 'center' }}>
                                    {loading ?
                                        <Oval
                                            heigth="100"
                                            width="100"
                                            color='grey'
                                            ariaLabel='loading'
                                        /> :
                                        <TableContainer component={Paper} style={{ width: '98%', alignSelf: 'center' }}>
                                            <Table id="dvDetailReport">
                                                <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Customer</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Category</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>State</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>District</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Contact Person</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Contact No</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>PAN</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>GSTNo</TableCell>
                                                </TableRow>
                                                {reportDetailsData.map((row) => (
                                                    <TableRow
                                                        key={row.customerId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell style={{ fontSize: '14px' }}>{row.customerName}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.customerCategory}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.state}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.district}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.contactPerson}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.contactNo}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.pan}</TableCell>
                                                        <TableCell style={{ fontSize: '14px' }}>{row.gstNo}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </Table>
                                            <br />
                                            <Table>
                                                <TableRow>
                                                    <TableCell align="center">
                                                        {showDetailsReport ?
                                                            <ReactHTMLTableToExcel table="dvDetailReport" className="info"
                                                                filename="ReportData" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                                                            />
                                                            : null}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>{errors[".invalid"]}</TableCell>
                                                </TableRow>
                                            </Table>
                                        </TableContainer>
                                    }
                                </Card>
                                :
                                <Card style={{ alignItems: 'center' }}>
                                    <div style={{ fontWeight: 'bold', fontSize: '14px', color: 'red' }}>You do not have access to this page</div>
                                </Card>
                            }
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default CustomerReport;
