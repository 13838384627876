
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Select from 'react-select';
import MDInput from "components/MDInput";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import * as service from '../../../services/service';

function AddEditCustomer() {
    const [state, setState] = useState({ customername: '', shippingaddress: '', billingaddress: '', contactperson: '', contactno: '', pan: '', gstno: '' });
    const [stateId, setStateId] = useState('');
    const [districtId, setDistrictId] = useState('');
    const [customerCategoryId, setCategoryId] = useState('');
    const [stateData, setStateData] = useState([]);
    const [districtData, setDistrictData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const navigate = useNavigate();
    const params = useParams();
    const getCategories = () => {
        fetch(
            service.CUSTOMER_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.customerCategoryId, label: item.customerCategory }));
                setCategoryData(data2);
            });
    };

    const getStates = () => {
        fetch(
            service.STATE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.stateId, label: item.state }));
                setStateData(data2);
            });
    };

    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    StateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));
                setDistrictData(data2);
            });
    };

    const getDetailsById = () => {
        setLoading(true);
        const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_CUSTOMER_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        CustomerId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setState({
                            ...state, customername: result.customerName, contactperson: result.contactPerson,
                            contactno: result.contactNo, pan: result.pan, gstno: result.gstNo,
                            shippingaddress: result.shippingAddress, billingaddress: result.billingAddress
                        });
                        const stData = ({ value: result.stateId, label: result.state });
                        setStateId(stData);
                        const distData = ({ value: result.districtId, label: result.district });
                        setDistrictId(distData);
                        const catData = ({ value: result.customerCategoryId, label: result.customerCategory });
                        setCategoryId(catData);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }

                });
        }
        setLoading(false);
    };

    useEffect(() => {
        getStates();
        getCategories();
        if (params.id > 0) {
            getDetailsById();
        }
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!state.customername) {
            isValid = false;
            errs[".customername"] = "Please enter customer name";
        }
        if (!state.shippingaddress) {
            isValid = false;
            errs[".shippingaddress"] = "Please enter shipping address";
        }
        if (!state.billingaddress) {
            isValid = false;
            errs[".billingaddress"] = "Please enter billing address";
        }
        if (!stateId) {
            isValid = false;
            errs[".state"] = "Please select state";
        }
        if (!districtId) {
            isValid = false;
            errs[".district"] = "Please select district";
        }
        if (!customerCategoryId) {
            isValid = false;
            errs[".category"] = "Please select customer category";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };
    const stateChangeHandler = (ev) => {
        setStateId(ev);
        setDistrictId(null);
        getDistricts(ev.value);
    }
    const districtChangeHandler = (ev) => {
        setDistrictId(ev);
    }
    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_UPDATE_CUSTOMER,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        CustomerId: params.id ? params.id : null,
                        CustomerName: state.customername,
                        ShippingAddress: state.shippingaddress,
                        BillingAddress: state.billingaddress,
                        ContactPerson: state.contactperson,
                        ContactNo: state.contactno,
                        PAN: state.pan,
                        GSTNo: state.gstno,
                        CustomerCategoryId: customerCategoryId.value,
                        StateId: stateId.value,
                        DistrictId: districtId.value,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/customer/customer');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/customer/customer');
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    const backHandler = () => {
        navigate('/customer/customer');
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                {/* <TableContainer component={Paper} style={{ width: '80%', alignSelf: 'center', height: '350px' }}> */}
                                    <Table aria-label="simple table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Customer Name" fullWidth value={state.customername || ''}
                                                        name="customername"
                                                        onChange={changeHandler}
                                                        id="customername"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".customername"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Shipping Address" fullWidth value={state.shippingaddress || ''}
                                                        name="shippingaddress"
                                                        onChange={changeHandler}
                                                        id="shippingaddress"
                                                        required
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".shippingaddress"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Billing Address" fullWidth value={state.billingaddress || ''}
                                                        name="billingaddress"
                                                        onChange={changeHandler}
                                                        id="billingaddress"
                                                        required
                                                        multiline
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".billingaddress"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Contact Person" fullWidth value={state.contactperson || ''}
                                                        name="contactperson"
                                                        onChange={changeHandler}
                                                        id="contactperson"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".contactperson"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Contact No" fullWidth value={state.contactno || ''}
                                                        name="contactno"
                                                        onChange={changeHandler}
                                                        id="contactno"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".contactno"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Select
                                                        name="state"
                                                        options={stateData} placeholder="Select State"
                                                        value={stateId}
                                                        onChange={stateChangeHandler}
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".state"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        name="district"
                                                        options={districtData} placeholder="Select District"
                                                        value={districtId}
                                                        onChange={districtChangeHandler}
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".district"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Select
                                                        name="category"
                                                        options={categoryData} placeholder="Select Category"
                                                        value={customerCategoryId}
                                                        onChange={ev => setCategoryId(ev)}
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".category"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="PAN" fullWidth value={state.pan || ''}
                                                        name="pan"
                                                        onChange={changeHandler}
                                                        id="pan"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pan"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="GST No." fullWidth value={state.gstno || ''}
                                                        name="gstno"
                                                        onChange={changeHandler}
                                                        id="gstno"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gstno"]}</div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                {/* </TableContainer> */}
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>  &nbsp;&nbsp;&nbsp;&nbsp;
                                            <MDButton color="info" onClick={backHandler}>
                                                BACK
                                            </MDButton>
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>

                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddEditCustomer;