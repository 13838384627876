/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import { useState, useEffect } from "react";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MDButton from 'components/MDButton';
import * as moment from 'moment';
import { Oval } from 'react-loader-spinner';
import * as service from '../../../services/service'
// Images

function Analytics() {
  const [materialForRequisionData, setMaterialForRequisitionData] = useState([]);
  const [requisionData, setRequisitionData] = useState([]);
  const [supplierWorkData, setSupplierWorkData] = useState([]);
  const [stockBelowRQData, setStockBelowRQData] = useState([]);
  const [loading, setLoading] = useState(false);

  const userDesignation = JSON.parse(sessionStorage.getItem('userData')).designation;
  const getMaterialForRequisition = () => {
    fetch(
      service.GET_MATERIAL_FOR_REQUISITION,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
      .then(res => res.json())
      .then(result => {
        setMaterialForRequisitionData(result);
      });
  };

  const getStockBelowReorderQuantity = () => {
    fetch(
      service.GET_STOCK_BELOW_REORDER_QUANTITY,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
      .then(res => res.json())
      .then(result => {
        setStockBelowRQData(result);
      });
  };

  const getMaterialRequisitions = () => {
    fetch(
      service.GET_MATERIAL_REQUISITION_BY_STATUS,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          Status: 'Pending'
        })
      })
      .then(res => res.json())
      .then(result => {
        setRequisitionData(result);
      })
      .catch(err => {
        if (err) {
          // eslint-disable-next-line
          console.log(err);
        };
      });
  };

  const getPendingWorkAllocationToSupplier = () => {
    fetch(
      service.GET_PENDING_WORK_ALLOCATION_TO_SUPPLIER,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(result => {
        setSupplierWorkData(result);
      });
  };

  const updateStatusHandler = (id, status) => {
    const errs = {};
    setLoading(true);
    // eslint-disable-next-line
    if (window.confirm("Are you sure...?")) {
      fetch(
        service.UPDATE_MATERIAL_REQUISITION_STATUS,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            RequisitionId: id,
            Status: status,
            UpdatedBy: JSON.parse(window.sessionStorage.getItem('userData')).employeeId,
          })
        })
        .then(res => res.json())
        .then(result => {
          if (result.messageType === 'Success') {
            // eslint-disable-next-line
            alert(result.message);
            window.location.reload();
          }
          else if (result.messageType === 'Failed') {
            // eslint-disable-next-line
            alert(result.message);
          }
          else {
            errs[".invalid"] = result.message.message;
            // setErrors(errs);
          }
        });
    }
    setLoading(false);
  }

  const updateWorkStatusHandler = (id, status) => {
    const errs = {};
    setLoading(true);
    // eslint-disable-next-line
    if (window.confirm("Are you sure...?")) {
      fetch(
        service.APPROVE_WORK_ALLOCATION_TO_SUPPLIER_STATUS,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            WorkAllocationId: id,
            Status: status,
            UpdatedBy: JSON.parse(window.sessionStorage.getItem('userData')).employeeId,
          })
        })
        .then(res => res.json())
        .then(result => {
          if (result.messageType === 'Success') {
            // eslint-disable-next-line
            // alert(result.message);
            window.location.reload();
          }
          else if (result.messageType === 'Failed') {
            // eslint-disable-next-line
            alert(result.message);
          }
          else {
            errs[".invalid"] = result.message.message;
            // setErrors(errs);
          }
        });
    }
    setLoading(false);
  }

  useEffect(() => {
    getMaterialForRequisition();
    getMaterialRequisitions();
    getPendingWorkAllocationToSupplier();
    getStockBelowReorderQuantity();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {userDesignation === 'General Manager' || userDesignation === 'Asst Mgr - Purchase'
          || userDesignation === 'Jr Mgr - Purchase' || userDesignation === 'Manager - Purchase'
          || userDesignation === 'Sr Mgr - Purchase' || userDesignation === 'MD' ?
          <Grid container>
            <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center' }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan="7" align="center" style={{ fontSize: '14px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', backgroundColor: 'lightblue' }}>
                      Material Below Reorder Quantity (Material For Requisition)
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Material Category
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Material Sub Category
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Material
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Material Code
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Unit
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Reorder Quantity
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Available Quantity
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  {materialForRequisionData.map((row) => (
                    <TableRow>
                      <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.materialCategory}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.materialSubCategory}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.material}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.materialCode}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.unitOfMeasure}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.reorderQuantity}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.availableQuantity}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          : null}
        <br />
        {userDesignation === 'General Manager' || userDesignation === 'Asst Mgr - Purchase'
          || userDesignation === 'Jr Mgr - Purchase' || userDesignation === 'Manager - Purchase'
          || userDesignation === 'Sr Mgr - Purchase' || userDesignation === 'MD' ?
          <Grid container>
            <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center' }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan="7" align="center" style={{ fontSize: '14px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', backgroundColor: 'lightblue' }}>
                      Stock Below Reorder Quantity
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Product
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Sub Assembly
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Part
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Reorder Quantity
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Available Quantity
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  {stockBelowRQData.map((row) => (
                    <TableRow>
                      <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.product}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.subAssembly}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.part}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.reorderQuantity}
                      </TableCell>
                      <TableCell align="right" style={{ fontSize: '12px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                        {row.stockCount}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          : null}
        <br />
        {userDesignation === 'General Manager' || userDesignation === 'Asst Mgr - Production'
          || userDesignation === 'Jr Mgr - Production' || userDesignation === 'Manager - Production'
          || userDesignation === 'Sr Mgr - Production' || userDesignation === 'MD' ?
          <Grid container>
            <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center' }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan="12" align="center" style={{ fontSize: '14px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', backgroundColor: 'lightskyblue' }}>
                      Pending Material Requisitions (For Approval)
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Material Category
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Material Sub Category
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Material
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Material Code
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Requisition No
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Requisition Date
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Requisition For
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Job No
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Quantity
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Requisition By
                    </TableCell>
                    {userDesignation === 'General Manager' ?
                      <TableCell colSpan="2" align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>Action</TableCell>
                      : null}
                  </TableRow>
                </TableBody>
                {loading ? <Oval
                  heigth="100"
                  width="100"
                  color='grey'
                  ariaLabel='loading'
                /> :
                  <TableBody>
                    {requisionData.map((row) => (
                      <TableRow>
                        <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                          {row.materialCategory}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                          {row.materialSubCategory}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                          {row.material}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                          {row.materialCode}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                          {row.requisitionNo}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                          {moment(row.requisitionDate).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                          {row.requisitionFor}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                          {row.jobNo}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                          {row.quantity}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                          {row.requisitionByName}
                        </TableCell>
                        {userDesignation === 'General Manager' ?
                          <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                            <MDButton onClick={() => updateStatusHandler(row.requisitionId, "Approved")}>Approved</MDButton>
                          </TableCell>
                          : null}
                        {userDesignation === 'General Manager' ?
                          <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                            <MDButton onClick={() => updateStatusHandler(row.requisitionId, "Rejected")}>Rejected</MDButton>
                          </TableCell>
                          : null}
                      </TableRow>
                    ))}
                  </TableBody>
                }
              </Table>
            </TableContainer>
          </Grid>
          : null}
        <br />
        {userDesignation === 'General Manager' || userDesignation === 'Asst Mgr - Production'
          || userDesignation === 'Jr Mgr - Production' || userDesignation === 'Manager - Production'
          || userDesignation === 'Sr Mgr - Production' || userDesignation === 'MD' ?
          <Grid container>
            <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center' }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan="13" align="center" style={{ fontSize: '14px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', backgroundColor: 'lightseagreen' }}>
                      Pending Work Allocation To Supplier (For Approval)
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Allocation For
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Suppler
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Customer
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Job No
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Product
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Sub Assembly
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Part
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Operation
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Allocated Date
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Planned Completion Date
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>
                      Remarks
                    </TableCell>
                    {userDesignation === 'General Manager' ?
                      <TableCell colSpan="2" align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid', fontWeight: 'bold' }}>Action</TableCell>
                      : null}
                  </TableRow>
                </TableBody>
                {loading ? <Oval
                  heigth="100"
                  width="100"
                  color='grey'
                  ariaLabel='loading'
                /> :
                  <TableBody>
                    {supplierWorkData.map((row) => (
                      <TableRow>
                        <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                          {row.allocationFor}
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                          {row.supplierName}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                          {row.customerName}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                          {row.jobNo}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                          {row.product}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                          {row.subAssembly}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                          {row.part}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                          {row.operation}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                          {moment(row.allocationDate).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                          {moment(row.plannedCompletionDate).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell align="right" style={{ fontSize: '12px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                          {row.remarks}
                        </TableCell>
                        {userDesignation === 'General Manager' ?
                          <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                            <MDButton onClick={() => updateWorkStatusHandler(row.workAllocationId, "Approved")}>Approved</MDButton>
                          </TableCell>
                          : null}
                        {userDesignation === 'General Manager' ?
                          <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                            <MDButton onClick={() => updateWorkStatusHandler(row.workAllocationId, "Rejected")}>Rejected</MDButton>
                          </TableCell>
                          : null}
                      </TableRow>
                    ))}
                  </TableBody>
                }
              </Table>
            </TableContainer>

          </Grid>
          : null}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics;
