import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import Select from 'react-select';
import * as service from '../../../services/service';

function SubAssembly() {
    const [data, setData] = useState([]);
    const [productCatData, setProductCatData] = useState([]);
    const [productSubCatData, setProductSubCatData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [productCatId, setProductCatId] = useState('');
    const [productSubCatId, setProductSubCatId] = useState('');
    const [productId, setProductId] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const userDesignation = JSON.parse(sessionStorage.getItem('userData')).designation;
    const navigate = useNavigate();
    const getProductCategories = () => {
        fetch(
            service.PRODUCT_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.productCategoryId, label: item.productCategory }));
                setProductCatData(data2);
            });
    };
    const getProductSubCategories = (id) => {
        fetch(
            service.PRODUCT_SUB_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductCategoryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.productSubCategoryId, label: item.productSubCategory }));
                setProductSubCatData(data2);
            });
    };
    const getProducts = (catId, subCatId) => {
        fetch(
            service.PRODUCT_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProductCategoryId: catId,
                    ProductSubCategoryId: subCatId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.productId, label: item.product }));
                setProductData(data2);
            });
    };
    const getDetails = (prodId) => {
        if (prodId > 0) {
            fetch(
                service.SUBASSEMBLY_URL,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ProductId: prodId
                    })
                })
                .then(res => res.json())
                .then(result => {
                    setData(result);
                })
                .catch(err => {
                    if (err) {
                        setError(err);
                    };
                });
        }
    };

    const handleEdit = (id) => {
        navigate(`/addeditsubassembly/${id}`);
    }

    const productCategoryChangeHandler = (ev) => {
        setProductCatId(ev);
        setProductSubCatId(null);
        setProductId(null);
        getProductSubCategories(ev.value);
    }
    const productSubCategoryChangeHandler = (ev) => {
        setProductSubCatId(ev);
        setProductId(null);
        getProducts(productCatId.value, ev.value);
    }
    const productChangeHandler = (ev) => {
        setProductId(ev);
        getDetails(ev.value);
    }

    useEffect(() => {
        setLoading(true);
        getProductCategories();

        if (JSON.parse(localStorage.getItem('productCategoryId')) != null) {
            const item = ({ value: JSON.parse(localStorage.getItem('productCategoryId')), label: JSON.parse(localStorage.getItem('productCategory')) });
            setProductCatId(item);
            getProductSubCategories(item.value);
            const subCat = ({ value: JSON.parse(localStorage.getItem('productSubCategoryId')), label: JSON.parse(localStorage.getItem('productSubCategory')) });
            setProductSubCatId(subCat);
            getProducts(item.value, subCat.value);
            const prod = ({ value: JSON.parse(localStorage.getItem('productId')), label: JSON.parse(localStorage.getItem('product')) });
            setProductId(prod);

            getDetails(JSON.parse(localStorage.getItem('productId')));
        };
        localStorage.setItem('productCategoryId', null);
        localStorage.setItem('productCategory', null);
        localStorage.setItem('productSubCategoryId', null);
        localStorage.setItem('productSubCategory', null);
        localStorage.setItem('productId', null);
        localStorage.setItem('product', null);

        setLoading(false);
    }, []);

    const addNew = () => {
        navigate('/addeditsubassembly/0');
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {error
                ? <MDAlert color="warning" dismissible>{error}</MDAlert>
                : null
            }
            {userDesignation === 'General Manager' || userDesignation === 'Manager - Production'
                || userDesignation === 'Sr Mgr - Production' || userDesignation === 'MD' ?
                <div style={{ float: 'right' }}>
                    <MDButton color="info" onClick={addNew}>
                        ADD NEW
                    </MDButton>
                </div>
                : null}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    SubAssembly Details
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <Table aria-label="simple table">
                                    <TableRow>
                                        <TableCell align='right' style={{ fontSize: '12px', width: '15%' }}>Product Category :</TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px', width: '20%' }}>
                                            <Select
                                                name="productCategory"
                                                options={productCatData} placeholder="Select Product Category"
                                                value={productCatId}
                                                onChange={productCategoryChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                        <TableCell align='right' style={{ fontSize: '12px', width: '15%' }}>Product Sub Category :</TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px', width: '20%' }}>
                                            <Select
                                                name="productSubCategory"
                                                options={productSubCatData} placeholder="Select Product Sub Category"
                                                value={productSubCatId}
                                                onChange={productSubCategoryChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                        <TableCell align='right' style={{ fontSize: '12px', width: '10%' }}>Product :</TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px', width: '20%' }}>
                                            <Select
                                                name="product"
                                                options={productData} placeholder="Select Product"
                                                value={productId}
                                                onChange={productChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                <br />
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '90%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Product Category</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Product Sub Category</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Product</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Sub Assembly</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Sub Assembly Code</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Required Quantity</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Reorder Quantity</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Sequence</TableCell>
                                                {userDesignation === 'General Manager' || userDesignation === 'Manager - Production'
                                                    || userDesignation === 'Sr Mgr - Production' || userDesignation === 'MD' ?
                                                    <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                                                    : null}
                                            </TableRow>
                                            <TableBody>
                                                {data.map((row) => (
                                                    <TableRow
                                                        key={row.productId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{row.productCategory}</TableCell>
                                                        <TableCell>{row.productSubCategory}</TableCell>
                                                        <TableCell>{row.product}</TableCell>
                                                        <TableCell>{row.subAssembly}</TableCell>
                                                        <TableCell>{row.subAssemblyCode}</TableCell>
                                                        <TableCell>{row.quantity}</TableCell>
                                                        <TableCell>{row.reorderQuantity}</TableCell>
                                                        <TableCell>{row.sequence}</TableCell>
                                                        {userDesignation === 'General Manager' || userDesignation === 'Manager - Production'
                                                            || userDesignation === 'Sr Mgr - Production' || userDesignation === 'MD' ?
                                                            <TableCell><MDButton onClick={() => handleEdit(row.subAssemblyId)}>Edit</MDButton></TableCell>
                                                            : null}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default SubAssembly;