// import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import { Radio } from '@mui/material';
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import * as service from '../../services/service';

function DailyWorkStatus() {
    const [state, setState] = useState({ remarks: '' });
    const [data, setData] = useState([]);
    const [inspectionData, setInspectionData] = useState([]);
    const [observationData, setObservationData] = useState([]);
    const [remarksData, setRemarksData] = useState([]);
    const [statusData, setStatusData] = useState([]);
    const [dailyWorkId, setDailyWorkId] = useState('');
    const [workAllocationId, setWorkAllocationId] = useState('');
    // const [taskStatus, setTaskStatus] = useState(true);
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const userDesignation = JSON.parse(sessionStorage.getItem('userData')).designation;
    const getDetails = () => {
        fetch(
            service.GET_DAILY_WORK_DETAILS_FOR_APPROVAL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json())
            .then(result => {
                setData(result);
            })
            .catch(err => {
                if (err) {
                    setError(err);
                };
            });
    };

    const getInspectionParameters = (id) => {
        fetch(
            service.OPERATION_INSPECTION_PARAMETER_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    OperationId: id
                })
            })
            .then(res => res.json())
            .then(result => {
                setInspectionData(result);
                result.forEach((index) => {
                    observationData[index] = '0';
                    setObservationData([...observationData]);
                    remarksData[index] = '';
                    setRemarksData([...remarksData]);
                    statusData[index] = '';
                    setStatusData([...statusData]);
                });
            })
            .catch(err => {
                if (err) {
                    setError(err);
                };
            });
    };

    const handleRadioChange = (id) => {
        setDailyWorkId(id);
        const item = data.find(obj => obj.dailyWorkId === id);
        setWorkAllocationId(item.workAllocationId);
        getInspectionParameters(item.operationId);
    }

    useEffect(() => {
        setLoading(true);
        getDetails();
        setLoading(false);
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!dailyWorkId) {
            isValid = false;
            errs[".dailyWorkId"] = "Please select task";
        }
        setError(errs);
        return isValid;
    }

    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const observationChangeHandler = (event, upLimit, lowLimit, index) => {
        let updatedObject = observationData[index];
        updatedObject = event.target.value;
        observationData[index] = updatedObject;
        setObservationData([...observationData]);

        let statusObject = statusData[index];
        let st = '';
        if (event.target.value < lowLimit || event.target.value > upLimit) {
            st = 'Rejected';
            // setTaskStatus(false);
        }
        else {
            st = 'Approved';
        }
        statusObject = st;
        statusData[index] = statusObject;
        setStatusData([...statusData]);
    };

    const remarksChangeHandler = (event, index) => {
        let updatedObject = remarksData[index];
        updatedObject = event.target.value;
        remarksData[index] = updatedObject;
        setRemarksData([...remarksData]);
    };

    // Update daily work details status
    const UpdateDailyWorkStatus = (tskSt) => {
        setLoading(true);
        fetch(
            service.UPDATE_DAILY_WORK_DETAILS_STATUS,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    DailyWorkId: dailyWorkId,
                    WorkStatus: tskSt ? "Approved" : "Rejected",
                    UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.messageType === 'Failed') {
                    // eslint-disable-next-line
                    alert(result.message);
                }
            });
        setLoading(false);
    };

    // Save inspection observations
    const SaveInspectionObservations = (dailyWorkStatusId) => {
        let flag = true;
        if (inspectionData) {
            inspectionData.forEach((element, index) => {
                fetch(
                    service.ADD_DAILY_WORK_INSPECTION_OBSERVATION,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            DailyWorkId: dailyWorkId,
                            DailyWorkStatusId: dailyWorkStatusId,
                            OperationInspParameterId: element.operationInspParameterId,
                            Observation: observationData[index],
                            Remakrs: remarksData[index],
                            Status: statusData[index],
                            AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                        })
                    })
                    .then(res1 => res1.json())
                    .then(result => {
                        if (result.messageType === 'Success') {
                            flag = true;
                        }
                        else if (result.messageType === 'Failed') {
                            flag = false;
                        }
                        else {
                            flag = false;
                        }
                    });
            });
        }
        if (flag) {
            // eslint-disable-next-line
            alert("Data Saved Successfully");
            setDailyWorkId('');
            setWorkAllocationId('');
            setInspectionData([]);
            setObservationData([]);
            setRemarksData([]);
            setStatusData([]);
            window.location.reload(false);
        }
        else {
            // eslint-disable-next-line
            alert("Error.. Something Went Wrong");
        }
    }

    // Save daily work status
    const submitHandler = (e) => {
        e.preventDefault();
        // const errs = {};
        setLoading(true);
        if (validate()) {
            let tskSt = true;
            statusData.forEach((element) => {
                if (element === "Rejected") {
                    tskSt = false;
                }
            });
            // eslint-disable-next-line
            if (window.confirm(`Do you want to save this task as ${tskSt ? 'Approved' : 'Rejected'}?`)) {
                fetch(
                    service.ADD_DAILY_WORK_STATUS,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            DailyWorkId: dailyWorkId,
                            WorkAllocationId: workAllocationId,
                            IsApproved: tskSt,
                            Remarks: state.remarks,
                            AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                        })
                    })
                    .then(res => res.json())
                    .then(result => {
                        if (result.message.messageType === 'Success') {
                            UpdateDailyWorkStatus(tskSt);
                            SaveInspectionObservations(result.dailyWorkStatusId);
                        }
                        else if (result.message.messageType === 'Failed') {
                            // eslint-disable-next-line
                            alert(result.message);
                        }
                    });
            }
            setLoading(false);
        }
    };


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Work Details For Approval
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Employee Name</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Product</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Sub Assembly</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Part</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Operation</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Remarks</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {data.map((row) => (
                                                    <TableRow
                                                        key={row.workAllocationId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell><Radio onChange={() => handleRadioChange(row.dailyWorkId)}
                                                            value={row.dailyWorkId} name="radio-buttons"
                                                            checked={dailyWorkId === row.dailyWorkId} />
                                                        </TableCell>
                                                        <TableCell>{row.employeeName}</TableCell>
                                                        <TableCell>{row.product}</TableCell>
                                                        <TableCell>{row.subAssembly}</TableCell>
                                                        <TableCell>{row.part}</TableCell>
                                                        <TableCell>{row.operation}</TableCell>
                                                        <TableCell>{row.remarks}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <div style={{ fontSize: '12px', color: 'red' }}>{error[".dailyWorkId"]}</div>
                                    </TableContainer>
                                }
                            </Card>
                            <br />
                            <MDTypography variant="h6" color="white">
                                Inspection Parameters
                            </MDTypography>
                            <Card style={{ alignItems: 'center' }}>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '70%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Inspection Parameter</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Specification</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Upper Limit</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Lower Limit</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Observation(In Decimal)</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Remarks</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {inspectionData.map((row, index) => (
                                                    <TableRow
                                                        key={row.operationInspParameterId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{row.inspectionParameter}</TableCell>
                                                        <TableCell>{row.specification}</TableCell>
                                                        <TableCell>{row.upperLimit}</TableCell>
                                                        <TableCell>{row.lowerLimit}</TableCell>
                                                        <TableCell>
                                                            <MDInput label="Observation" fullWidth
                                                                name="observation"
                                                                onChange={ev => observationChangeHandler(ev, row.upperLimit, row.lowerLimit, index)}
                                                                id="observation"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Remarks" fullWidth
                                                                name="remarks"
                                                                onChange={ev => remarksChangeHandler(ev, index)}
                                                                id="remarks"
                                                                multiline
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Card>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <TableContainer component={Paper} style={{ width: '50%', alignSelf: 'center', }}>
                                    <Table aria-label="simple table">
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                <MDInput label="Remarks" fullWidth value={state.remarks || ''}
                                                    name="remarks"
                                                    onChange={changeHandler}
                                                    id="remarks"
                                                    multiline
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{error[".remarks"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        {userDesignation === 'General Manager' || userDesignation === 'Manager - Production'
                                            || userDesignation === 'Sr Mgr - Production' || userDesignation === 'MD' ?
                                            <TableRow>
                                                <TableCell>
                                                    <MDBox mt={4} mb={1} textAlign="center">
                                                        {loading ? <Oval
                                                            heigth="20"
                                                            width="20"
                                                            color='grey'
                                                            ariaLabel='loading'
                                                        /> :
                                                            <MDButton color="info" onClick={submitHandler}>
                                                                Save
                                                            </MDButton>
                                                        }
                                                        {error ? <div style={{ fontSize: '12px', color: 'red' }}>{error[".invalid"]}</div> : null}
                                                    </MDBox>
                                                </TableCell>
                                            </TableRow>
                                            : null}
                                    </Table>
                                </TableContainer>
                            </Card>

                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default DailyWorkStatus;