
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Select from 'react-select';
import MDInput from "components/MDInput";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import * as service from '../../../services/service';

function AddEditSupplier() {
    const [state, setState] = useState({ suppliername: '', address: '', emailid: '', contactno: '', creditperiod: '', gstno: '' });
    const [stateId, setStateId] = useState('');
    const [districtId, setDistrictId] = useState('');
    const [supplierCategoryId, setCategoryId] = useState('');
    const [stateData, setStateData] = useState([]);
    const [districtData, setDistrictData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const navigate = useNavigate();
    const params = useParams();
    const getCategories = () => {
        fetch(
            service.SUPPLIER_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.supplierCategoryId, label: item.supplierCategory }));
                setCategoryData(data2);
            });
    };

    const getStates = () => {
        fetch(
            service.STATE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.stateId, label: item.state }));
                setStateData(data2);
            });
    };

    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    StateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));
                setDistrictData(data2);
            });
    };

    const getDetailsById = () => {
        setLoading(true);
        const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_SUPPLIER_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        SupplierId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setState({ ...state, suppliername: result.supplierName, address: result.address, emailid: result.emailId, contactno: result.contactNo, creditperiod: result.creditPeriod, gstno: result.gstNo });
                        const stData = ({ value: result.stateId, label: result.state });
                        setStateId(stData);
                        const distData = ({ value: result.districtId, label: result.district });
                        setDistrictId(distData);
                        const catData = ({ value: result.supplierCategoryId, label: result.supplierCategory });
                        setCategoryId(catData);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }

                });
        }
        setLoading(false);
    };

    useEffect(() => {
        getStates();
        getCategories();
        if (params.id > 0) {
            getDetailsById();
        }
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!state.suppliername) {
            isValid = false;
            errs[".suppliername"] = "Please enter supplier name";
        }
        if (!stateId) {
            isValid = false;
            errs[".state"] = "Please select state";
        }
        if (!districtId) {
            isValid = false;
            errs[".district"] = "Please select district";
        }
        if (!supplierCategoryId) {
            isValid = false;
            errs[".category"] = "Please select supplier category";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };
    const stateChangeHandler = (ev) => {
        setStateId(ev);
        setDistrictId(null);
        getDistricts(ev.value);
    }
    const districtChangeHandler = (ev) => {
        setDistrictId(ev);
    }
    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_UPDATE_SUPPLIER,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        SupplierId: params.id ? params.id : null,
                        SupplierName: state.suppliername,
                        Address: state.address,
                        EmailId: state.emailid,
                        ContactNo: state.contactno,
                        CreditPeriod: state.creditperiod,
                        SupplierCategoryId: supplierCategoryId.value,
                        StateId: stateId.value,
                        DistrictId: districtId.value,
                        GSTNo: state.gstno,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/supplier/supplier');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/supplier/supplier');
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }
    const backHandler = () => {
        navigate('/supplier/supplier');
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Supplier Name" fullWidth value={state.suppliername || ''}
                                                    name="suppliername"
                                                    onChange={changeHandler}
                                                    id="suppliername"
                                                    required />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".suppliername"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Address" fullWidth value={state.address || ''}
                                                    name="address"
                                                    onChange={changeHandler}
                                                    id="address"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".address"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Email" fullWidth value={state.emailid || ''}
                                                    name="emailid"
                                                    type="email"
                                                    onChange={changeHandler}
                                                    id="emailid"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".emailid"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Contact No" fullWidth value={state.contactno || ''}
                                                    name="contactno"
                                                    onChange={changeHandler}
                                                    id="contactno"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".contactno"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="state"
                                                    options={stateData} placeholder="Select State"
                                                    value={stateId}
                                                    onChange={stateChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".state"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="district"
                                                    options={districtData} placeholder="Select District"
                                                    value={districtId}
                                                    onChange={districtChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".district"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="category"
                                                    options={categoryData} placeholder="Select Category"
                                                    value={supplierCategoryId}
                                                    onChange={ev => setCategoryId(ev)}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".category"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Credit Period" fullWidth value={state.creditperiod || ''}
                                                    name="creditperiod"
                                                    onChange={changeHandler}
                                                    id="contactno"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".creditperiod"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="GST No." fullWidth value={state.gstno || ''}
                                                    name="gstno"
                                                    onChange={changeHandler}
                                                    id="gstno"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gstno"]}</div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>  &nbsp;&nbsp;&nbsp;&nbsp;
                                            <MDButton color="info" onClick={backHandler}>
                                                BACK
                                            </MDButton>
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>

                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddEditSupplier;