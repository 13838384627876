import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import 'react-dropdown/style.css';
// import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from 'react-select';
import * as moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MDButton from 'components/MDButton';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import * as service from '../../services/service';

function EmployeePerformanceReport() {
    const [reportDetailsData, setReportDetailsData] = useState([]);
    const [showDetailsReport, setShowDetailsReport] = useState(false);
    const [employeeData, setEmployeeData] = useState([]);
    const [employeeId, setEmployeeId] = useState('');    
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);   
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const getEmployees = () => {
        fetch(
            service.EMPLOYEE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.employeeId, label: item.employeeName }));              
                setEmployeeData(data2);                
            });
    };
   
    function GetReport() {
        const errs = {};        
        fetch(
            service.GET_EMPLOYEE_PERFORMANCE_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({                   
                    EmployeeId: employeeId.value,                    
                    FromDate: fromDate ? moment(fromDate).format("MM/DD/YYYY") : null,
                    ToDate: toDate ? moment(toDate).format("MM/DD/YYYY") : null
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowDetailsReport(true);
                    setReportDetailsData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDetailsReport(false);
                }
            });
    }

    useEffect(() => {
        setLoading(true);
        getEmployees();
        setLoading(false);
    }, []);

    const changeHandler = (ev) => {
        setEmployeeId(ev);       
        setReportDetailsData([]);
    }
    
    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!employeeId) {
            isValid = false;
            errs[".employeeId"] = "Please select employee";
        }        
        setErrors(errs);
        return isValid;
    }
    const submitHandler = e => {
        setReportDetailsData([]);
        setShowDetailsReport(false);
        e.preventDefault();
        setLoading(true);
        if (validate()) {
            GetReport();
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Employee Performance Report
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Table aria-label="simple table">                                
                                <TableRow>
                                    <TableCell width="15%" align="right" style={{ fontSize: '12px' }}>
                                        Employee :
                                    </TableCell>
                                    <TableCell width="20%" align="left" style={{ fontSize: '12px' }}>
                                        <Select
                                            options={employeeData}
                                            name="employeeId"
                                            value={employeeId}
                                            onChange={changeHandler}
                                            placeholder="Select Employee"
                                            isSearchable
                                            styles={selectStyles}                                          
                                        />
                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".employeeId"]}</div>                                       
                                    </TableCell>                               
                                    <TableCell  width="15%" align="right" style={{ fontSize: '12px' }}>
                                        From Date :
                                    </TableCell>
                                    <TableCell  width="20%" style={{ fontSize: '12px' }}>
                                        <DatePicker
                                            id="fromDate"
                                            selected={fromDate}
                                            onChange={date => setFromDate(date)}
                                            dateFormat="MM/dd/yyyy"
                                        />
                                    </TableCell>
                                    <TableCell  width="15%" align="right" style={{ fontSize: '12px' }}>
                                        To Date :
                                    </TableCell>
                                    <TableCell style={{ fontSize: '12px' }}>
                                        <DatePicker
                                            id="toDate"
                                            selected={toDate}
                                            onChange={date => setToDate(date)}
                                            dateFormat="MM/dd/yyyy"
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan="6" align="center">
                                        <MDBox mt={4} mb={1} textAlign="center">
                                            {loading ?
                                                <Oval
                                                    heigth="20"
                                                    width="20"
                                                    color='grey'
                                                    ariaLabel='loading'
                                                />
                                                :
                                                <MDButton color="info" onClick={submitHandler}>
                                                    Show
                                                </MDButton>
                                            }
                                            {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                        </MDBox>
                                    </TableCell>
                                </TableRow>
                            </Table>
                            <Card style={{ alignItems: 'center' }}>
                                {loading ?
                                    <Oval
                                        heigth="100"
                                        width="100"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                    <TableContainer component={Paper} style={{ width: '98%', alignSelf: 'center' }}>
                                        <Table id="dvDetailReport">
                                            <TableRow>
                                                <TableCell colSpan="12" align="center">
                                                    <Table>
                                                        <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                            Employee : {employeeId.label}
                                                        </TableCell>                                                       
                                                        <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                            From Date : {fromDate ? moment(fromDate).format("DD/MM/YYYY") : ''}
                                                        </TableCell>
                                                        <TableCell align="center" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                            To Date : {toDate ? moment(toDate).format("DD/MM/YYYY") : ''}
                                                        </TableCell>
                                                    </Table>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Employee</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Allocation For</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Customer</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Work Order No.</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Job No.</TableCell>                                                
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Product</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Sub Assembly</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Part</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Operation</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Allocation Date</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Planned Completion Date</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Remarks</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Expected Time</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Time Spent</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Difference</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Status</TableCell>                                                
                                            </TableRow>
                                            {reportDetailsData.map((row) => (
                                                <TableRow
                                                    key={row.workAllocationId}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell style={{ fontSize: '14px' }}>{row.employeeName}</TableCell>
                                                    <TableCell style={{ fontSize: '14px' }}>{row.allocationFor}</TableCell>
                                                    <TableCell style={{ fontSize: '14px' }}>{row.customerName}</TableCell>
                                                    <TableCell style={{ fontSize: '14px' }}>{row.workOrderNo}</TableCell>
                                                    <TableCell style={{ fontSize: '14px' }}>{row.jobNo}</TableCell>                                                    
                                                    <TableCell style={{ fontSize: '14px' }}>{row.product}</TableCell>
                                                    <TableCell style={{ fontSize: '14px' }}>{row.subAssembly}</TableCell>
                                                    <TableCell style={{ fontSize: '14px' }}>{row.part}</TableCell>
                                                    <TableCell style={{ fontSize: '14px' }}>{row.operation}</TableCell>
                                                    <TableCell style={{ fontSize: '14px' }}>{moment(row.allocationDate).format("DD/MM/YYYY")}</TableCell>
                                                    <TableCell style={{ fontSize: '14px' }}>{moment(row.plannedCompletionDate).format("DD/MM/YYYY")}</TableCell>
                                                    <TableCell style={{ fontSize: '14px' }}>{row.remarks}</TableCell>
                                                    <TableCell style={{ fontSize: '14px' }}>{row.completionTime}</TableCell>
                                                    <TableCell style={{ fontSize: '14px' }}>{row.timeSpent}</TableCell>
                                                    <TableCell style={{ fontSize: '14px' }}>{(parseFloat(row.completionTime) - parseFloat(row.timeSpent)).toFixed(2)}</TableCell>
                                                    <TableCell style={{ fontSize: '14px' }}>{row.status}</TableCell>
                                                </TableRow>
                                            ))}
                                        </Table>
                                        <br />
                                        <Table>
                                            <TableRow>
                                                <TableCell align="center">
                                                    {showDetailsReport ?
                                                        <ReactHTMLTableToExcel table="dvDetailReport" className="info"
                                                            filename="ReportData" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                                                        />
                                                        : null}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>{errors[".invalid"]}</TableCell>
                                            </TableRow>
                                        </Table>
                                    </TableContainer>
                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default EmployeePerformanceReport;
