
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import Select from 'react-select';
import * as service from '../../../../services/service';

function AddEditMaterial() {
    const [state, setState] = useState({ material: '', description: '', unitofmeasure: '', reorderquantity: '', materialcode: '', storelocation: '' });
    const [materialCategoryId, setMaterialCategoryId] = useState('');
    const [materialSubCategoryId, setMaterialSubCategoryId] = useState('');
    const [materialCategoryData, setMaterialCategoryData] = useState([]);
    const [materialSubCategoryData, setMaterialSubCategoryData] = useState([]);
    const [hsnCodeData, setHSNCodeData] = useState([]);
    const [hsnCodeId, setHSNCodeId] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [matClass, setMatClass] = useState('');
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const classData = [{ label: 'A', value: 'A' }, { label: 'B', value: 'B' }, { label: 'C', value: 'C' }]
    const navigate = useNavigate();
    const params = useParams();
    const getDetailsById = () => {
        setLoading(true);
        const errs = {};
        if (params.id > 0) {
            fetch(
                service.GET_MATERIAL_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        materialId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setState({
                            ...state, material: result.material, description: result.description,
                            unitofmeasure: result.unitOfMeasure, reorderquantity: result.reorderQuantity, materialcode: result.materialCode,
                            storelocation: result.storeLocation
                        });
                        const prod = ({ value: result.materialCategoryId, label: result.materialCategory });
                        setMaterialCategoryId(prod);
                        const proc = ({ value: result.materialSubCategoryId, label: result.materialSubCategory });
                        setMaterialSubCategoryId(proc);
                        if (result.hsnCodeId != null) {
                            const hsn = ({ value: result.hsnCodeId, label: result.hsnCode });
                            setHSNCodeId(hsn);
                        }

                        const cl = ({ value: result.materialClass, label: result.materialClass });
                        setMatClass(cl);

                        localStorage.setItem('materialCategoryId', JSON.stringify(result.materialCategoryId));
                        localStorage.setItem('materialCategory', JSON.stringify(result.materialCategory));
                        localStorage.setItem('materialSubCategoryId', JSON.stringify(result.materialSubCategoryId));
                        localStorage.setItem('materialSubCategory', JSON.stringify(result.materialSubCategory));
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }

                });
        }
        setLoading(false);
    };

    const getMaterialCategories = () => {
        fetch(
            service.MATERIAL_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.materialCategoryId, label: item.materialCategory }));
                setMaterialCategoryData(data2);
            });
    };
    const getMaterialSubCategories = (id) => {
        fetch(
            service.MATERIAL_SUB_CATEGORY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    materialCategoryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.materialSubCategoryId, label: item.materialSubCategory }));
                setMaterialSubCategoryData(data2);
            });
    };
    const getHSNCodes = () => {
        fetch(
            service.HSNCODE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.hsnCodeId, label: item.hsnCode }));
                setHSNCodeData(data2);
            });
    };
    useEffect(() => {
        getMaterialCategories();
        getMaterialSubCategories();
        getHSNCodes();
        if (params.id > 0) {
            getDetailsById();
        }
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!materialCategoryId) {
            isValid = false;
            errs[".materialCategory"] = "Please select Material Category";
        }
        if (!materialSubCategoryId) {
            isValid = false;
            errs[".materialSubCategory"] = "Please select Material Sub Category";
        }
        if (!state.material) {
            isValid = false;
            errs[".material"] = "Please enter material";
        }
        if (!state.unitofmeasure) {
            isValid = false;
            errs[".unitofmeasure"] = "Please enter Unit Of Measure";
        }
        if (!state.reorderquantity) {
            isValid = false;
            errs[".reorderquantity"] = "Please enter Reorder Quantity";
        }
        if (!matClass) {
            isValid = false;
            errs[".matClass"] = "Please select Material Class";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };
    const materialCategoryChangeHandler = (ev) => {
        setMaterialCategoryId(ev);
        setMaterialSubCategoryId(null);
        getMaterialSubCategories(ev.value);
    }
    const materialSubCategoryChangeHandler = (ev) => {
        setMaterialSubCategoryId(ev);
    }
    const hsnCodeChangeHandler = (ev) => {
        setHSNCodeId(ev);
    }
    const matClassChangeHandler = (ev) => {
        setMatClass(ev);        
    }
    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            if (params.id > 0) {
                fetch(
                    service.UPDATE_MATERIAL,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            materialId: params.id,
                            materialCategoryId: materialCategoryId.value,
                            materialSubCategoryId: materialSubCategoryId.value,
                            material: state.material,
                            materialCode: state.materialcode,
                            Description: state.description,
                            UnitOfMeasure: state.unitofmeasure,
                            ReorderQuantity: state.reorderquantity,
                            HSNCodeId: hsnCodeId.value,
                            MaterialClass: matClass.value,
                            StoreLocation: state.storelocation,
                            UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                        })
                    })
                    .then(res => res.json())
                    .then(result => {
                        if (result.messageType === 'Success') {
                            // eslint-disable-next-line
                            alert(result.message);
                            navigate('/masters/material');
                        }
                        else if (result.messageType === 'Failed') {
                            // eslint-disable-next-line
                            alert(result.message);
                            // navigate('/masters/taskcategory');
                        }
                        else {
                            errs[".invalid"] = result.message.message;
                            setErrors(errs);
                        }
                    });
            }
            else {
                fetch(
                    service.ADD_MATERIAL,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            materialCategoryId: materialCategoryId.value,
                            materialSubCategoryId: materialSubCategoryId.value,
                            material: state.material,
                            materialCode: state.materialcode,
                            Description: state.description,
                            UnitOfMeasure: state.unitofmeasure,
                            ReorderQuantity: state.reorderquantity,
                            HSNCodeId: hsnCodeId.value,
                            MaterialClass: matClass.value,
                            StoreLocation: state.storelocation,
                            AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                        })
                    })
                    .then(res => res.json())
                    .then(result => {
                        if (result.messageType === 'Success') {
                            // eslint-disable-next-line
                            alert(result.message);
                            navigate('/masters/material');
                        }
                        else if (result.messageType === 'Failed') {
                            // eslint-disable-next-line
                            alert(result.message);
                            // navigate('/masters/taskcategory');
                        }
                        else {
                            errs[".invalid"] = result.message.message;
                            setErrors(errs);
                        }
                    });
            }
        }
        setLoading(false);
    }
    const backHandler = () => {
        navigate('/masters/material');
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Save Details
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <MDBox pt={4} pb={3} px={3} style={{ width: '50%' }}>
                                    <MDBox component="form" role="form">
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                            <Select
                                                name="materialCategory"
                                                options={materialCategoryData} placeholder="Select Material Category"
                                                value={materialCategoryId}
                                                onChange={materialCategoryChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".materialCategory"]}</div>
                                        </MDBox>
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                            <Select
                                                name="materialSubCategory"
                                                options={materialSubCategoryData} placeholder="Select Material Sub Category"
                                                value={materialSubCategoryId}
                                                onChange={materialSubCategoryChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".materialSubCategory"]}</div>
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput label="Material" fullWidth value={state.material || ''}
                                                name="material"
                                                onChange={changeHandler}
                                                id="material"
                                                required
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".material"]}</div>
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput label="Material Code" fullWidth value={state.materialcode || ''}
                                                name="materialcode"
                                                onChange={changeHandler}
                                                id="materialcode"
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".materialcode"]}</div>
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput label="Description" fullWidth value={state.description || ''}
                                                name="description"
                                                onChange={changeHandler}
                                                id="description"
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".description"]}</div>
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput label="Unit Of Measure" fullWidth value={state.unitofmeasure || ''}
                                                name="unitofmeasure"
                                                onChange={changeHandler}
                                                id="unitofmeasure"
                                                required
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".unitofmeasure"]}</div>
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput label="Re-order Quantity" fullWidth value={state.reorderquantity || ''}
                                                name="reorderquantity"
                                                onChange={changeHandler}
                                                id="reorderquantity"
                                                required
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".reorderquantity"]}</div>
                                        </MDBox>
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                            <Select
                                                options={hsnCodeData}
                                                name="hsnCode"
                                                value={hsnCodeId}
                                                onChange={hsnCodeChangeHandler}
                                                placeholder="Select HSN Code"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".hsnCode"]}</div>
                                        </MDBox>
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                            <Select
                                                options={classData}
                                                name="matClass"
                                                value={matClass}
                                                onChange={matClassChangeHandler}                                                
                                                placeholder="Select Class"
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".matClass"]}</div>
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput label="Store Location" fullWidth value={state.storelocation || ''}
                                                name="storelocation"
                                                onChange={changeHandler}
                                                id="storelocation"
                                                multiline
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".storelocation"]}</div>
                                        </MDBox>
                                        <MDBox mt={4} mb={1} textAlign="center">
                                            {loading ? <Oval
                                                heigth="20"
                                                width="20"
                                                color='grey'
                                                ariaLabel='loading'
                                            /> :
                                                <div>
                                                    <MDButton color="info" onClick={submitHandler}>
                                                        SAVE
                                                    </MDButton>  &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <MDButton color="info" onClick={backHandler}>
                                                        BACK
                                                    </MDButton>
                                                </div>
                                            }
                                            {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddEditMaterial;