import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import * as service from '../../services/service';

function WorkOrder() {
    const [data, setData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [customerId, setCustomerId] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const userDesignation = JSON.parse(sessionStorage.getItem('userData')).designation;
    const navigate = useNavigate();
    const getCustomers = () => {
        fetch(
            service.CUSTOMER_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.customerId, label: item.customerName }));
                setCustomerData(data2);
            });
    };
    const getDetails = (id) => {
        if (id > 0) {
            fetch(
                service.WORK_ORDER_URL,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        CustomerId: id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    setData(result);
                })
                .catch(err => {
                    if (err) {
                        setError(err);
                    };
                });
        }
    };

    // const handleEdit = (id) => {
    //     navigate(`/addeditworkorder/${id}`);
    // }

    const handleViewStock = (id) => {
        navigate(`/viewstock/${id}`);
    }
    const handleAddMaterial = (id) => {
        navigate(`/workorderformaterial/${id}`);
    }

    const handleUpdateStatus = (id) => {
        setLoading(true);
        // eslint-disable-next-line
        if (window.confirm("Do you want to update status as completed?")) {
            fetch(
                service.UPDATE_WORK_ORDER_STATUS,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        WorkOrderId: id,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        window.location.reload(false);
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        // navigate('/customer/workorder');
                    }
                    else {
                        // errs[".invalid"] = result.message.message;
                        // setError(errs);
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    }

    const changeHandler = (ev) => {
        setCustomerId(ev);
        getDetails(ev.value);
    }

    useEffect(() => {
        setLoading(true);
        getCustomers();
        if (JSON.parse(localStorage.getItem('customerId')) != null) {
            const item = ({ value: JSON.parse(localStorage.getItem('customerId')), label: JSON.parse(localStorage.getItem('customerName')) });
            setCustomerId(item);
            getDetails(JSON.parse(localStorage.getItem('customerId')));
        };
        localStorage.setItem('customerId', null);
        localStorage.setItem('customerName', null);
        setLoading(false);
    }, []);

    const addNew = () => {
        navigate('/addeditworkorder/0');
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {error
                ? <MDAlert color="warning" dismissible>{error}</MDAlert>
                : null
            }
            {userDesignation === 'General Manager' || userDesignation === 'MD' || userDesignation === 'Assistant - Marketing' ?
                <div style={{ float: 'right' }}>
                    <MDButton color="info" onClick={addNew}>
                        ADD NEW
                    </MDButton>
                </div>
                : null}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Work Orders
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <Table aria-label="simple table">
                                    <TableRow>
                                        <TableCell align='right' style={{ fontSize: '12px', width: '30%' }}>
                                            Customer :
                                        </TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px', width: '30%' }}>
                                            <Dropdown menuClassName='myMenuClassName' id="customer"
                                                name="customer"
                                                options={customerData} placeholder="Select Customer"
                                                value={customerId}
                                                onChange={changeHandler}
                                                width
                                            />
                                        </TableCell>
                                        <TableCell align='left' style={{ fontSize: '12px', width: '30%' }} />
                                    </TableRow>
                                </Table>
                                <br />
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Work Order No.</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Job No.</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Product Category</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Product Sub Category</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Product</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Sub Assembly</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Part</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Quantity</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Total Amount</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                                                {userDesignation === 'General Manager' ?
                                                    <TableCell style={{ fontWeight: 'bold' }}>Add Material</TableCell>
                                                    : null}
                                                {userDesignation === 'General Manager' ?
                                                    <TableCell style={{ fontWeight: 'bold' }}>View Stock</TableCell>
                                                    : null}
                                                {userDesignation === 'General Manager' ?
                                                    <TableCell style={{ fontWeight: 'bold' }}>Mark Completed</TableCell>
                                                    : null}
                                            </TableRow>
                                            <TableBody>
                                                {data.map((row) => (
                                                    <TableRow
                                                        key={row.workOrderId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">{row.workOrderNo}</TableCell>
                                                        <TableCell component="th" scope="row">{row.jobNo}</TableCell>
                                                        <TableCell component="th" scope="row">{row.productCategory}</TableCell>
                                                        <TableCell component="th" scope="row">{row.productSubCategory}</TableCell>
                                                        <TableCell component="th" scope="row">{row.product}</TableCell>
                                                        <TableCell component="th" scope="row">{row.subAssembly}</TableCell>
                                                        <TableCell component="th" scope="row">{row.part}</TableCell>
                                                        <TableCell component="th" scope="row">{row.quantity}</TableCell>
                                                        <TableCell component="th" scope="row">{row.totalAmount}</TableCell>
                                                        <TableCell component="th" scope="row">{row.status ? 'Completed' : 'Pending'}</TableCell>
                                                        {userDesignation === 'General Manager' ?
                                                            <TableCell><MDButton onClick={() => handleAddMaterial(row.workOrderId)} disabled={row.status}>Add Material</MDButton></TableCell>
                                                            : null}
                                                        {userDesignation === 'General Manager' || userDesignation === 'MD' ?
                                                            <TableCell><MDButton onClick={() => handleViewStock(row.workOrderId)} disabled={row.status || row.stockAllocated}>View Stock</MDButton></TableCell>
                                                            : null}
                                                        {userDesignation === 'General Manager' ?
                                                            <TableCell><MDButton onClick={() => handleUpdateStatus(row.workOrderId)} disabled={row.status}>Mark Completed</MDButton></TableCell>
                                                            : null}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default WorkOrder;