// import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
// import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import MDInput from "components/MDInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Radio } from '@mui/material';
import * as moment from 'moment';
import * as service from '../../services/service';

function Invoice() {
    const [state, setState] = useState({ invoiceno: '', cgst: '', igst: '', sgst: '', amount: '', totalamount: '' });
    const [invoiceDate, setInvoiceDate] = useState(new Date());
    const [data, setData] = useState([]);
    const [workOrderData, setWorkOrderData] = useState([]);
    const [deliveryChallanId, setDeliveryChallanId] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const userDesignation = JSON.parse(sessionStorage.getItem('userData')).designation;
    const getDetails = () => {
        fetch(
            service.GET_ITEMS_FOR_INVOICE,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                setData(result);
            })
            .catch(err => {
                if (err) {
                    setErrors(err);
                };
            });
    };

    const getWODetailsById = (id) => {
        setLoading(true);
        const errs = {};
        fetch(
            service.GET_WORK_ORDER_BY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    WorkOrderId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.message.messageType === 'Success') {
                    setWorkOrderData(result);
                }
                else {
                    errs[".invalid"] = result.message.message;
                    setErrors(errs);
                }
            });

        setLoading(false);
    };

    const getNextInvoiceNo = () => {
        fetch(
            service.GET_NEXT_INVOICE_NO,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                setState({ ...state, invoiceno: result.invoiceNo });
            });
    };

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!deliveryChallanId) {
            isValid = false;
            errs[".deliveryChallanId"] = "Please select delivery challan";
        }
        if (!invoiceDate) {
            isValid = false;
            errs[".invoiceDate"] = "Please enter invoice date";
        }
        if (!state.amount) {
            isValid = false;
            errs[".amount"] = "Please enter amount";
        }
        if (!state.totalamount) {
            isValid = false;
            errs[".totalamount"] = "Please enter totalamount";
        }
        setErrors(errs);
        return isValid;
    }

    const submitHandler = e => {
        if (validate()) {
            e.preventDefault();
            const errs = {};
            setLoading(true);
            fetch(
                service.ADD_INVOICE,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        InvoiceNo: state.invoiceno,
                        InvoiceDate: moment(invoiceDate).format("MM/DD/YYYY"),
                        DeliveryChallanId: deliveryChallanId,
                        Amount: state.amount,
                        CGST: state.cgst ? state.cgst : 0,
                        SGST: state.sgst ? state.sgst : 0,
                        IGST: state.igst ? state.igst : 0,
                        TotalAmount: state.totalamount,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).employeeId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        window.location.reload(false);
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        // navigate('/customer/workorder');
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
            setLoading(false);
        }
    }

    useEffect(() => {
        setLoading(true);
        getNextInvoiceNo();
        getDetails();
        setLoading(false);
    }, []);

    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const handleRadioChange = (delId, woId) => {
        setDeliveryChallanId(delId);
        getWODetailsById(woId);
    }

    // const clearHandler = () => {
    //     setDeliveryChallanId('');
    //     setData([]);
    // }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Invoices
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '90%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Delivery Challan No</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Customer</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Job No</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Product</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Sub Assembly</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Part</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Quantity in DL</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {data.map((row) => (
                                                    <TableRow
                                                        key={row.deliveryChallanId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>
                                                            <Radio onChange={() => handleRadioChange(row.deliveryChallanId, row.workOrderId)}
                                                                value={row.deliveryChallanId} name="radio-buttons"
                                                                checked={deliveryChallanId === row.deliveryChallanId} />
                                                        </TableCell>
                                                        <TableCell>{row.challanNo}</TableCell>
                                                        <TableCell>{row.customerName}</TableCell>
                                                        <TableCell>{row.jobNo}</TableCell>
                                                        <TableCell>{row.product}</TableCell>
                                                        <TableCell>{row.subAssembly}</TableCell>
                                                        <TableCell>{row.part}</TableCell>
                                                        <TableCell>{row.challanQuantity}</TableCell>
                                                    </TableRow>
                                                ))}
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".deliveryChallanId"]}</div>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                                <br />
                                {workOrderData ?
                                    <TableContainer component={Paper} style={{ width: '90%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }} colSpan="9" align="center">
                                                    Work Order Details
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Work Order No.</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Job No.</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Product Category</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Product Sub Category</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Product</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Sub Assembly</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Part</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Quantity</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Total Amount</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">{workOrderData.workOrderNo}</TableCell>
                                                    <TableCell component="th" scope="row">{workOrderData.jobNo}</TableCell>
                                                    <TableCell component="th" scope="row">{workOrderData.productCategory}</TableCell>
                                                    <TableCell component="th" scope="row">{workOrderData.productSubCategory}</TableCell>
                                                    <TableCell component="th" scope="row">{workOrderData.product}</TableCell>
                                                    <TableCell component="th" scope="row">{workOrderData.subAssembly}</TableCell>
                                                    <TableCell component="th" scope="row">{workOrderData.part}</TableCell>
                                                    <TableCell component="th" scope="row">{workOrderData.quantity}</TableCell>
                                                    <TableCell component="th" scope="row">{workOrderData.totalAmount}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    : null}
                                <br />
                                <TableContainer component={Paper} style={{ width: '90%', alignSelf: 'center', }}>
                                    <Table aria-label="simple table">
                                        <TableRow>
                                            <TableCell width="35%">
                                                <MDInput label="Invoice No." fullWidth value={state.invoiceno || ''}
                                                    name="invoiceno"
                                                    onChange={changeHandler}
                                                    id="invoiceno"
                                                    required
                                                    disabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invoiceno"]}</div>
                                            </TableCell>
                                            <TableCell width="35%">
                                                Invoice Date
                                                <DatePicker
                                                    id="invoiceDate"
                                                    selected={invoiceDate}
                                                    onChange={date => setInvoiceDate(date)}
                                                    dateFormat="MM/dd/yyyy"
                                                    tabIndex={0}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invoiceDate"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Amount" fullWidth value={state.amount || ''}
                                                    name="amount"
                                                    onChange={changeHandler}
                                                    id="amount"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".amount"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="CGST" fullWidth value={state.cgst || ''}
                                                    name="cgst"
                                                    onChange={changeHandler}
                                                    id="cgst"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".cgst"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="SGST" fullWidth value={state.sgst || ''}
                                                    name="sgst"
                                                    onChange={changeHandler}
                                                    id="sgst"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".sgst"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="IGST" fullWidth value={state.igst || ''}
                                                    name="igst"
                                                    onChange={changeHandler}
                                                    id="igst"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".igst"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Total Amount" fullWidth value={state.totalamount || ''}
                                                    name="totalamount"
                                                    onChange={changeHandler}
                                                    id="totalamount"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".totalamount"]}</div>
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                </TableContainer>
                                {userDesignation === 'General Manager' || userDesignation === 'Manager - Accounts'
                                    || userDesignation === 'Sr Mgr - Accounts' || userDesignation === 'MD' 
                                    || userDesignation === 'Asst Mgr - Accounts' || userDesignation === 'Jr Mgr - Accounts' ?
                                    <MDBox mt={4} mb={1} textAlign="center">
                                        {loading ? <Oval
                                            heigth="20"
                                            width="20"
                                            color='grey'
                                            ariaLabel='loading'
                                        /> :
                                            <div>
                                                <MDButton color="info" onClick={submitHandler}>
                                                    SAVE
                                                </MDButton>
                                            </div>
                                        }
                                        {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                    </MDBox>
                                    : null}
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default Invoice;